import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import queryString from 'query-string';
import { contentType, pageType, postContentObject } from '../shared/services/strapi-access-services';
import { useForm } from '../shared/hooks/useForm';
import { PageHeader } from '../shared/components/PageHeader';
import InputText from '../shared/components/InputText';
import { inputType } from '../shared/services/input-utils';

const ResetPassword = (props) => {
    const [code, setCode] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const history = useHistory();

    const { handleChange, handleSubmit, errors, data } = useForm({
        validations :{
            password1: {
                required: {
                    value: true,
                    message: 'Необходимо выбрать пароль',
                },
                combinationRequired: {
                    isValid: (data, value) => data.password1 == data.password2 && value,
                    message: 'Пароли не совпадают',
                },
            },
            password2: {
                required: {
                    value: true,
                    message: 'Необходимо выбрать пароль',
                },
                combinationRequired: {
                    isValid: (data, value) => data.password1 == data.password2 && value,
                    message: 'Пароли не совпадают',
                },
            },
        },
        onSubmit: () => {
            register();
            history.push('/');
        },
    });

    const register = () => {
        let application = {
            code: code,
            password: data.password1,
            passwordConfirmation: data.password2
        };
        postContentObject(contentType.RESET_PASSWORD_PERFORM, application, false)
        .then((result) => {
            handleClose();
            return(result);
        })                    
        .then((response) => {
            console.log("Your user's password has been reset.");
            setMessage("Пароль успешно сменен.");
        })
        .catch(error => {
            console.log('An error occurred:', error.response);
            setError("Что-то пошло не так");
        })
    }

    useEffect(
		() => {
            setCode(queryString.parse(props.location.search).code);
		},
		[props.location],
	);

		return(
			<>
    		<PageHeader pageType={pageType.RESET_PASSWORD} />

			<Container className="mb-5 mt-5 p-3 bg-white">
            <div className="d-flex justify-content-center">
            { message == null && error == null ?
                <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <InputText data={data} errors={errors} handleChange={handleChange} 
                            datatestid="nkp-password-1" type={inputType.USER_PASSWORD_1} password />
                        <InputText data={data} errors={errors} handleChange={handleChange} 
                            datatestid="nkp-password-2" type={inputType.USER_PASSWORD_2} password />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center mt-3">
                    <Button variant="success" type="submit" data-testid="nkp-button-submit">
                        Сменить пароль
                    </Button>
                </Row>

                </Form>
            :
                error == null ?
                    <span>{message}</span>
                :
                    <span className="text-danger">{error}</span>
            }
            </div>
			</Container>
			</>
		);
}

export default ResetPassword;