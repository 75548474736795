import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useWindowDimensions from '../hooks/useWindowDimensions';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { pageType } from '../services/strapi-access-services';

const ModalAccepted = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const { promiseInProgress } = usePromiseTracker();

    const { height, width } = useWindowDimensions();
    const pageT = pageType.MODAL_ACCEPTED;

    useEffect(
		() => {
			setVisible(props.visible);
		},
		[props],
	);

    const handleClose = () => {
        setVisible(false);
     	props.callbackParent();
    }

    return (
        <Modal size={width <= 768 ? "md" : "lg"} show={visible} animation={false} 
            onHide={handleClose} data-testid="nkp-modal-ok">
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
                <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Row className="align-items-center">
                    <Col lg={5} md={5} className="align-middle p-3">
                        <Image src={pageT.image} className="modal-image" />
                    </Col>
                    <Col lg={7} md={7} sm={12} className="p-5">
                        <h1>{pageT.title}</h1>

                        <div className="pt-3" data-testid="nkp-ok-text">
                        {props.parent == "Register" ?
                            <span>Спасибо, ваша заявка принята. На указанный вами адрес почты 
                                отправлена ссылка для активации аккаунта, пожалуйста пройдите 
                                по ней чтобы иметь возможность входить в личный кабинет.</span>
                        :
                            <span>Спасибо, ваша заявка принята. Вы можете отслеживать ее статус 
                                в вашем личном кабинете на вкладке "Заявки". Как только модератор 
                                одобрит заявку, внесенная вами информация будет опубликована 
                                на сайте.</span>
                        }
                        </div>
                    </Col>
                </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleClose} data-testid="nkp-close">
                Закрыть
            </Button>
        </Modal.Footer>
        </Modal>        
    )
}

export default ModalAccepted;