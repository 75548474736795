import gql from "graphql-tag";

const LAST_TICKET_QUERY = gql`
    query Promote ($slug: ID!) {
        tickets(sort: "id:desc", limit: 1, where : { users_permissions_user : { id : $slug }, Type: "Promote" }) {
            id
            Type
            Attachment {
                id
            }
            dog {
                id
                Name
            }
            TicketContent {
                Key
                Value
            }
        }
        dogs (sort:"Name:asc", where: { _or : [{owner : {id : $slug}} , {curators : {id : $slug}}] }) {
            id
            Name
        }
    }
`;

export default LAST_TICKET_QUERY;