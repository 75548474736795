import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { pageType } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import EXPERTS_QUERY from "../shared/queries/experts";

const Experts = (props) => {
    const uniqueCountries = (arr) => {
        let result = [];
        let tmpCountry = "";
        arr.map((expert) => {
            if (expert.Country != tmpCountry) {
                tmpCountry = expert.Country;
                let country = {}
                country.country = expert.Country;
                country.count = arr.filter(rec => rec.Country == expert.Country).length;
                result.push(country);
            }
        })

        result.sort(function(a, b) {
            return b.count - a.count;
        });
        return result;
    }

    return (
        <>
        <Helmet>
            <title>НКП ВКК - Список экспертов, рекомендуемых для судейства монопородных выставок </title>
            <meta name="description" content="Список экспертов, рекомендуемых для судейства монопородных выставок НКП ВКК." />
            <meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, монопородные выставки, эксперты" />
            <link rel="canonical" href="https://welsh-corgi.club/#/experts" />
        </Helmet>

        <PageHeader pageType={pageType.EXPERTS} />

        <Query query={EXPERTS_QUERY}>
        {({ data: { experts } }) => {
            let countries = uniqueCountries(experts);

            return(
                <Container className="mb-3 p-3 bg-white">
                <Row lg={3} noGutters>
                    {countries.map((country, index) => {
                        return(
                            <Col key={index} data-testid="nkp-exp-country">
                                <div className="nkp-kennel-plate-kennel p-3 m-3">
                                    <div className="justify-content-center" style={{ marginTop: "-10px" }}>
                                        <div className="d-flex align-items-start">
                                            <span className="nkp-date-rest">{country.country}</span>
                                        </div>
                                        {experts.filter(rec => rec.Country == country.country).length > 0 &&
                                            <>
                                            <ul>
                                            {experts
                                                .filter(rec => rec.Country == country.country)
                                                .map((expert, index) => {
                                                return (
                                                    <li key={index} data-testid="nkp-exp-name">
                                                        <small>
                                                            {expert.Name}
                                                            {expert.Kennel != null ? " (" + expert.Kennel + ")" : ""}
                                                            {expert.Limitation != null ? " - исключая " + expert.Limitation : ""}
                                                        </small>
                                                    </li>
                                                )
                                            })}
                                            </ul>
                                            </>
                                        }
                                    </div>
                                </div>
                            </Col>
                        );                        
                    })}
                </Row>
                </Container>
            );
        }}
        </Query>                
        </>
    );
}

export default Experts;