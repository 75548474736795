import React, { useEffect, useState } from 'react';
import Form from "react-bootstrap/Form";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

const InputDropdown = (props) => {
    const [current, setCurrent] = useState(props.current);
    const [newFlag, setNewFlag] = useState(false);

    const handleChoose = (e) => {
        if (e == 0) {
            setCurrent(null);
            setNewFlag(true);
        } else {
            setCurrent(props.data.filter(rec => rec.id == e)[0]);
            setNewFlag(false);
        }
        if (props.custom_key) {
            props.handleChoose(props.custom_key)(e);
        } else {
            props.handleChoose(props.type.key)(e);
        }
    }

    useEffect(
        () => {
            if (props.newFlag) {
                setNewFlag(props.newFlag)
            }
        },[props.newFlag]
    );

    return (
        <>
            <DropdownButton
                onSelect={(e) => handleChoose(e)}
                data-testid={props.datatestid}
                title={current != null ? current[props.name] : newFlag ? props.type.newName : props.type.placeholder}
                size="sm"
                className="pb-2"
                variant={props.errors[props.type.key] ? "danger" : "primary"}
            >
                {props.data.map((item, index) => {
                    return <Dropdown.Item key={index} eventKey={item.id}>{item[props.name]}</Dropdown.Item>
                })}
                {props.newItem && <Dropdown.Item eventKey={0}><span><i>&lt; {props.newItemName} &gt;</i></span></Dropdown.Item>}
            </DropdownButton>

            {props.errors[props.type.key] ?
                <Form.Text className="text-danger">
                    {props.errors[props.type.key]}
                </Form.Text>
            : null}
        </>
    )
}

export default InputDropdown;