import { contentType, postContentObject, putContentObject } from '../strapi-access-services';

const ticketMapping = [
    { "tKey" : "HD", "bKey" : "HD", "type" : "text" },
    { "tKey" : "ED", "bKey" : 'ED', "type" : "text" },
    { "tKey" : "doctor", "bKey" : 'Doctor', "type" : "text" },
];

const checkNewDog = (ticket) => {
    if (ticket.dog == null) return true;
    return false;
}

const checkNewGenetics = (dog) => {
    if (dog.dysplasia == null) return true;
    return false;
}

const dysplasiaMethod = (ticket, addDogF, getDogF, prepF) => {
    return Promise.resolve()
    .then(() => {
        if (checkNewDog(ticket)) {
            return addDogF(ticket);
        } 
        return getDogF(ticket);
    })
    .then((dog) => {
        if (dog.error) throw dog.error;
        if (checkNewGenetics(dog)) {
            return postContentObject (contentType.DYSPLASIA, 
                        prepF(ticket, ticketMapping), 
                        true)
            .then((response) => { 
                if(!response.ok) throw response;
                return response.json() 
            })
            .then((dysplasia) => {
                return putContentObject(contentType.DOG, 
                    dog.id, 
                    { "dysplasia": dysplasia.id },
                    true);
            })
            .catch((error) => {
                return ({message : "Other error", error : error});
            });
        }
        return putContentObject(contentType.DYSPLASIA, 
                                dog.dysplasia.id, 
                                prepF(ticket, ticketMapping), 
                                true);
    })
    .then((result) => {
        if (!result.ok) throw result;
        return putContentObject(contentType.TICKET, 
                                ticket.id, 
                                { Status : "Approved" },
                                true);
    })
    .catch((error) => {
        return { error : "Other error", message : error };
    });
}

export default dysplasiaMethod;