import gql from "graphql-tag";

const DYSPLASIAS_QUERY = gql`
    query Genetics ($slug: String!, $limit: Int, $start: Int ){
        dogs(sort:"Name:asc", start: $start, limit: $limit, where : {dysplasia_null : false, Name_contains :$slug}){
            id
            Name
            Year
            dysplasia {
                HD
                ED
                Doctor
            }
        },
    dogsConnection(where : {dysplasia_null : false, Name_contains :$slug}) {
        aggregate {
        count
        }
    }
    }
`;

export default DYSPLASIAS_QUERY;