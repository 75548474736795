import gql from "graphql-tag";

const DOCUMENTS_QUERY = gql`
    query Documents {
        articles(sort: "Order:asc") {
            id
            Url
            Link
            Title
        }
        documents(sort: "Order:asc") {
            id
            Url
            Link
            Title
        }
        breedings(sort: "Order:asc") {
            id
            Url
            Link
            Title
        }
        expos(sort: "Order:asc") {
            id
            Url
            Link
            Title
        }
    }
`;

export default DOCUMENTS_QUERY;