import React, { useState, useEffect, useContext } from 'react';
import {useHistory} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import PaginationPlus from '../shared/components/PaginationPlus';
import ModalAddPuppies from '../shared/modals/ModalAddPuppies';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import ModalRegister from '../shared/modals/ModalRegister';
import ModalPromote from '../shared/modals/ModalPromote';
import { ProfileContext } from '../shared/contexts/profile-context';
import { pageType, getMediaUrl } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { transformImageUri } from '../shared/services/strapi-access-services';

import PUPPIES_QUERY from "../shared/queries/puppies";

const Puppies = (props) => {
	const [page, setPage] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [registerVisible, setRegisterVisible] = useState(false);
    const [modalPromoteVisible, setModalPromoteVisible] = useState(false);

	const [profile, setProfile] = useContext(ProfileContext);

	const history = useHistory();
	const rowsPerPage = 20;

	useEffect(
		() => {
			let page;
			if (!props.match.params.page) { 
				page = 1;
			} else {
				page = props.match.params.page;
			}
			setPage(page);
		},
		[props.match.params],
	);

	const start = (page) => {
		let firstRecord = (page - 1) * rowsPerPage;
		firstRecord < 0 ? firstRecord = 0 : null;

		return firstRecord;
	}

	const goToPage = (page) => {
		history.push('/puppies/' + page);
	}

	return(
		<>
		<Helmet>
			<title>НКП ВКК - Объявления о продаже щенков</title>
			<meta name="description" content="Объявления о продаже щенков Вельш Корги Кардиган." />
			<meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, члены, объявления, щенки" />
			<link rel="canonical" href="https://welsh-corgi.club/#/puppies" />
		</Helmet>

		<PageHeader pageType={pageType.PUPPIES} />

		<Container className="mt-3 mb-3">
		<div className="bg-white py-3">

		{profile != null && profile != "default context value" ? 
			profile?.role?.name == "Members" || profile?.role?.name == "Moderator" ?
				<Row>
					<Col className="d-flex nkp-action-button px-3">
						<Button as="input" 
								className="mb-3" 
								data-testid="nkp-but-add-puppy"
								onClick={() => setModalVisible(true)} 
								type="submit" 
								value="Опубликовать объявление" />
					</Col>
				</Row>
				:
				<>
				<Row className="d-flex nkp-action-button pb-3">
					<span className="d-flex nkp-action-button px-3"><small><i>Чтобы иметь возможность публиковать объявления необходимо быть пользователем сайта и зарегистрироваться в НКП.</i></small></span>
				</Row>
				<Row className="d-flex justify-content-end">
					<Button as="input" 
							data-testid="nkp-but-promote"
							size="md"
							onClick={() => setModalPromoteVisible(true)} 
							type="submit" 
							value="Зарегистрироваться в НКП" />
				</Row>
				</>
			:
			<>
			<Row className="d-flex nkp-action-button pb-3">
				<span className="d-flex nkp-action-button px-3"><small><i>Чтобы иметь возможность публиковать объявления необходимо быть пользователем сайта и зарегистрироваться в НКП.</i></small></span>
			</Row>
			<Row className="d-flex justify-content-end">
				<Button as="input" 
						size="md"
						data-testid="nkp-but-register"
						onClick={() => setRegisterVisible(true)} 
						type="submit" 
						value="Зарегистрироваться на сайте" />
			</Row>
			</>}

			<Query query={PUPPIES_QUERY} start={start(page)} limit={rowsPerPage}>
			{({ data: { puppies, puppiesConnection } }) => {
				let totalRows = puppiesConnection.aggregate.count;
				let totalPages = Math.ceil(totalRows / rowsPerPage);
				return(
					<>
					{puppies.map((dog, index) => {
						return(
							<div key={index} className="p-3 my-3 border bg-light border border-primary rounded" data-testid="nkp-puppies">
								{dog.Title != "" && dog.Title != null ?
								<Row>
									<Col className="d-flex justify-content-center">
										<h5>{dog.Title}</h5>
									</Col>
								</Row>
								: ""}
								<Row>
									{dog.Pic != null ?
									<Col lg={3}>
										<Image src={getMediaUrl(dog.Pic)} style={{ maxWidth: 200 }}/>
									</Col>
									:
									""}
									<Col>
										<div data-testid="nkp-puppies-content">
											<ReactMarkdown 
												rehypePlugins={[rehypeRaw]} transformImageUri={transformImageUri}
												children={dog.Content} />
										</div>
										{dog.Phone != null && dog.Phone != "" ?
										<div>
											<small>
												<FontAwesomeIcon icon={faPhone} width={15} height={15} />: {dog.Phone}
											</small>
										</div>
										: ""}
										{dog.Email != null && dog.Email != "" ?
										<div>
											<small>
												<FontAwesomeIcon icon={faEnvelopeOpen} width={15} height={15} />: <a href={"mailto:" + dog.Email} data-testid="nkp-puppies-mail">{dog.Email}</a>
											</small>
										</div>
										: ""}
									</Col>
								</Row>
							</div>
						)
					})}

					<div className="d-flex justify-content-center">
						<PaginationPlus 
							totalRows={totalRows}
							page={page}
							totalPages={totalPages}
							rowsPerPage={rowsPerPage}
							goToPage={goToPage}
						/>
					</div>
				</>
				);
			}}
			</Query>
			</div>
		</Container>

        <ModalAddPuppies visible={modalVisible} 
			callbackParent={() => setModalVisible(false)}
		/>

		<ModalRegister visible={registerVisible} 
			callbackParent={() => setRegisterVisible(false)}
		/>

		<ModalPromote visible={modalPromoteVisible} 
			callbackParent={() => setModalPromoteVisible(false)}
		/>

		</>
	);
}

export default Puppies;
