import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { formatDate } from '../shared/services/utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { contentType, pageType } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";
import { getMediaUrl } from '../shared/services/strapi-access-services';

import PROTOCOLS_QUERY from "../shared/queries/protocols";
import PROTOCOL_SINGLE_QUERY from "../shared/queries/protocol-single";

const Protocols = (props) => {
	const [documentId, setDocumentId] = useState(null);

	useEffect(
		() => {
			let id;
			if (!props.match.params.id) { 
				id = null;
			} else {
				id = props.match.params.id;
			}
			setDocumentId(id);
		},
		[props.match.params],
	);

	return(
		<>
			<Helmet>
                <title>НКП ВКК - Протоколы заседания Президиума</title>
                <meta name="description" content="Протоколы заседания Президиума НКП Вельш Корги Кардиган." />
                <meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, протоколы, президиум" />
                <link rel="canonical" href="https://welsh-corgi.club/#/protocols" />
            </Helmet>

			<PageHeader pageType={pageType.PROTOCOLS} />

			<Container className="py-3">
				<Query query={PROTOCOLS_QUERY}>
				{({ data: { protocols } }) => {
					return(
						<Row>
							<Col>
								{protocols.map((document, index) => {
									return (
										<div key={index} className="nkp-dog-plate p-2 m-2" data-testid="nkp-protocol">
											<a href={getMediaUrl(document.protocol)} data-testid="nkp-button-protocol" target='_blank'>
												<FontAwesomeIcon icon={faFileAlt}/> 
												<span className="pl-3">
													Протокол Президиума от {formatDate(document.Date)}
												</span>
											</a>
										</div>	
									)		
								})}
							</Col>
						</Row>
					);
				}}
				</Query>						
			</Container>
		</>
	);
}

export default Protocols;