import gql from "graphql-tag";

const ANNOUNCEMENT_QUERY = gql`
    query Announcement ($slug:ID!){
        puppy (id: $slug) {
            Content
        }
    }
`;

export default ANNOUNCEMENT_QUERY;