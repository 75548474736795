import gql from "graphql-tag";

const USERS_QUERY = gql`
    query Users {
        users {
            id
            name
            email
        }
    }
`;

export default USERS_QUERY;