import React, { useState, useEffect, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import useWindowDimensions from '../../shared/hooks/useWindowDimensions';
import { pageType } from '../services/strapi-access-services';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import { ticketType } from '../services/ticket-utils';
import { submitTicket } from '../services/tickets-api';
import { ProfileContext } from '../contexts/profile-context';
import ModalAccepted from './ModalAccepted';

const ModalProlong = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [okVisible, setOkVisible] = useState(false);
    const [puppy, setPuppy] = useState(null);
    const [profile, setProfile] = useContext(ProfileContext);

    const { promiseInProgress } = usePromiseTracker();

    const { height, width } = useWindowDimensions();
    const pageT = pageType.MODAL_PROLONG;

    useEffect(
		() => {
			setVisible(props.visible);
      setPuppy(props.puppy);
		},
		[props.visible, profile, props.puppy],
	);

  const handleCloseOk = () => {
    setOkVisible(false);
    handleClose();
}

  const handleClose = () => {
      setVisible(false);
    props.callbackParent();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    data.puppiesId = puppy.id.toString();
    trackPromise(
      submitTicket(ticketType.PROLONG, data, profile)
      .then(() => {
        setOkVisible(true);
        setVisible(false);
      })
    );
  }

    return (
      <>
      <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} 
        onHide={handleClose} data-testid="nkp-user-prolong">
        <form onSubmit={handleSubmit}>
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {puppy != null ?
              <Row className="align-items-center">
              <Col lg={5} md={5} className="align-middle p-3">
                  <Image src={pageT.image} className="modal-image" />
              </Col>
              <Col lg={7} md={7} sm={12} className="p-5">
                <h1>{pageT.title}</h1>
                <div>
                  Вы действительно хотите повторно опубликовать это объявление?
                </div>
                <div className="mt-3">
                    <i>{puppy.Content}</i>
                </div>
                <div className="mt-3">
                  Объявление будет опубликовано после одобрения модератора и будет видно в течение двух недель.
                </div>
              </Col>
              </Row>
            : null}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" 
              data-testid="nkp-submit" disabled={promiseInProgress}>
            Опубликовать
          </Button>
          <Button variant="danger" onClick={handleClose} 
              data-testid="nkp-cancel" disabled={promiseInProgress}>
            Отмена
          </Button>
        </Modal.Footer>
        </form>
      </Modal>

      <ModalAccepted visible={okVisible} callbackParent={() => handleCloseOk()} />
      </>      
	  );
}

export default ModalProlong;