import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ModalAddDog from '../shared/modals/ModalAddDog';
import ModalReplace from '../shared/modals/ModalReplace';
import ModalAddPuppies from '../shared/modals/ModalAddPuppies';
import ModalPromote from '../shared/modals/ModalPromote';
import ModalProlong from '../shared/modals/ModalProlong';
import { formatDate, incrementDate } from '../shared/services/utils';
import { formatTicketTitle, formatTicketStatus } from '../shared/services/ticket-utils';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt, faQuestionCircle, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { pageType } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import { ProfileContext } from '../shared/contexts/profile-context';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import USER_PROFILE_QUERY from "../shared/queries/user-profile";

const UserProfile = (props) => {
	const [modalVisible, setModalVisible] = useState(false);
    const [modalPuppiesVisible, setModalPuppiesVisible] = useState(false);
	const [modalReplaceVisible, setModalReplaceVisible] = useState(false);
    const [modalPromoteVisible, setModalPromoteVisible] = useState(false);
    const [modalProlongVisible, setModalProlongVisible] = useState(false);
    const [puppy, setPuppy] = useState(null);
    const [key, setKey] = useState('dogs');
	const [profile, setProfile] = useContext(ProfileContext);

    const checkDate = (date) => {
        let now = new Date();
        if (date.getTime() > now.getTime()) {
            return true;
        } else {
            return false;
        }
    }

    return(
			<>
           <Helmet>
                <title>НКП ВКК - личный кабинет пользователя</title>
                <meta name="description" content="Личный кабинет пользователя сайта НКП Вельш Корги Кардиган." />
                <meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, личный кабинет, пользователь" />
            </Helmet>

			<PageHeader pageType={pageType.USER_PROFILE} />

            {profile != null ?
            <Query query={USER_PROFILE_QUERY} slug={3}>
			{({ data: { dogs, puppies, tickets } }) => {
				return(
                   <>
                    {profile != null &&
                    <Container className="mb-3 p-3 bg-white">
                    <Row>
                    <Col md={3}>
                        <div className="nkp-box-shadow p-3">
                            <div className="d-flex justify-content-center">
                                <Avatar name={profile.name} /*size={30}*/ round={true} />    
                            </div>
                            <div className="pt-3" data-testid="nkp-user-name">
                                <center><span><small><b>{profile.name}</b></small></span></center>
                            </div>
                            <div className="pt-1">
                                <center><span><small><i>{profile.email}</i></small></span></center>
                            </div>
                            <div data-testid="nkp-user-role">
                                <center><small>{profile.role?.description}</small></center>
                            </div>
                            {profile.role?.name != "Members" && profile.role?.name != "Moderator" ?
                            <div className="d-flex justify-content-center">
                                <div className="pt-3">
                                    <Button onClick={() => setModalPromoteVisible(true)} data-testid="nkp-user-promote">
                                        <small>Регистрация в НКП</small>
                                    </Button>
                                </div>
                            </div>
                            : ""}
                        </div>
                    </Col>
                    <Col>
                        <div className="nkp-box-shadow p-3">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                            >
                                <Tab eventKey="dogs" title="Собаки">
                                    <div className="p-3">
                                        <div>
                                            <small>
                                                Куратор - пользователь нашего сайта, который уполномочен публиковать или 
                                                менять информацию о собаке. В настоящее время вы являетесь куратором 
                                                перечисленных ниже собак. Вы можете добавить новую собаку в этот список.
                                            </small>
                                        </div>

                                        <div className="pt-3">
                                            {dogs.map((dog, index) => {
                                                return (
                                                    <Link key={index} to={"/dog/" + dog.id} data-testid="nkp-user-dog-button">
                                                    <div className="nkp-dog-plate p-2 m-2" data-testid="nkp-user-dog">
                                                        <span className="pr-3">{dog.Name} ({dog.Year})</span> <FontAwesomeIcon icon={faPenAlt}/>
                                                    </div>
                                                    </Link>
                                                )
                                            })}
                                        </div>

                                        <div className="d-flex justify-content-end pt-3">
                                            <Button onClick={(e) => setModalVisible(true)} data-testid="nkp-user-dog-add">
                                                <small>
                                                    Добавить собаку
                                                </small>
                                            </Button>            
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="puppies" title="Объявления">
                                    <div className="p-3">
                                        <div>
                                            <small>
                                                Добросовестные заводчики - члены НКП имеют возможность бесплатно 
                                                публиковать объявления, доступные в разделе "Щенки". 
                                            </small>
                                        </div>

                                        {profile.role?.name != "Members" &&
                                        <div className="pt-3">
                                            <small>
                                                Вы являетесь пользователем сайта, но не зарегистрированы в НКП; 
                                                чтобы воспользоваться возможностью публиковать объявления зарегистрируйтесь.
                                            </small>
                                        </div>}

                                        {(profile.role?.name == "Members" || profile.role?.name == "Moderator") && puppies.length > 0 ?
                                        <>
                                        <div className="pt-3">
                                            <small>
                                                Ранее вы опубликовали следующие объявления:
                                            </small>
                                        </div>

                                        {puppies.map((puppy, index) => {
                                            return (
                                                <div key={index} className="nkp-dog-plate p-2 m-2" data-testid="nkp-user-puppy">
                                                    <div><small><b>{puppy.Title}</b></small></div>
    
                                                    <div>
                                                        <small>
                                                            {puppy.Content.substring(0, 80) + " ... "}
                                                        </small>
                                                    </div>
                                                    {checkDate(incrementDate(puppy.published_at)) ? 
                                                    <div>
                                                        <small><i>Опубликовано {formatDate(puppy.published_at)}, будет видно до {formatDate(incrementDate(puppy.published_at))}</i></small>
                                                    </div>
                                                    :
                                                    <div>
                                                        <small><i>Было опубликовано {formatDate(puppy.published_at)} - {formatDate(incrementDate(puppy.published_at))}</i></small>
                                                    </div>
                                                    }
                                                    {checkDate(incrementDate(puppy.published_at)) ? null :
                                                    <div>
                                                    <Button onClick={() => {setPuppy(puppy); setModalProlongVisible(true)}} 
                                                        className="py-0 mt-3" data-testid="nkp-button-prolong">
                                                        <small>
                                                            Опубликовать повторно
                                                        </small>
                                                    </Button>
                                                    </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                        <div className="d-flex justify-content-end pt-3">
                                            {profile.role?.name == "Members" || profile.role?.name == "Moderator" ?
                                            <Button onClick={() => setModalPuppiesVisible(true)} data-testid="nkp-user-puppy-add">
                                                <small>
                                                    Добавить объявление
                                                </small>
                                            </Button>
                                            :
                                            <span>
                                                <small>
                                                    <i>
                                                        Чтобы иметь возможность публиковать объявления необходимо 
                                                        зарегистрироваться в НКП.
                                                    </i>
                                                </small>
                                            </span>}
                                        </div>

                                    </>
                                    : ""}
                                    </div>
                                </Tab>
                                <Tab eventKey="tickets" title="Заявки">
                                <div className="p-3">
                                    <>
                                        <div className="pb-3">
                                            <small>
                                                Любые публикации и изменения, которые вы делаете на нашем сайте 
                                                обязательно проходят через модератора в форме заявок.
                                            </small>
                                        </div>

                                        {tickets.length > 0 ?
                                        <>
                                        <div className="pb-3">
                                            <small>
                                                В этом разделе вы видите, какие заявки вы уже оформляли и в каком 
                                                статусе они сейчас находятся.
                                            </small>
                                        </div>

                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th><small>#</small></th>
                                                    <th><small>Запрос</small></th>
                                                    <th><small>Дата</small></th>
                                                    <th><small>Статус</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tickets.map((ticket, index) => {
                                                    return (
                                                        <tr key={index} data-testid="nkp-user-ticket">
                                                        <td><small>{ticket.id}</small></td>
                                                        <td><small>{formatTicketTitle(ticket)}</small></td>
                                                        <td><small>{formatDate(ticket.published_at)}</small></td>
                                                        <td align="center">
                                                            {ticket.Status == "New" ?
                                                            <OverlayTrigger
                                                                placement="right"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={
                                                                    <Tooltip id="button-tooltip">
                                                                        Находится на рассмотрении модератора
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faQuestionCircle} style={{ color: "#0081C9" }} />
                                                            </OverlayTrigger>
                                                            : 
                                                            ticket.Status == "Approved" ?
                                                            <OverlayTrigger
                                                                placement="right"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={
                                                                    <Tooltip id="button-tooltip">
                                                                        Одобрена модератором
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#28a745" }} />
                                                            </OverlayTrigger>
                                                            :
                                                            ticket.Status == "Canceled" ?
                                                            <OverlayTrigger
                                                                placement="right"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={
                                                                    <Tooltip id="button-tooltip">
                                                                        Отклонена модератором
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faTimesCircle} style={{ color: "#dc3545" }} />
                                                            </OverlayTrigger>
                                                            :
                                                            <small>{formatTicketStatus(ticket.Status)}</small>}
                                                        </td>
                                                        </tr>        
                                                    )    
                                                })}
                                            </tbody>
                                        </Table>
                                        </>
                                        : null }
                                    </>
                                </div>
                                </Tab>
                                <Tab eventKey="other" title="Другие вопросы">
                                <div className="p-3">
                                    <>
                                    <div>
                                        <small>
                                            Если ваша собака добавлена на сайт, но куратором являетесь не вы и по 
                                            какой-либо причине текущий куратор не может дать вам права, вы можете 
                                            запросить замену текущего куратора на вас через модератора. Для этого 
                                            необходимо будет предоставить документы, подтверждающие ваши права на 
                                            собаку (родословная).
                                        </small>
                                    </div> 

                                    <div className="d-flex justify-content-end pt-3">
                                        <Button onClick={(e) => setModalReplaceVisible(true)} data-testid="nkp-button-own">
                                            <small>
                                                Сменить куратора
                                            </small>
                                        </Button>
                                    </div>
                                    </>
                                </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
			</Container>
            }
            </>
            );
			}}
    		</Query>
            :
            <h4 style={{ color: "red" }}><center>Для доступа к личному кабинету необходимо авторизоваться.</center></h4>}

            <ModalAddDog visible={modalVisible} 
                callbackParent={() => setModalVisible(false)}
            />

            <ModalReplace visible={modalReplaceVisible} 
                callbackParent={() => setModalReplaceVisible(false)}
            />

            <ModalAddPuppies visible={modalPuppiesVisible} 
                callbackParent={() => setModalPuppiesVisible(false)}
            />

            <ModalPromote visible={modalPromoteVisible} 
                callbackParent={() => setModalPromoteVisible(false)}
                refresh={() => refresh()}
            />

            <ModalProlong visible={modalProlongVisible} 
                puppy={puppy}
                callbackParent={() => setModalProlongVisible(false)}
            />

			</>
    );        
}

export default UserProfile;