import React, { useState, useEffect } from 'react';
const ReactDOM = require('react-dom');
import {HashRouter, BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import './scss/custom.scss';
import './css/nkp.css';
import Header from './shared/components/Header';
import Footer from './shared/components/Footer';
import Documents from './modules/Documents';
import Protocols from './modules/Protocols';
import Exhibitions from './modules/Exhibitions';
import News from './modules/News';
import Experts from './modules/Experts';
import Sports from './modules/Sports';
import Puppies from './modules/Puppies';
import Genetics from './modules/Genetics';
import Champions from './modules/Champions';
import Titles from './modules/Titles';
import Dysplasia from './modules/Dysplasia';
import Kennels from './modules/Kennels';
import Members from './modules/Members';
import Dog from './modules/Dog';
import ResetPassword from './modules/ResetPassword';
import Home from './modules/Home';
import Privacy from './modules/Privacy';
import UserProfile from './modules/UserProfile';
import ModeratorPanel from './modules/ModeratorPanel';
import Disqualification from './modules/Disqualification';
import { ProfileContext } from './shared/contexts/profile-context';
import { contentType, pageType, getContentObject } from './shared/services/strapi-access-services';
import Query from "./shared/components/Query";

import CONTACTS_QUERY from "./shared/queries/contacts";

const App = (props) => {
	const [contact, setContact] = useState(null);
	const [loggedIn, setLoggedIn] = useState(false);

	const [profile, setProfile] = useState(null);

	// useEffect(
	// 	() => {
	// 		getContentObject(contentType.CONTACT)
	// 		.then((obj) => {
	// 			setContact(obj);
	// 		})
	// 	},
	// 	[props],
	// );
	
	const handleChangeLoggedState = (newState) => {
		setLoggedIn(newState);
	}

	return (
		<>
		<ProfileContext.Provider value={[profile, setProfile]}>
			<Header {...props} callbackParent={handleChangeLoggedState} handleChangeProfile={(profile) => setProfile(profile)}/>
			<Switch>
				<Route path="/articles/:id?" render={(props) => <Documents {...props} type="articles" />} />
				<Route path="/documents/:id?" render={(props) => <Documents {...props} type="documents" />} />
				<Route path="/expos/:id?" render={(props) => <Documents {...props} type="expos" />} />
				<Route path="/breedings/:id?" render={(props) => <Documents {...props} type="breedings" />} />
				<Route path="/news/:page?" render={(props) => <News {...props} />} />
				<Route path="/protocols/:id?" render={(props) => <Protocols {...props} />} />
				<Route path="/exhibitions/:id?" render={(props) => <Exhibitions {...props} />} />
				<Route path="/experts" render={(props) => <Experts {...props} />} />
				<Route path="/kennels" render={(props) => <Kennels {...props} />} />
				<Route path="/members" render={(props) => <Members {...props} />} />
				<Route path="/sports/:section?/:page?" render={(props) => <Sports {...props} />} />
				<Route path="/genetics/:page?" render={(props) => <Genetics {...props} />} />
				<Route path="/dysplasia/:page?" render={(props) => <Dysplasia {...props} />} />
				<Route path="/dog/:id?" render={(props) => <Dog {...props} />} />
				<Route path="/puppies/:id?" render={(props) => <Puppies {...props} />} />
				<Route path="/disqualifications/:page?" render={(props) => <Disqualification {...props} />} />
				<Route path="/champions/:page?" render={(props) => <Champions {...props} />} />
				<Route path="/titles/:page?" render={(props) => <Titles {...props} />} />
				<Route path="/privacy" render={(props) => <Privacy {...props} />} />
				<Route path="/user/profile" render={(props) => <UserProfile {...props} />} />
				<Route path="/user/moderator/:ticket?" render={(props) => <ModeratorPanel {...props} />} />
				<Route path="/reset-password/:slug" render={(props) => <ResetPassword {...props} />} />
				<Route path="/" render={(props) => <Home {...props} />} />
			</Switch>
			<Query query={CONTACTS_QUERY} >
			{({ data: { contact } }) => {
				return <Footer {...props} contact={contact} />
			}}
			</Query>
		</ProfileContext.Provider>
		</>
	);
}

export default App;
