import gql from "graphql-tag";

const KENNELS_QUERY = gql`
    query Kennels {
        members (sort: "MemberName:asc", where: { _or : [{Type : "Kennel"}, {Type : "Prefix"}]}) {
            Name
            MemberName
            City
            Type
        }
    }
`;

export default KENNELS_QUERY;