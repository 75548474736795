import React from "react";
import { useQuery } from "@apollo/client";
import Spinner from 'react-bootstrap/Spinner';

const Query = ({ children, query, slug, start, limit, date }) => {
  const { data, loading, error, refetch } = useQuery(query, {
    variables: { slug: slug, start: start, limit: limit, date: date }
  });

  if (loading) return <div><center><Spinner data-testid="nkp-spinner" animation="border" /></center></div>

//  if (error) console.log(error)
  if (error) return <div><center><h4 style={{ color : "red" }}>Что-то пошло не так. Мы обязательно разберемся и исправим.</h4></center></div>

  return children({ data, refetch });
};

export default Query;