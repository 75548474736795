import gql from "graphql-tag";

const PROTOCOL_SINGLE_QUERY = gql`
    query Protocols ($slug:ID!) {
        protocol (id: $slug) {
            id
            Number
            Date
            Participates
            Quorum
            AgendaItem {
                id
                Title
                Order
                Description
                Decision
                Proposal
                Approve
                Disapprove
                Neutral
            }
        }
    }
`;

export default PROTOCOL_SINGLE_QUERY;