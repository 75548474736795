export var base_url;
window.Configs != null ? base_url = window.Configs.nkpApiUrl : base_url = "http://localhost:1337"

var qs = require('qs');

export const contentType = {
    PAGE: "/pages",
    HOME: "/home",
    PRIVACY: "/privacy",
    RULES: "/puppies-rules",
    GENETICS: "/genetics",
    DYSPLASIA: "/dysplasias",
    RESULTS_OKD: "/sports-results-okds",
    RESULTS_HERDING: "/sports-results-herdings",
    RESULTS_AGILITY: "/sports-results-agilities",
    GENERAL_DOCUMENT: "/documents",
    EXPO_DOCUMENT: "/expos",
    BREEDING_DOCUMENT: "/breedings",
    DOG: "/dogs",
    ARTICLE: "/articles",
    DOCUMENT: "/documents",
    PROTOCOLS: "/protocols",
    EXPO: "/expos",
    BREEDING: "/breedings",
    EXPERTS: "/experts",
    PUPPIES: "/puppies",
    DISQUALIFICATIONS: "/disqualifications",
    MEMBERS: "/members",
    TICKET: "/tickets",
    ATTACHMENT: "/upload",
    REGISTER: "/auth/local/register",
    LOGON: "/auth/local",
    RESET_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD_PERFORM: "/auth/reset-password",
    USER_DETAILS: "/users/me",
    USER_PROFILE: "/user/profile",
    MODERATOR_PANEL: "/user/moderator",
    USERS: "/users",
    SPORTS: "/sports",
    SPORTS_EVENTS: "/sports-events",
    SPORTS_CATEGORIES: "/sports-categories",
    EXHIBITIONS: "/exhibitions",
    ROLES: "/users-permissions/roles",
    CONTACT: "/contact",
    NEWS: "/news-items",
} 

export const pageType = {
    ARTICLE: { "name": "Articles", "title": "О породе", "image": "/image/documents.jpg" },
    BREEDING: { "name": "Breedings", "title": "Племенная работа", "image": "/image/breeding.jpg" },
    CHAMPIONS: { "name": "Champions", "title": "Чемпионы НКП", "image": "/image/expo.jpg" },
    DISQUALIFICATIONS: { "name": "Disqualification", "title": "Дисквалификации", "image": "/image/expo.jpg" },
    DOCUMENT: { "name": "Documents", "title": "Документы НКП", "image": "/image/documents.jpg" },
    DOG: { "name": "Dog", "title": "Собака", "image": "/image/profile.jpg" },
    DYSPLASIA: { "name": "Dysplasia", "title": "База данных HD / ED", "image": "/image/breeding.jpg" },
    EXHIBITIONS: { "name": "Exhibitions", "title": "Отчеты о проведении монопородных выставок", "image": "/image/expo.jpg" },
    EXPERTS: { "name": "Experts", "title": "Список экспертов, рекомендуемых для судейства монопородных выставок НКП ВКК", "image": "/image/expo.jpg" },
    EXPO: { "name": "Expos", "title": "Выставочная работа", "image": "/image/expo.jpg" },
    GENETIC: { "name": "Genetics", "title": "База данных генетических тестов", "image": "/image/breeding.jpg" },
    KENNELS: { "name": "Kennels", "title": "Список питомников и заводских приставок", "image": "/image/breeding.jpg" },
    LOGIN: { "name": "Login", "title": "Войти","image":"/image/modal_short.jpg" },
    MEMBERS: { "name": "Members", "title": "Список членов НКП", "image": "/image/documents.jpg" },
    MODAL_ACCEPTED: { "name": "ModalAccepted", "title": "Ваша заявка принята","image":"/image/modal_short.jpg" },
    MODAL_ADD_AGILITY_RESULTS: { "name": "ModalAddAgilityResults", "title": "Добавить результаты соревнований по аджилити","image":"/image/modal_short.jpg" },
    MODAL_ADD_CHAMPION: { "name": "ModalAddChampion", "title": "Добавить информацию о чемпионе НКП","image":"/image/modal_short.jpg" },
    MODAL_ADD_CURATOR: { "name": "ModalAddCurator", "title": "Добавить куратора для собаки","image":"/image/modal_short.jpg" },
    MODAL_ADD_DOG: { "name": "ModalAddDog", "title": "Добавить курируемую собаку","image":"/image/modal_short.jpg" },
    MODAL_ADD_DYSPLASIA: { "name" : "ModalAddDysplasia", "title": "Добавить результаты теста HD / ED","image":"/image/modal_tall.jpg" },
    MODAL_ADD_GENETICS: { "name" : "ModalAddGenetics", "title" : "Добавить результаты генетического теста","image":"/image/modal_tall.jpg" },
    MODAL_ADD_HERDING_RESULTS: { "name": "ModalAddHerdingResults", "title": "Добавить результаты испытаний по пастушьей службе","image":"/image/modal_short.jpg" },
    MODAL_ADD_OKD_RESULTS: { "name": "ModalAddOKDResults", "title": "Добавить результаты тестирования ОКД и тестирования на темперамент","image":"/image/modal_short.jpg" },
    MODAL_ADD_PUPPIES: { "name": "ModalAddPuppies", "title": "Добавить объявление","image":"/image/modal_tall.jpg" },
    MODAL_ADD_TITLES: { "name": "ModalAddTitles", "title": "Добавить информацию о титулах","image":"/image/modal_short.jpg" },
    MODAL_PROLONG: { "name": "ModalProlong", "title": "Повторная публикация объявления","image":"/image/modal_short.jpg" },
    MODAL_REMOVE_CURATOR: { "name": "ModalRemoveCurator", "title": "Отозвать права куратора для собаки","image":"/image/modal_short.jpg" },
    MODAL_REPLACE: { "name": "ModalReplace", "title": "Смена текущего куратора","image":"/image/modal_short.jpg" },
    MODAL_SEND_MESSAGE: { "name": "ModalSendMessage", "title": "Связаться с питомником / заводчиком","image":"/image/modal_short.jpg" },
    MODAL_TICKET: { "name": "ModalTicket", "title": "Карточка заявки","image":"/image/modal_short.jpg" },
    MODERATOR: { "name": "Moderator", "title": "Панель модератора", "image": "/image/profile.jpg" },
    PRIVACY: { "name": "Privacy", "title": "Политика в отношении обработки персональных данных", "image": "/image/documents.jpg" },
    USER_PROFILE: { "name": "Profile", "title": "Личный кабинет", "image": "/image/profile.jpg" },
    MODAL_PROMOTE: { "name": "Promote", "title": "Заявление на регистрацию в НКП","image":"/image/modal_tall.jpg" },
    PROTOCOLS: { "name": "Protocols", "title": "Протоколы заседания Президиума НКП", "image": "/image/documents.jpg" },
    PUPPIES: { "name": "Puppies", "title": "Щенки","image":"/image/puppies.jpg" },
    REGISTER: { "name": "Register", "title": "Зарегистрироваться","image":"/image/modal_short.jpg" },
    MODAL_RESET_PASSWORD: { "name": "Reset", "title": "Сброс пароля","image":"/image/modal_short.jpg" },
    RESET_PASSWORD: { "name": "ResetPassword", "title": "Сброс пароля", "image": "/image/profile.jpg" },
    SPORTS: { "name": "Sports", "title": "Работа и спорт","image":"/image/sports.jpg" },
    TITLES: { "name": "Titles", "title": "Наши достижения", "image": "/image/expo.jpg" },
    NEWS: { "name": "News", "title": "Новости","image":"/image/puppies.jpg" },
}

export const getContentObject = (type, filter, sort, start, limit, id) => {
    const handleLogoff = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    const token = localStorage.getItem('token');
    let query = "";
    if (filter != null) {
        query = qs.stringify({
            _where: filter,
        });
    }

    let request = "";
    let url = base_url + type;
    if (id != null) url += "/" + id;
    request = `${url}?${query}`;
    if (sort != null) request += "&_sort=" + sort;
    if (start != null && limit != null) request += "&_start=" + start + "&_limit=" + limit;

    let headers;
    if (token != null) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }

    return fetch((request), {
        method: 'GET',
        headers: headers,
    })
    .then((response) => {
        if(!response.ok) {
            throw response;
        } else {
            return response;
        }
    })
    .then((response) => {
        return response.json();
    })
    .then((myjson) => {
        return myjson;
    })
    .catch((error) => {
        switch (error.status) {
            case 401:
                handleLogoff();
                return({ error : "Unauthorized" });
            case 403:
                return({ error : "Permission denied" });
            default:
                return({ error : "Something's wrong" })
        }
    })
}

export const uploadMedia  = (payload) => {
    return fetch(base_url + contentType.ATTACHMENT, {
        method: 'POST',
        body: payload
    })
    .then((response) => {
        return response.json();
    })
    .then((myjson) => {
        return myjson;
    })            
}

export const postContentObject = (type, payload, secure) => {
//    console.log("Posting", type, payload)
    const token = localStorage.getItem('token');
    let headers = {};
    if (token != null && secure == true) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    } else {
        headers = {
            'Content-Type': 'application/json',
        }
    }

    return fetch(base_url + type, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload)
    })
    .then((response) => {
        return response;
    })
    .catch()    
}

export const putContentObject = (type, id, payload, secure) => {
    const token = localStorage.getItem('token');
    let headers = {};
    if (token != null && secure == true) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    } else {
        headers = {
            'Content-Type': 'application/json',
        }
    }

    return fetch(base_url + type + "/" + id, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(payload)
    })
    .then((response) => {
        // let body = response.json();
        // let status = response.status;
        // return {body, status};
        return response;
    })
    .catch()
}

export const getMediaUrl = (object) => {
    if (object != null) {
        return base_url + object.url;
    } else {
        return "";
    }
}

export const getEmptyDog = () => {
    return "/image/silhouette.png";
}

export const getDocumentUrl = (pageType) => {
    return pageType;
}

export const countContentObjects = (type, filter, sort) => {
    const token = localStorage.getItem('token');
    let query = "";
    if (filter != null) {
        query = qs.stringify({
            _where: filter,
        });
    }

    let url = base_url + type + '/count';

    return fetch((`${url}?${query}`), {
        method: 'GET'
    })
    .then((response) => {
        return response.json();
    })
    .then((myjson) => {
        return myjson;
    })            
}

export const transformImageUri = (input) => {
    return base_url + input;
}

export const darkBackground = () => {
    return `url(/image/dark-back.jpg)`
}