import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getMediaUrl } from '../shared/services/strapi-access-services';

const PhotoModal = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [expo, setExpo] = useState(props.expo);
    const [picId, setPicId] = useState(null);

    useEffect(
        () => {
            setPicId(props.currentPic)
        }, [props]
    );

    const handleClose = () => {
        setVisible(false);
        props.callbackParent(false);
    }

    const handleNext = () => {
        let next;
        for (var i = 0; i < expo.Pictures.length; i++) {
            if (expo.Pictures[i].id == picId) {
                next = expo.Pictures[i+1];
            }
        }
        setPicId(next.id);
    }

    const handlePrevious = () => {
        let previous;
        for (var i = 0; i < expo.Pictures.length; i++) {
            if (expo.Pictures[i].id == picId) {
                previous = expo.Pictures[i-1];
            }
        }
        setPicId(previous.id);
    }

    useEffect(
		() => {
            setVisible(props.visible);
		},
		[props.visible],
	);

    useEffect(
		() => {
            setExpo(props.expo);
		},
		[props.expo],
	);

    useEffect(
		() => {
            setPicId(props.currentPic);
		},
		[props.currentPic],
	);

    const getImage = (id) => {
    if (expo != null && id != null) {
            return expo.Pictures.filter(pic => pic.id == id)[0];
        } 
    }

    return (
        <Modal size="lg" show={visible} animation={false} onHide={handleClose} data-testid="nkp-photo-modal">
            <Modal.Body>
                <div>
                    <Image src={getMediaUrl(getImage(picId))} data-testid="nkp-photo" fluid />
                    <div style={{ opacity: 0.65, position: "absolute", top: "0%", right: "0%", marginTop: "25px", marginRight: "25px" }} >
                        <FontAwesomeIcon icon={faTimes} style={{ fontSize: '40px', color: "white" }} 
                                        data-testid="nkp-close-button" onClick={handleClose} />
                    </div>
                    {expo.Pictures[0].id != picId ?
                    <div style={{ opacity: 0.65, position: "absolute", bottom: "0%", left: "50%", marginBottom: "25px", marginLeft: "-45px" }} >
                        <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '40px', color: "white" }} 
                                        data-testid="nkp-photo-prev" onClick={handlePrevious} />
                    </div>
                    : null}
                    {expo.Pictures[expo.Pictures.length - 1].id != picId ?
                    <div style={{ opacity: 0.65, position: "absolute", bottom: "0%", left: "50%", marginBottom: "25px", marginLeft: "15px" }} >
                        <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '40px', color: "white" }} 
                                        data-testid="nkp-photo-next" onClick={handleNext} />
                    </div>
                    : null}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PhotoModal;