import gql from "graphql-tag";

const PUPPIES_QUERY = gql`
    query Announcements ($start:Int, $limit:Int){
        puppies (sort: "id:asc", start: $start, limit: $limit) {
            Title
            Pic {
                url
            }
            Content
            Phone
            Email
        }
        puppiesConnection {
            aggregate {
                count
            }
        }
    }
`;

export default PUPPIES_QUERY;