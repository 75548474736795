import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import PaginationPlus from '../shared/components/PaginationPlus';
import ModalAddGenetics from '../shared/modals/ModalAddGenetics';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import ModalRegister from '../shared/modals/ModalRegister';
import { ProfileContext } from '../shared/contexts/profile-context';
import { pageType, transformImageUri, getMediaUrl } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import { formatDate } from '../shared/services/utils';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import NEWS_QUERY from "../shared/queries/news";

const News = (props) => {
	const [page, setPage] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [registerVisible, setRegisterVisible] = useState(false);
	const [profile, setProfile] = useContext(ProfileContext);

	const history = useHistory();
	const rowsPerPage = 20;
	const date = new Date();

	useEffect(
		() => {
			let page;
			if (!props.match.params.page) { 
				page = 1;
			} else {
				page = props.match.params.page;
			}
			setPage(page);
		},
		[props.match.params],
	);

	const start = (page) => {
		let firstRecord = (page - 1) * rowsPerPage;
		firstRecord < 0 ? firstRecord = 0 : null;

		return firstRecord;
	}

	const goToPage = (page) => {
		history.push('/news/' + page);
	}

	return(
		<>
		<Helmet>
			<title>НКП ВКК - База данных генетических тестов</title>
			<meta name="description" content="База данных генетических тестов членов НКП Вельш Корги Кардиган." />
			<meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, генетика, тесты, база данных" />
			<link rel="canonical" href="https://welsh-corgi.club/#/genetics" />
		</Helmet>

		<PageHeader pageType={pageType.NEWS} />

		<Query query={NEWS_QUERY} start={start(page)} limit={rowsPerPage} date={date.toISOString().split('.')[0] + 'Z'}>
			{({ data: { newsItems, newsItemsConnection } }) => {
				let totalRows = newsItemsConnection.aggregate.count;
				let totalPages = Math.ceil(totalRows / rowsPerPage);
				return(
					<Container className="mt-3 mb-3">

					<div className="nkp-box-shadow p-3">
						<div className="mb-3">
							{newsItems.map((item, index) => {
								return(
									<div key={index} className={index != 0 ? "py-3 border-top border-primary" : "py-3"}>
									<div className="d-flex pb-3 justify-content-end" data-testid="nkp-news-date">
										<i>
											<small>
												{formatDate(item.Date)}
											</small>
										</i>
									</div>

									{item.Short != null && 
										<div>
											<small  data-testid="nkp-news-text">
												<ReactMarkdown 
													rehypePlugins={[rehypeRaw]} transformImageUri={transformImageUri}
													children={item.Short} />
											</small>
										</div>
									}

									{item.Image != null && <div><center><Image src={getMediaUrl(item.Image)} /></center></div>}

									{item.Content != null && 
										<div className="pt-3">
											<small>
												<ReactMarkdown 
													rehypePlugins={[rehypeRaw]} transformImageUri={transformImageUri}
													children={item.Content} />
											</small>
										</div>
									}
									</div>
								)	
							}
						)}
						</div>

						<div className="d-flex justify-content-center">
								<PaginationPlus 
									totalRows={totalRows}
									page={page}
									totalPages={totalPages}
									rowsPerPage={rowsPerPage}
									goToPage={goToPage}
								/>
						</div>
					</div>
					</Container>
				);
			}}
		</Query>		

        <ModalAddGenetics visible={modalVisible} 
			profile={profile}
            callbackParent={() => setModalVisible(false)}
		/>

		<ModalRegister visible={registerVisible} 
			callbackParent={() => setRegisterVisible(false)}
		/>

		</>
	);
}

export default News;
