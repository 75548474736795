import React, { useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { formatDate } from '../shared/services/utils';
import { formatTicketTitle } from '../shared/services/ticket-utils';
import { trackPromise } from 'react-promise-tracker';
import ModalTicket from '../shared/modals/ModalTicket';
import { PageHeader } from '../shared/components/PageHeader';
import { pageType } from '../shared/services/strapi-access-services';
import { rejectTicket, processTicket } from '../shared/services/tickets-api';
import { ProfileContext } from '../shared/contexts/profile-context';
import Query from "../shared/components/QueryM";

import TICKETS_QUERY from "../shared/queries/tickets";

const ModeratorPanel = (props) => {
    const [tickets, setTickets] = useState([]);
    const [currentTicket, setCurrentTicket] = useState(null);
    const [visible, setVisible] = useState(false);

    const [profile, setProfile] = useContext(ProfileContext);

    const handleConfirm = (ticket, refetch) => {
        let newArray = [].concat(tickets)
        newArray.map((tkt) => {
            if (tkt.id == ticket.id) {
                tkt.Status = "Processing..."
            }
        })
        setTickets(newArray);
        trackPromise(
            processTicket(ticket)
            .then((result) => {
                refetch();
            })
        );
    }

    const handleReject = (ticket, refetch) => {
        let newArray = [].concat(tickets)
        newArray.map((tkt) => {
            if (tkt.id == ticket.id) {
                tkt.Status = "Processing..."
            }
        })
        setTickets(newArray);
        trackPromise(
            rejectTicket(ticket)
            .then(() => {
                refetch()
            })
        );
    }

    const handleSelectTicket = (e, ticket) => {
        e.preventDefault();
        setCurrentTicket(ticket);
        setVisible(true);
    }

    return(
        <>
        <PageHeader pageType={pageType.MODERATOR} />

        {profile?.role?.name == "Moderator" ?
        <>
        <Query query={TICKETS_QUERY} fetchPolicy="no-cache">
			{({ data: { tickets }, refetch }) => {
				return(
                    <>
                    <Container>
                    <div className="nkp-box-shadow p-3 mt-3 mb-5">
                        <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                            <th><small>#</small></th>
                            <th><small>Инициатор</small></th>
                            <th><small>Запрос</small></th>
                            <th><small>Дата</small></th>
                            <th><small>Ваше решение</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.map((ticket, index) => {
                                if (ticket.Status == "New" || ticket.Status == "Processing...") {
                                    return (
                                        <tr key={index} data-testid="nkp-moderator-ticket">
                                        <td>
                                            <small>
                                                <a href="" onClick={(e) => {handleSelectTicket(e, ticket)}}>
                                                    {ticket.id}
                                                </a>
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {ticket.users_permissions_user != null ? 
                                                ticket.users_permissions_user.name != "" ?
                                                ticket.users_permissions_user.name 
                                                :
                                                ticket.users_permissions_user.email
                                                : ""}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                <a href="" onClick={(e) => {handleSelectTicket(e, ticket)}} 
                                                    data-testid="nkp-moderator-ticket-modal">
                                                    {formatTicketTitle(ticket)}
                                                </a>
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {formatDate(ticket.published_at)}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                            {ticket.Status == "New" ?
                                            <>
                                                <Button size="sm" className="btn-success mx-2" 
                                                    data-testid="nkp-confirm" onClick={(e) => {handleConfirm(ticket, refetch)}}>
                                                    <small>Подтвердить</small>
                                                </Button>
                                                <Button size="sm" className="btn-danger mx-2" 
                                                    data-testid="nkp-cancel" onClick={(e) => {handleReject(ticket, refetch)}}>
                                                    <small>Отклонить</small>
                                                </Button>
                                            </>
                                            :
                                            <span>Обрабатывается...</span>
                                            }
                                            </small>
                                        </td>
                                        </tr>        
                                    )    
                                }
                            })}
                        </tbody>
                        </Table>
                    </div>
                    </Container>

                    <ModalTicket visible={visible} 
                        ticket={currentTicket}
                        refetch={refetch}
                        handleConfirm={handleConfirm} 
                        handleReject={handleReject}
                        callbackParent={() => setVisible(false)}
                    />
                    </>
                );
            }}
        </Query>        
        </>
        : 
            <div className="d-flex justify-content-center text-danger">У вас нет доступа к этому разделу.</div>
        }
        </>
    );
}

export default ModeratorPanel;