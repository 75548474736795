import React from 'react';
import { getContentObject, contentType, pageType, postContentObject, putContentObject, uploadMedia } from './strapi-access-services';
import addDogMethod from './methods/add-dog-method';
import geneticsMethod from './methods/genetics-method';
import dysplasiaMethod from './methods/dysplasia-method';
import manageMethod from './methods/manage-method';
import unmanageMethod from './methods/unmanage-method';
import ownMethod from './methods/own-method';
import okdResultsMethod from './methods/okd-results-method';
import agilityResultsMethod from './methods/agility-results-method';
import herdingResultsMethod from './methods/herding-results-method';
import puppiesMethod from './methods/puppies-method';
import prolongMethod from './methods/prolong-method';
import championMethod from './methods/champion-method';
import titlesMethod from './methods/titles-method';
import promoteMethod from './methods/promote-method';

export const submitTicket = (tType, data, profile, oldTicket) => {
    let type= tType;

    if (data.files?.length > 0) {
        const attachments = new FormData();

        for (var i = 0; i < data.files.length; i++) {
            attachments.append('files', data.files[i]);
        }            

        if (data.fotos?.length > 0) {
            for (var i = 0; i < data.fotos.length; i++) {
                attachments.append('files', data.fotos[i]);
            }                
        }
    
        return uploadMedia(attachments)
        .then((result) => { return result })
        .then((attachments) => {
            let ticket = formatTicket(type, profile, data);
            let files = [];
            let fotos;
            if (data.oldfiles?.length > 0) {
                files = [].concat(data.oldfiles);
            }
            attachments.map((pic) => {
                if (data.files?.filter(rec => rec.name == pic.name).length > 0) {
                    files.push(pic.id.toString());
                } else {
                    fotos = pic.id.toString();
                }
            });
            ticket.Attachment = files;
            ticket.Fotos = fotos;
            if (data.id !== undefined) {
                return putContentObject(contentType.TICKET, data.id, ticket, true);
            } else {
                return postContentObject(contentType.TICKET, ticket, true);
            }
        });
    } else {    
        let ticket = formatTicket(type, profile, data);
        let files = [];
        let fotos;
        if (data.oldfiles?.length > 0) {
            files = [].concat(data.oldfiles);
        }
        ticket.Attachment = files;
        ticket.Fotos = fotos;
        if (data.id !== undefined) {
            return putContentObject(contentType.TICKET, data.id, ticket, true);
        } else {
            return postContentObject(contentType.TICKET, ticket, true);
        }
    }
}

const formatTicket = (type, profile, data) => {
    let ticket = {};
    ticket.users_permissions_user = profile?.id;
    ticket.Status = "New";
    ticket.TicketContent = [];
    ticket.Type = type;
    if (!data.chooseDog || data.chooseDog == "0") {
        if (data.dogName !=  null) ticket.TicketContent.push({ Key: "dogName", Value: data.dogName });
        if (data.dogYear !=  null) ticket.TicketContent.push({ Key: "dogYear", Value: parseInt(data.dogYear, 10).toString() });
        ticket.dog = null;
    } else {
        ticket.dog = data.chooseDog;
    }

    for (const key in data) {
        if (key != "chooseDog" && key != "oldfiles" && key != "files" && key != "fotos" && key != "dogName" && key != "dogYear") {
            let value = data[key];
            if (typeof data[key] != "string") {
                value = data[key].toString();
            }
            ticket.TicketContent.push({ Key: key, Value: value });
        }
    }

    return ticket;
}

export const rejectTicket = (ticket) => {
    return putContentObject (contentType.TICKET, ticket.id, {Status: "Canceled"}, true)
    .then((result) => {
        return(result);
    });
}

const addDog = (ticket) => {
    let newDog = {};
    let files = [];

    ticket.attachments.map((pic) => {
        files.push(pic.id.toString());
    });
    newDog.DocGeneral = files;

    if (ticket.fotos != null) newDog.Pic = ticket.fotos.id; 
    newDog.Name = ticket.dogName;
    newDog.Year = ticket.dogYear;
    newDog.owner = ticket.owner.id;

    if (ticket.breed) newDog.breed = ticket.breed;
    if (ticket.stamp) newDog.stamp = ticket.stamp;
    if (ticket.chip) newDog.chip = ticket.chip;    

    return postContentObject(contentType.DOG, newDog, true)
    .then((dog) => { 
        if (!dog.ok) throw dog;
        return dog.json(); 
    })
    .catch((error) => {
        return ({message : "Other error", error : error});
    });
}

const getDog = (ticket) => {
    return getContentObject(contentType.DOG, [], null, null, null, ticket.dog.id)
    .then((dog) => {
        let newDocs = [];
        dog.DocGeneral.map((doc) => newDocs.push(doc.id))
        ticket.attachments.map((doc) => {
            newDocs.push(doc.id);
        });
        let update = { DocGeneral : newDocs };
        if (ticket.fotos != null) update = { ...update, Pic : ticket.fotos };
        return putContentObject(contentType.DOG, dog.id, update, true)
        .then(() => { return dog })
    })
}

const preparePayload = (ticket, mapping) => {
    let payload = {};
    mapping.map((item) => {
        if (ticket[item.tKey]) {
            switch(item.type) {
                case "boolean":
                    payload[item.bKey] = (ticket[item.tKey].toString().toLowerCase() === 'true');
                    break;
                case "text":
                    payload[item.bKey] = ticket[item.tKey];
                    break;
            }
        }        
    })
    return payload;
}

export const processTicket = (ticket) => {
    switch (ticket.Type) {
        case "AddDog":
            return addDogMethod(ticketToObject(ticket), addDog);
        case "Manage":
            return manageMethod(ticketToObject(ticket), getDog);
        case "Unmanage":
            return unmanageMethod(ticketToObject(ticket), getDog);
        case "Own":
            return ownMethod(ticketToObject(ticket), getDog);
        case "Genetics":
            return geneticsMethod(ticketToObject(ticket), addDog, getDog, preparePayload);
        case "Dysplasia":
            return dysplasiaMethod(ticketToObject(ticket), addDog, getDog, preparePayload);
        case "OKDResults":
            return okdResultsMethod(ticketToObject(ticket), addDog, getDog, preparePayload);
        case "AgilityResults":
            return agilityResultsMethod(ticketToObject(ticket), addDog, getDog, preparePayload);
        case "HerdingResults":
            return herdingResultsMethod(ticketToObject(ticket), addDog, getDog, preparePayload);            
        case "Puppies":
            return puppiesMethod(ticketToObject(ticket), preparePayload);            
        case "Prolong":
            return prolongMethod(ticketToObject(ticket));           
        case "Champion":
            return championMethod(ticketToObject(ticket), addDog, getDog);
        case "Titles":
            return titlesMethod(ticketToObject(ticket), addDog, getDog);
        case "Promote":
            return promoteMethod(ticketToObject(ticket), addDog, getDog, preparePayload);
    }
}

export const ticketToObject = (ticket) => {
    let obj = {};
    obj.id = ticket.id;
    obj.attachments = ticket.Attachment;
    obj.fotos = ticket.Fotos;
    obj.type = ticket.Type;
    obj.owner = ticket.users_permissions_user;
    obj.dog = ticket.dog;
    for (var i = 0; i < ticket.TicketContent.length; i++) {
        obj[ticket.TicketContent[i].Key] = ticket.TicketContent[i].Value;
    }
    return obj;
}