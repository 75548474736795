import { contentType, putContentObject } from '../strapi-access-services';

const addDogMethod = (ticket, addDogF) => {
    return Promise.resolve()
    .then(() => {
        return addDogF(ticket);
    })
    .then((result) => {
        if (result.error) throw result;
        return putContentObject(contentType.TICKET, 
                            ticket.id, 
                            { Status : "Approved" },
                            true);
    })
    .catch((error) => {
        return { error : "Other error", message : error };
    });
}

export default addDogMethod;