import React, { useState, useEffect, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ModalAddCurator from '../shared/modals/ModalAddCurator';
import ModalRemoveCurator from '../shared/modals/ModalRemoveCurator';
import ModalAddGenetics from '../shared/modals/ModalAddGenetics';
import ModalAddDysplasia from '../shared/modals/ModalAddDysplasia';
import ModalAddOKDResults from '../shared/modals/ModalAddOKDResults';
import ModalAddHerdingResults from '../shared/modals/ModalAddHerdingResults';
import ModalAddAgilityResults from '../shared/modals/ModalAddAgilityResults';
import ModalAddChampion from '../shared/modals/ModalAddChampion';
import ModalAddTitles from '../shared/modals/ModalAddTitles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFile, faCheck } from '@fortawesome/free-solid-svg-icons';
import { pageType, getEmptyDog, getMediaUrl } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import { ProfileContext } from '../shared/contexts/profile-context';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import DOG_PROFILE_QUERY from "../shared/queries/dog-profile";

const Dog = (props) => {
	const [dogId, setDogId] = useState(null);
	const [currentCurator, setCurrentCurator] = useState(null);
	const [addModalVisible, setAddModalVisible] = useState(false);
	const [removeModalVisible, setRemoveModalVisible] = useState(false);
	const [geneticsModalVisible, setGeneticsModalVisible] = useState(false);
	const [dysplasiaModalVisible, setDysplasiaModalVisible] = useState(false);
	const [okdModalVisible, setOKDModalVisible] = useState(false);
	const [herdingModalVisible, setHerdingModalVisible] = useState(false);
	const [agilityModalVisible, setAgilityModalVisible] = useState(false);
	const [championModal, setChampionModal] = useState(false);
	const [titlesModal, setTitlesModal] = useState(false);

	const [profile, setProfile] = useContext(ProfileContext);

	useEffect(
		() => {
			setDogId(props.match.params.id);
		},
		[props],
	);

	const genetic = {
		Clean: "Чистый",
		Carrier: "Носитель",
		Risk: "В зоне риска"
	}

	const ed = {
		A: 0,
		B: 1,
		C: 2
	}

	const okd = {
		Rank1: "1 степень",
		Rank2: "2 степень",
		Rank3: "3 степень"
	}

	const herding = {
		A: "Выражен",
		B: "В наличии",
		C: "Не выражен"
	}

	const checkPermissions = (dog) => {
		let result = false;
		if (dog != null && profile != null) {
			if (dog.curators.filter(rec => rec.email == profile.email).length > 0 
			|| dog.owner?.email == profile.email
			|| profile.role.name == "Moderator") {
				result = true;
			}
		}
		return result;
	}

	return(
		<>
           <Helmet>
                <title>НКП ВКК - карточка собаки</title>
                <meta name="description" content="Карточка собаки." />
                <meta name="keywords" content="собака, карточка" />
            </Helmet>

			<Query query={DOG_PROFILE_QUERY} slug={dogId || 0}>
				{({ data: { dog } }) => {
					return(
						<>
						<PageHeader pageType={pageType.DOG} altTitle={dog != null ? "Собака " + dog.Name : "Карточка собаки"}/>

						{dog != null && checkPermissions(dog) ?

						<Container className="mb-3 p-3 bg-white">
						<Row>
						<Col md={12}>
						<div className="nkp-box-shadow p-3 mb-4">
							<Row>
								<Col lg={4}>
									{dog.Pic != null ?
										<div data-testid="nkp-pic">
											<center>
												<Image src={getMediaUrl(dog.Pic)} style={{ maxWidth: "300px", maxHeight: "300px" }} fluid />
											</center>
										</div>
									: 
										<div data-testid="nkp-pic">
											<center>
												<Image src={getEmptyDog()} style={{ maxWidth: "300px", maxHeight: "300px" }} fluid />
											</center>
										</div>}
								</Col>
								<Col>
								<div className="d-flex flex-column h-100">
									<div className="d-flex">
										<small><b>Общие данные:</b></small>
									</div>
									{dog.owner != null ?
										<div data-testid="nkp-owner" className="d-flex">
											<small>Владелец - {dog.owner.name}</small>
										</div>
									: null}
									{dog.Year != null ?
										<div className="d-flex">
											<small>Год рождения - {dog.Year}</small>
										</div>
									: null}
									{dog.Champion == true ?
										<div data-testid="nkp-champion" className="d-flex">
											<small>Чемпион НКП</small>
										</div>
									: null}
									{dog.Titles != null ?
										<div className="d-flex">
											<small>Присвоенные титулы: {dog.Titles}</small>
										</div>
									: null}
									<div className="d-flex justify-content-end flex-grow-1 align-items-end pt-3">
										<div>
										<Button onClick={() => setChampionModal(true)} className="py-0" data-testid="nkp-but-champ">
											<small>
												Подтвердить титул чемпиона НКП
											</small>
										</Button>
										</div>
									</div>
									<div className="d-flex justify-content-end pt-3">
										<div>
										<Button onClick={() => setTitlesModal(true)} className="py-0" data-testid="nkp-but-titles">
											<small>
												Опубликовать титулы собаки
											</small>
										</Button>            
										</div>
									</div>
								</div>
								</Col>
							</Row>
						</div>
						</Col>
						</Row>

					<Row>
					<Col md={6}>
						<div className="nkp-box-shadow p-3 mb-4">
							<div>
								<small><b>Генетические исследования:</b></small>
							</div>
							{dog.genetic != null ?
							dog.genetic.PRA != null ?
								<div>
									<small>Прогрессирующая атрофия сетчатки (PRA) - {genetic[dog.genetic.PRA]}</small>
								</div>
							: null 
							: null}
							{dog.genetic != null ?
							dog.genetic.DM != null ?
								<div>
									<small>Дегенеративная миелопатия (ДМ) - {genetic[dog.genetic.DM]}</small>
								</div>
							: null 
							: null }
							{dog.genetic != null ?
							dog.genetic.Fluffy != null ?
								<div>
									<small>Флаффи - {genetic[dog.genetic.Fluffy]}</small>
								</div>
							: null 							
							: null }
							{dog.genetic != null ?
							dog.genetic.vWD != null ?
								<div data-testid="nkp-dog-vwd">
									<small>Болезнь Виллебранда (vWD) - {genetic[dog.genetic.vWD]}</small>
								</div>
							: null 							
							: null }
							{dog.genetic != null ?
							dog.genetic.Pink != null ?
								<div>
									<small>Аллель E - {genetic[dog.genetic.Pink]}</small>
								</div>
							: null 							
							: null }
							{dog.genetic != null ?
							dog.genetic.Deljut != null ?
								<div>
									<small>Аллель D - {genetic[dog.genetic.Deljut]}</small>
								</div>
							: null 							
							: null }
							{dog.genetic != null ?
							dog.genetic.Chocolate != null ?
								<div>
									<small>Аллель B - {genetic[dog.genetic.Chocolate]}</small>
								</div>
							: null 							
							: null }
							{dog.genetic != null ?
								<div>
									<small>Генетический паспорт - {dog.genetic.Passport ? <FontAwesomeIcon icon={faCheck} /> : "Отсутствует"}</small>
								</div>
							: null }
							<div className="d-flex justify-content-end pt-3">
								<Button onClick={() => setGeneticsModalVisible(true)} className="py-0" data-testid="nkp-gut-gen">
									<small>
										Добавить или обновить
									</small>
								</Button>            
							</div>
						</div>
						</Col>

						<Col md={6}>
						<div className="nkp-box-shadow p-3 mb-4">
							<div>
								<small><b>Исследования ED / HD:</b></small>
							</div>

							{dog.dysplasia != null ?
								dog.dysplasia.HD != null ?
								<div data-testid="nkp-dog-hd">
									<small>HD - {dog.dysplasia.HD}</small>
								</div>
								: null
								:null}
							{dog.dysplasia != null ?
								dog.dysplasia.ED != null ?
								<div>
									<small>ED - {ed[dog.dysplasia.ED]}</small>
								</div>
								: null
								:null}

							<div className="d-flex justify-content-end pt-3">
								<Button onClick={() => setDysplasiaModalVisible(true)} className="py-0" data-testid="nkp-gut-dysp">
									<small>
										Добавить или обновить
									</small>
								</Button>            
							</div>
						</div>
						</Col>

						<Col md={6}>
						<div className="nkp-box-shadow p-3 mb-4">
							<div>
								<small><b>Результаты тестирования ОКД и тестирования на темперамент:</b></small>
							</div>

							{dog.sports_results_okd != null ?
								<div data-testid="nkp-dog-okd">
									<small>Тестирование ОКД: {okd[dog.sports_results_okd.Rank]}</small>
								</div>
								:
								null }

								{dog.sports_results_okd != null ?
								<div data-testid="nkp-dog-temper">
									<small>Тестирование темперамента: {dog.sports_results_okd.Temperament}</small>
								</div>
								:
								null }

							<div className="d-flex justify-content-end pt-3">
								<Button onClick={() => setOKDModalVisible(true)} className="py-0" data-testid="nkp-but-okd">
									<small>
										Добавить или обновить
									</small>
								</Button>            
							</div>
						</div>
						</Col>

						<Col md={6}>
						<div className="nkp-box-shadow p-3 mb-4">
							<div>
								<small><b>Результаты тестирования пастушьего инстинкта:</b></small>
							</div>

							{dog.sports_results_herding != null ?
								dog.sports_results_herding.TPI != null ?
								<div>
									<small>ТПИ: {herding[dog.sports_results_herding.TPI]}</small>
								</div>
								:
								null
							: null }

							{dog.sports_results_herding != null ?
								dog.sports_results_herding.Test != null ?
								<div data-testid="nkp-dog-herding"><small>Испытания: {dog.sports_results_herding.Test}</small></div>
								:
								null
							: null }

							{dog.sports_results_herding != null ?
								dog.sports_results_herding.Rank1Class != null && dog.sports_results_herding.Rank1Class != "" ?
								<div><small>IHT-TS-1: {dog.sports_results_herding.Rank1Class}</small></div>
								:
								null
							: null }

							{dog.sports_results_herding != null ?
								dog.sports_results_herding.Rank2Class != null && dog.sports_results_herding.Rank2Class != "" ?
								<div><small>IHT-TS-2: {dog.sports_results_herding.Rank2Class}</small></div>
								:
								null
							: null }

							{dog.sports_results_herding != null && dog.sports_results_herding.Rank3Class != "" ?
								dog.sports_results_herding.Rank3Class != null ?
								<div><small>IHT-TS-3: {dog.sports_results_herding.Rank3Class}</small></div>
								:
								null
							: null }

							{dog.sports_results_herding != null ?
								dog.sports_results_herding.Title != null && dog.sports_results_herding.Title != "" ?
								<div><small>Титулы: {dog.sports_results_herding.Title}</small>
								</div>
								:
								null
							: null }

							<div className="d-flex justify-content-end pt-3">
								<Button onClick={() => setHerdingModalVisible(true)} className="py-0" data-testid="nkp-dog-herd">
									<small>
										Добавить или обновить
									</small>
								</Button>            
							</div>
						</div>
						</Col>

						<Col md={6}>
						<div className="nkp-box-shadow p-3 mb-4">
							<div>
								<small><b>Результаты соревнований по аджилити:</b></small>
							</div>
							{dog.sports_results_agility != null ?
								dog.sports_results_agility.A1 != null &&
								<div data-testid="nkp-dog-agility">
									<small>A1: {dog.sports_results_agility.A1}</small>
								</div>
								:
							null }
							{dog.sports_results_agility != null ?
								dog.sports_results_agility.A2 != null &&
								<div data-testid="nkp-dog-agility">
									<small>A2: {dog.sports_results_agility.A2}</small>
								</div>
								:
							null }
							{dog.sports_results_agility != null ?
								dog.sports_results_agility.A3 != null &&
								<div data-testid="nkp-dog-agility">
									<small>A3: {dog.sports_results_agility.A3}</small>
								</div>
								:
							null }
							<div className="d-flex justify-content-end pt-3">
								<Button onClick={() => setAgilityModalVisible(true)} className="py-0" data-testid="nkp-but-agil">
									<small>
										Добавить или обновить
									</small>
								</Button>            
							</div>
						</div>
						</Col>

						<Col md={6}>
						<div className="nkp-box-shadow p-3 mb-4">
							<div>
								<small><b>Кураторы:</b></small>
							</div>

							{dog.curators.map((user, index) => {
								return (
								<div key={index} data-testid="nkp-dog-cur">
									<small>{user.name}
										{user.email == profile.email ?
										null
										: 
										<OverlayTrigger
											placement="right"
											delay={{ show: 250, hide: 400 }}
											overlay={
												<Tooltip id="button-tooltip">
													Отозвать права куратора
												</Tooltip>
											}
										>
											<FontAwesomeIcon icon={faTimes} style={{ color: "#dc3545" }} 
												className="ml-2" data-testid="nkp-but-remove-cur"
												onClick={() => {setCurrentCurator(user); setRemoveModalVisible(true)}}
											/>
										</OverlayTrigger>}
									</small>
								</div>
								)
							})}

							<div className="d-flex justify-content-end pt-3">
								<Button onClick={() => setAddModalVisible(true)} className="py-0" data-testid="nkp-but-cur">
									<small>
										Добавить куратора
									</small>
								</Button>            
							</div>
						</div>
					</Col>
					<Col md={6}>
						<div className="nkp-box-shadow p-3 mb-4">
							<div data-testid="nkp-dog-doc">
								{dog.DocGeneral.length > 0 ?
									<small><b>Загруженные документы:</b></small>
									:
									<small><b>Ни одного документа не загружено</b></small>							
								}
							</div>
							{dog.DocGeneral.map((doc, index) => {
								return(
									<div key={index}>	
										<a href={getMediaUrl(doc)} target="_blank">
											<FontAwesomeIcon icon={faFile} /> <small>{doc.name}</small>
										</a>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
			</Container>
			
			:
			<h4 style={{ color: "red" }}><center>У вас нет прав для доступа к этому разделу.</center></h4>
			}

				<ModalAddChampion visible={championModal} 
					callbackParent={() => setChampionModal(false)}
					dog={dog}
				/>

				<ModalAddTitles visible={titlesModal} 
					callbackParent={() => setTitlesModal(false)}
					dog={dog}
				/>

				<ModalAddCurator visible={addModalVisible} 
					callbackParent={() => setAddModalVisible(false)}
					dog={dog}
				/>

				<ModalRemoveCurator visible={removeModalVisible} 
					callbackParent={() => setRemoveModalVisible(false)}
					currentCurator={currentCurator}
					dog={dog}
				/>

				<ModalAddGenetics visible={geneticsModalVisible} 
					dog={dog}
					callbackParent={() => setGeneticsModalVisible(false)}
				/>

				<ModalAddDysplasia visible={dysplasiaModalVisible} 
					dog={dog}
					callbackParent={() => setDysplasiaModalVisible(false)}
				/>

				<ModalAddOKDResults visible={okdModalVisible} 
					dog={dog}
					callbackParent={() => setOKDModalVisible(false)}
				/>

				<ModalAddHerdingResults visible={herdingModalVisible} 
					dog={dog}
					callbackParent={() => setHerdingModalVisible(false)}
				/>

				<ModalAddAgilityResults visible={agilityModalVisible} 
					dog={dog}
					callbackParent={() => setAgilityModalVisible(false)}
				/>
			</>
		);
		}}
		</Query>			

		</>
	);
}

export default Dog;