import { contentType, getContentObject, postContentObject, putContentObject } from '../strapi-access-services';

const memberMapping = [
    { "tKey" : "name", "bKey" : "Name", "type" : "text" },
    { "tKey" : "birthday", "bKey" : "Birthday", "type" : "text" },
    { "tKey" : "memberEmail", "bKey" : "Email", "type" : "text" },
    { "tKey" : "phone", "bKey" : "Phone", "type" : "text" },
    { "tKey" : "address", "bKey" : "Address", "type" : "text" },
    { "tKey" : "memberName", "bKey" : "MemberName", "type" : "text" },
    { "tKey" : "managerName", "bKey" : "Name", "type" : "text" },
    { "tKey" : "prefix", "bKey" : "Prefix", "type" : "text" },
    { "tKey" : "chiefName", "bKey" : "ChiefName", "type" : "text" },
    { "tKey" : "jurAddress", "bKey" : "JurAddress", "type" : "text" },
    { "tKey" : "nkpSupport1", "bKey" : "Support1", "type" : "boolean" },
    { "tKey" : "nkpSupport2", "bKey" : "Support2", "type" : "boolean" },
    { "tKey" : "nkpSupport3", "bKey" : "Support3", "type" : "boolean" },
    { "tKey" : "nkpSupport4", "bKey" : "Support4", "type" : "boolean" },
    { "tKey" : "nkpSupport5", "bKey" : "Support5", "type" : "boolean" },
    { "tKey" : "supportOther", "bKey" : "SupportOwn", "type" : "text" },
];

// { breed: "breed" },
// { stamp: "stamp" },
// { chip: "chip" },

const checkNewDog = (ticket) => {
    if (ticket.dog == null) return true;
    return false;
}

const getMemberType = (type) => {
    switch (type) {
        case "Владелец" :
            return "Owner";
        case "Питомник" :
            return "Kennel";
        case "Приставка" :
            return "Prefix";
        case "Клуб" :
            return "Club";
    }

    return "";
}

const promoteMethod = (ticket, addDogF, getDogF, prepF) => {
    return Promise.resolve()
    .then(() => {
        if (checkNewDog(ticket)) {
            return addDogF(ticket);
        } 
        return getDogF(ticket);
    })
    .then((dog) => {
        if (dog.error) throw dog.error;
        let memPayload = {...prepF(ticket, memberMapping), Type : getMemberType(ticket.memberType)};
        return postContentObject(contentType.MEMBERS, memPayload, true);
    })
    .then((result) => {
        if (!result.ok) throw result;
        return getContentObject(contentType.ROLES)
        .then((result) => { 
            return result;
        })
        .then((result) => { 
            if (result.error) throw result;
            return (result.roles.filter(rec => rec.type == "members")[0].id);
        });
    })
    .then((roleId) => {
        if (!typeof(roleId) == "number") throw roleId;
        return putContentObject(contentType.USERS, ticket.owner.id, { role : roleId }, true);
    })
    .then((result) => {
        if (!result.ok) throw result;
        return putContentObject(contentType.TICKET, ticket.id, { Status : "Approved" }, true);
    })
    .catch((error) => {
        console.log("Caught error", error)
        return { error : "Other error", message : error };
    });
}

export default promoteMethod;