import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { pageType } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import MEMBERS_QUERY from "../shared/queries/members";

const Members = (props) => {

    return(
        <>
        <Helmet>
            <title>НКП ВКК - Список членов НКП</title>
            <meta name="description" content="Список членов НКП Вельш Корги Кардиган." />
            <meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, члены, питомник, клуб, заводчик, владелец" />
            <link rel="canonical" href="https://welsh-corgi.club/#/members" />
        </Helmet>

        <PageHeader pageType={pageType.MEMBERS} />

        <Query query={MEMBERS_QUERY}>
        {({ data: { members } }) => {
            return(
                <Container className="mt-3 mb-3 p-3 bg-white">
                    <Row>
                        <Col className="nkp-box-shadow p-3 m-3" data-testid="nkp-member-type">
                            <b>Клубы</b>
                            <Table striped bordered hover>
                            <tbody>
                                {members.map((member, index) => {
                                    if(member.Type == "Club") {
                                        return (
                                            <tr key={index}>
                                                <td data-testid="nkp-member"><small>{member.MemberName} {member.City != null ? "(" + member.City + ")" : null}</small></td>
                                            </tr>	
                                        )		    
                                    }
                                })}
                            </tbody>
                            </Table>
                        </Col>
                        </Row>
                        <Row>
                        <Col className="nkp-box-shadow p-3 m-3" data-testid="nkp-member-type">
                            <b>Питомники и заводские приставки</b>
                            <Table striped bordered hover>
                            <tbody>
                                {members.map((member, index) => {
                                    if(member.Type == "Kennel") {
                                        return (
                                            <tr key={index}>
                                                <td data-testid="nkp-member"><small>Питомник "{member.MemberName.toUpperCase()}"; {member.Name} {member.City != null ? "(" + member.City + ")" : null}</small></td>
                                            </tr>	
                                        )		    
                                    } else if (member.Type == "Prefix") {
                                        return (
                                            <tr key={index}>
                                                <td data-testid="nkp-member"><small>Зав.приставка "{member.MemberName.toUpperCase()}"; {member.Name} {member.City != null ? "(" + member.City + ")" : null}</small></td>
                                            </tr>	
                                        )		    
                                    }
                                })}
                            </tbody>
                            </Table>
                        </Col>
                        </Row>
                        <Row>
                        <Col className="nkp-box-shadow p-3 m-3" data-testid="nkp-member-type">
                        <b>Владельцы</b>
                        <Table striped bordered hover>
                            <tbody>
                                {members.map((member, index) => {
                                    if(member.Type == "Owner") {
                                        return (
                                            <tr key={index}>
                                                <td data-testid="nkp-member"><small>{member.Name} {member.City != null ? "(" + member.City + ")" : null}</small></td>
                                            </tr>	
                                        )		    
                                    }
                                })}
                            </tbody>
                        </Table>
                        </Col>
                    </Row>
                </Container>
                );
            }}
        </Query>    
        </>
    );
}

export default Members;