import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileImage, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { formatTicketTitle } from '../shared/services/ticket-utils';
import Table from 'react-bootstrap/Table';
import { genetics, buildTestResult } from '../shared/services/utils';
import { getMediaUrl } from '../shared/services/strapi-access-services';
import Query from "../shared/components/Query";

import CURATOR_QUERY from "../shared/queries/curator";
import ANNOUNCEMENT_QUERY from "../shared/queries/announcement";

export const TicketCard = (props) => {
    const [ticket, setTicket] = useState(props.ticket);
    const [curatorId, setCuratorId] = useState(null);
    const [announcementId, setAnnouncementId] = useState(null);

    useEffect(
        () => {
            if (ticket != props.ticket) {
                console.log(props.ticket)
                setTicket(props.ticket);
            }
            if (ticket.TicketContent.filter(rec => rec.Key == "curatorId").length > 0) {
                setCuratorId(ticket.TicketContent.filter(rec => rec.Key == "curatorId")[0].Value);
            }
            if (ticket.TicketContent.filter(rec => rec.Key == "puppiesId").length > 0) {
                setAnnouncementId(ticket.TicketContent.filter(rec => rec.Key == "puppiesId")[0].Value);
            }

        },
        [props.ticket]
    );    

    const dysplasia = {
        A : 0,
        B : 1,
        C : 2
    }

    const okd = {
        Rank1 : "1 степень",
        Rank2 : "2 степень",
        Rank3 : "3 степень",
    }

    const herding = {
        A : "Выражен",
        B : "В наличии",
        C : "Не выражен",
    }

    return (
        <div className="nkp-dog-plate p-2 m-2">
            <div data-testid="nkp-ticket-user">
                <small><b>Инициатор:</b> {ticket.users_permissions_user.name}</small>
            </div>
            <div data-testid="nkp-ticket-subj">
                <small><b>Тема:</b> {formatTicketTitle(ticket)}</small>
            </div>
            {ticket.Type != "Prolong" && ticket.Type != "Promote" && ticket.Type != "Puppies" ?
            <>
            <div data-testid="nkp-ticket-dog-name">
                <small><b>Кличка собаки: </b> 
                {ticket.dog == null ? 
                    ticket.TicketContent.filter(rec => rec.Key == "dogName").length > 0 &&
                    ticket.TicketContent.filter(rec => rec.Key == "dogName")[0].Value 
                : 
                    ticket.dog.Name
                }</small>
            </div>
            <div data-testid="nkp-ticket-dog-year">
                <small><b>Год рождения собаки: </b> 
                {ticket.dog == null ? 
                    ticket.TicketContent.filter(rec => rec.Key == "dogYear").length > 0 &&
                    ticket.TicketContent.filter(rec => rec.Key == "dogYear")[0].Value
                :
                    ticket.dog.Year
                }</small>
            </div>
            </>
            : null }
            {ticket.Type == "Genetics" || ticket.Type == "AddDogAndGenetics" ?
                ticket.TicketContent.filter(rec => rec.Key == "lab").length > 0 &&
                <div data-testid='nkp-ticket-lab'>
                    <small><b>Лаборатория:</b> {ticket.TicketContent.filter(rec => rec.Key == "lab")[0].Value}</small>
                </div>
            : null}
            {ticket.Type == "Dysplasia" || ticket.Type == "AddDogAndDysplasia" ?
                ticket.TicketContent.filter(rec => rec.Key == "doctor").length > 0 &&
                <div data-testid='nkp-ticket-doc'>
                    <small><b>Доктор:</b> {ticket.TicketContent.filter(rec => rec.Key == "doctor")[0].Value}</small>
                </div>
            : null}
            {ticket.Type == "Genetics" || ticket.Type == "AddDogAndGenetics" ?
            <>
                <div>
                    <small><b>Результаты:</b></small>
                </div>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <td><small>Тест</small></td>
                                <td><small>Результат</small></td>
                            </tr>
                        </thead>
                        <tbody>
                            {genetics.map((gen, index) => {
                                if (ticket != null && ticket.TicketContent.filter(rec => rec.Key == gen.code).length > 0) {
                                    return (
                                        <tr key={index} data-testid='nkp-ticket-gen'>
                                            <td><small>{gen.title} ({gen.short})</small></td>
                                            <td><small>{buildTestResult(ticket.TicketContent.filter(rec => rec.Key == gen.code)[0].Value)}</small></td>
                                        </tr>    
                                    )
                                }
                            })}
                            <tr data-testid='nkp-passport'>
                                <td><small>Генетический паспорт</small></td>
                                <td>
                                    <small>
                                        {ticket.TicketContent.filter(rec => rec.Key == "consentGenPassport").length > 0 &&
                                        ticket.TicketContent.filter(rec => rec.Key == "consentGenPassport")[0].Value == "true" ?
                                        "В наличии" : "Отсутствует"}
                                    </small>
                                </td>
                            </tr>    
                        </tbody>
                    </Table>
                </div>
            </>
            : null }
            {ticket.Type == "Dysplasia" || ticket.Type == "AddDogAndDysplasia" ?
                <>
                <div>
                    <small><b>Результаты:</b></small>
                </div>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <td><small>Тест</small></td>
                                <td><small>Результат</small></td>
                            </tr>
                        </thead>
                        <tbody>
                            {ticket.TicketContent.filter(rec => rec.Key == "HD").length > 0 ?
                                <tr data-testid="nkp-ticket-hd">
                                    <td>HD</td>
                                    <td>{ticket.TicketContent.filter(rec => rec.Key == "HD")[0].Value}</td>
                                </tr>    
                            : null}
                            {ticket.TicketContent.filter(rec => rec.Key == "ED").length > 0 ?
                                <tr data-testid="nkp-ticket-ed">
                                    <td>ED</td>
                                    <td>{dysplasia[ticket.TicketContent.filter(rec => rec.Key == "ED")[0].Value]}</td>
                                </tr>    
                            : null}
                        </tbody>
                    </Table>
                </div>
                </>
            : null }
            {ticket.Type == "OKDResults" || ticket.Type == "AddDogAndOKDResults" ?
            ticket.TicketContent.filter(rec => rec.Key == "judge").length &&
            <div data-testid='nkp-ticket-judge'>
                <small><b>Судья:</b> {ticket.TicketContent.filter(rec => rec.Key == "judge")[0].Value}</small>
            </div>
            : null }
            {ticket.Type == "OKDResults" || ticket.Type == "AddDogAndOKDResults" ?
                ticket.TicketContent.filter(rec => rec.Key == "chooseOKD").length > 0 ?
                    <div data-testid="nkp-ticket-okd">
                        <small><b>Результат тестирования ОКД:</b> {okd[ticket.TicketContent.filter(rec => rec.Key == "chooseOKD")[0].Value]}</small>
                    </div>
                : null
            : null }
            {ticket.Type == "OKDResults" || ticket.Type == "AddDogAndOKDResults" ?
                ticket.TicketContent.filter(rec => rec.Key == "chooseTemper").length > 0 ?
                    <div data-testid="nkp-ticket-temper">
                        <small><b>Результат тестирования темперамента:</b> {ticket.TicketContent.filter(rec => rec.Key == "chooseTemper")[0].Value}</small>
                    </div>
                : null
            : null }
            {ticket.Type == "HerdingResults" || ticket.Type == "AddDogAndHerdingResults" ?
                <>
                <div>
                    <small><b>Результаты:</b></small>
                </div>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <td><small>Тест</small></td>
                                <td><small>Результат</small></td>
                            </tr>
                        </thead>
                        <tbody>
                            {ticket.TicketContent.filter(rec => rec.Key == "chooseHerding").length > 0 ?
                                <tr>
                                    <td>ТПИ</td>
                                    <td>{herding[ticket.TicketContent.filter(rec => rec.Key == "chooseHerding")[0].Value]}</td>
                                </tr>    
                            : null}
                            {ticket.TicketContent.filter(rec => rec.Key == "test").length > 0 ?
                                <tr data-testid="nkp-ticket-herding-test">
                                    <td>Результат испытаний</td>
                                    <td>{ticket.TicketContent.filter(rec => rec.Key == "test")[0].Value}</td>
                                </tr>    
                            : null}
                            {ticket.TicketContent.filter(rec => rec.Key == "IHT-TS-1").length > 0 ?
                                <tr data-testid="nkp-ticket-herding-ihtts1">
                                    <td>IHT-TS-1</td>
                                    <td>{ticket.TicketContent.filter(rec => rec.Key == "IHT-TS-1")[0].Value}</td>
                                </tr>    
                            : null}
                            {ticket.TicketContent.filter(rec => rec.Key == "IHT-TS-2").length > 0 ?
                                <tr>
                                    <td>IHT-TS-2</td>
                                    <td>{ticket.TicketContent.filter(rec => rec.Key == "IHT-TS-2")[0].Value}</td>
                                </tr>    
                            : null}
                            {ticket.TicketContent.filter(rec => rec.Key == "IHT-TS-3").length > 0 ?
                                <tr>
                                    <td>IHT-TS-3</td>
                                    <td>{ticket.TicketContent.filter(rec => rec.Key == "IHT-TS-3")[0].Value}</td>
                                </tr>    
                            : null}
                            {ticket.TicketContent.filter(rec => rec.Key == "titles").length > 0 ?
                                <tr>
                                    <td>Титулы:</td>
                                    <td>{ticket.TicketContent.filter(rec => rec.Key == "titles")[0].Value}</td>
                                </tr>    
                            : null}
                        </tbody>
                    </Table>
                </div>
                </>
            : null }
            {ticket.Type == "AgilityResults" ?
                <>
                <div>
                    <small><b>Результаты:</b></small>
                </div>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <td><small>Тест</small></td>
                                <td><small>Результат</small></td>
                            </tr>
                        </thead>
                        <tbody>
                            {ticket.TicketContent.filter(rec => rec.Key == "a1").length > 0 ?
                                <tr data-testid="nkp-ticket-agility-a1">
                                    <td>A1</td>
                                    <td>{ticket.TicketContent.filter(rec => rec.Key == "a1")[0].Value}</td>
                                </tr>    
                            : null}
                            {ticket.TicketContent.filter(rec => rec.Key == "a2").length > 0 ?
                                <tr>
                                    <td>A2</td>
                                    <td>{ticket.TicketContent.filter(rec => rec.Key == "a2")[0].Value}</td>
                                </tr>    
                            : null}
                            {ticket.TicketContent.filter(rec => rec.Key == "a3").length > 0 ?
                                <tr>
                                    <td>A3</td>
                                    <td>{ticket.TicketContent.filter(rec => rec.Key == "a3")[0].Value}</td>
                                </tr>    
                            : null}
                        </tbody>
                    </Table>
                </div>
                </>
            : null }
            {(ticket.Type == "Manage" || ticket.Type == "Unmanage") && curatorId != null &&
                <Query query={CURATOR_QUERY} slug={curatorId}>
                {({ data: { user } }) => {
                    return(
                        <div data-testid="nkp-ticket-curator">
                            <small><b>Куратор:</b> {user.name}</small>
                        </div>
                    );
                }}
                </Query>
            }
            {ticket.Type == "Prolong" && announcementId != null &&
                <Query query={ANNOUNCEMENT_QUERY} slug={announcementId}>
                {({ data: { puppy } }) => {
                    return(
                        <>
                            <div>
                                <small><b>Текст объявления:</b></small>
                            </div>
                            <div data-testid="nkp-ticket-puppies-text">
                                <small>{puppy.Content}</small>
                            </div>
                        </>
                    );
                }}
                </Query>
            }
            {ticket.Type == "Puppies" ?
            <>
                <div>
                    <small><b>Текст объявления:</b></small>
                </div>
                {ticket.TicketContent.filter(rec => rec.Key == "puppies").length > 0 &&
                <div data-testid="nkp-ticket-puppies-text">
                    <small>{ticket.TicketContent.filter(rec => rec.Key == "puppies")[0].Value}</small>
                </div>}
            </>
            : null }
            {ticket.Type == "Titles" ?
            <>
                <div>
                    <small><b>Присвоенные титулы:</b></small>
                </div>
                {ticket.TicketContent.filter(rec => rec.Key == "titles").length > 0 &&
                <div data-testid="nkp-ticket-titles">
                    <small>{ticket.TicketContent.filter(rec => rec.Key == "titles")[0].Value}</small>
                </div>}
            </>
            : null }
            {ticket.Type == "Champion" && ticket.Fotos != null &&
            <>
            <div>
                <small><b>Фото собаки:</b></small>
            </div>
            <div className="mt-2">
                <div  data-testid="nkp-ticket-foto">
                    <a href={getMediaUrl(ticket.Fotos)} target="_blank">
                        <FontAwesomeIcon 
                            icon={ticket.Fotos.ext == ".pdf" ? faFilePdf 
                            : ticket.Fotos.ext == ".jpg" || ticket.Fotos.ext == ".jpeg" || 
                                ticket.Fotos.ext == ".png" || ticket.Fotos.ext == ".tif" ?
                            faFileImage
                            : faFileAlt} /> <small>{ticket.Fotos.name}</small>
                    </a>
                </div>
            </div>
            </>}
            {ticket.Type != "Manage" && ticket.Type != "Unmanage" && ticket.Type != "Prolong" && ticket.Type != "Puppies" ?
            <>
            <div>
                <small><b>Предоставленные документы:</b></small>
            </div>
            <div className="mt-2">
                {ticket.Attachment.map((attach, index) => {
                    return(
                        <div key={index} data-testid="nkp-ticket-attach">
                            <a href={getMediaUrl(attach)} target="_blank">
                                <FontAwesomeIcon 
                                    icon={attach.ext == ".pdf" ? faFilePdf 
                                    : attach.ext == ".jpg" || attach.ext == ".jpeg" || attach.ext == ".png" || attach.ext == ".tif" ?
                                    faFileImage
                                    : faFileAlt} /> <small>{attach.name}</small>
                            </a>
                        </div>
                    )    
                })}
            </div>
            </>
            : null}
            {ticket.Type == "Promote" ?
            <>
            {ticket.TicketContent.filter(rec => rec.Key == "name").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "memberType")[0].Value == "Клуб" ?
            <div data-testid="nkp-ticket-promote-manager">
                <small><b>Руководитель секции:</b> {ticket.TicketContent.filter(rec => rec.Key == "managerName")[0].Value}</small>
            </div>
            :
            <div data-testid="nkp-ticket-promote-name">
                <small><b>Ф.И.О.:</b> {ticket.TicketContent.filter(rec => rec.Key == "name")[0].Value}</small>
            </div>
            :
            null }
            {ticket.TicketContent.filter(rec => rec.Key == "birthday").length > 0 ?
            <div data-testid="nkp-ticket-promote-birthday">
                <small><b>Дата рождения:</b> {ticket.TicketContent.filter(rec => rec.Key == "birthday")[0].Value}</small>
            </div>
            :
            null }
            {ticket.TicketContent.filter(rec => rec.Key == "memberType").length > 0 &&
            <div data-testid="nkp-ticket-promote-type">
                <small><b>Тип членства:</b> {ticket.TicketContent.filter(rec => rec.Key == "memberType")[0].Value}</small>
            </div>}
            {ticket.TicketContent.filter(rec => rec.Key == "memberType").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "memberType")[0].Value == "Питомник" ?
            <div data-testid="nkp-ticket-promote-kennel">
                <small><b>Название питомника:</b> {ticket.TicketContent.filter(rec => rec.Key == "memberName")[0].Value}</small>
            </div>
            :
            ticket.TicketContent.filter(rec => rec.Key == "memberType")[0].Value == "Приставка" ?
            <div data-testid="nkp-ticket-promote-prefix">
                <small><b>Название приставки: </b> {ticket.TicketContent.filter(rec => rec.Key == "memberName")[0].Value}</small>
            </div>
            :
            ticket.TicketContent.filter(rec => rec.Key == "memberType")[0].Value == "Клуб" ?
            <div data-testid="nkp-ticket-promote-club">
                <small><b>Название клуба</b> {ticket.TicketContent.filter(rec => rec.Key == "memberName")[0].Value}</small>
            </div>
            :
            null
            :
            null }
            {ticket.TicketContent.filter(rec => rec.Key == "prefix").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "prefix")[0].Value != "" ?
            <div data-testid="nkp-ticket-promote-stamp-code">
                <small><b>Код клейма</b> {ticket.TicketContent.filter(rec => rec.Key == "prefix")[0].Value}</small>
            </div>
            : null 
            : null }
            {ticket.TicketContent.filter(rec => rec.Key == "phone").length > 0 ?
            <div data-testid="nkp-ticket-promote-phone">
                <small><b>Телефон</b> {ticket.TicketContent.filter(rec => rec.Key == "phone")[0].Value}</small>
            </div>    
            : null}
            {ticket.TicketContent.filter(rec => rec.Key == "memberEmail").length > 0 ?
            <div data-testid="nkp-ticket-promote-mail">
                <small><b>Электронная почта</b> {ticket.TicketContent.filter(rec => rec.Key == "memberEmail")[0].Value}</small>
            </div>
            : null }
            {ticket.TicketContent.filter(rec => rec.Key == "address").length > 0 ?
            <div data-testid="nkp-ticket-promote-address">
                <small><b>Адрес</b> {ticket.TicketContent.filter(rec => rec.Key == "address")[0].Value}</small>
            </div>
            : null }
            {ticket.TicketContent.filter(rec => rec.Key == "jurAddress").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "jurAddress")[0].Value != "" ?
            <div data-testid="nkp-ticket-promote-legal">
                <small><b>Юридический адрес</b> {ticket.TicketContent.filter(rec => rec.Key == "jurAddress")[0].Value}</small>
            </div>
            : null
            : null }
            {ticket.TicketContent.filter(rec => rec.Key == "chiefName").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "chiefName")[0].Value != "" ?
            <div data-testid="nkp-ticket-promote-chief">
                <small><b>Руководитель клуба:</b> {ticket.TicketContent.filter(rec => rec.Key == "chiefName")[0].Value}</small>
            </div>
            : null
            : null }
            {ticket.TicketContent.filter(rec => rec.Key == "dogName").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "dogName")[0].Value != "" ?
            <div data-testid="nkp-ticket-promote-dogname">
                <small><b>Имя собаки:</b> {ticket.TicketContent.filter(rec => rec.Key == "dogName")[0].Value}</small>
            </div>
            : null
            : null }
            {ticket.TicketContent.filter(rec => rec.Key == "dogYear").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "dogYear")[0].Value != "" ?
            <div data-testid="nkp-ticket-promote-dogyear">
                <small><b>Год рождения собаки:</b> {ticket.TicketContent.filter(rec => rec.Key == "dogYear")[0].Value}</small>
            </div>
            : null
            : null }
            {ticket.dog != null ?
            <div data-testid="nkp-ticket-promote-dog">
                <small><b>Собака (добавлено ранее):</b> <Link to={"/dog/" + ticket.dog.id}>{ticket.dog.Name} ({ticket.dog.Year})</Link></small>
            </div>
            :
            null }
            {ticket.TicketContent.filter(rec => rec.Key == "breed").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "breed")[0].Value != "" ?
            <div data-testid="nkp-ticket-promote-breed">
                <small><b>Номер родословной:</b> {ticket.TicketContent.filter(rec => rec.Key == "breed")[0].Value}</small>
            </div>
            : null
            : null }
            {ticket.TicketContent.filter(rec => rec.Key == "stamp").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "stamp")[0].Value != "" ?
            <div data-testid="nkp-ticket-promote-stamp">
                <small><b>Клеймо:</b> {ticket.TicketContent.filter(rec => rec.Key == "stamp")[0].Value}</small>
            </div>
            : null
            : null }
            {ticket.TicketContent.filter(rec => rec.Key == "chip").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "chip")[0].Value != "" ?
            <div data-testid="nkp-ticket-promote-chip">
                <small><b>Микрочип:</b> {ticket.TicketContent.filter(rec => rec.Key == "chip")[0].Value}</small>
            </div>
            : null
            : null }
            <div>
                <small><b>Какой вклад в деятельность НКП вы готовы внести:</b></small>
                <ul>
                    {ticket.TicketContent.filter(rec => rec.Key == "nkpSupport1").length > 0 &&
                    <li><small>Финансовая поддержка.</small></li>}
                    {ticket.TicketContent.filter(rec => rec.Key == "nkpSupport2").length > 0 &&
                    <li><small>Предоставление призов на монопородные выставки и соревнования.</small></li>}
                    {ticket.TicketContent.filter(rec => rec.Key == "nkpSupport3").length > 0 &&
                    <li><small>Помощь в организации Чемпионата породы (найти спонсоров, привезти / отвезти 
                        инвентарь, встретить / отвезти экспертов и т.д.).</small></li>}
                    {ticket.TicketContent.filter(rec => rec.Key == "nkpSupport4").length > 0 &&
                    <li><small>Производство и размещение рекламы НКП и монопородных выставок на 
                        дрессировочных площадках и площвдках для выгула собак в вашем  регионе.</small></li>}
                    {(ticket.TicketContent.filter(rec => rec.Key == "nkpSupport5").length > 0 &&
                    ticket.TicketContent.filter(rec => rec.Key == "supportOther").length > 0) &&
                    <li><small>Другое:</small><br />
                    <small>{ticket.TicketContent.filter(rec => rec.Key == "supportOther")[0].Value}</small></li>}
                </ul>
            </div>
            {ticket.TicketContent.filter(rec => rec.Key == "consentDocuments").length > 0 ?
            ticket.TicketContent.filter(rec => rec.Key == "consentDocuments")[0].Value == "true" ?
            <div data-testid="nkp-ticket-promote-consdoc">
                <small><b><i>Скан родословной предоставлялся при регистрации собаки</i></b></small>
            </div>
            : null
            : null }
            </>
            : null }
        </div>
    );    
}
