import gql from "graphql-tag";

const EXHIBITIONS_QUERY = gql`
    query Exhibitions {
        exhibitions(sort: "Start:desc"){
            id
            Type
            Start
            Organizer
            Rank
            City
            Pictures{
                id
            }
            Report {
                url
            }
        }
    }
`;

export default EXHIBITIONS_QUERY;