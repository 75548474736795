import { contentType, putContentObject } from '../strapi-access-services';

const ownMethod = (ticket, getDogF) => {
    return Promise.resolve()
    .then(() => { 
        return getDogF(ticket); 
    })
    .then((dog) => { 
        if (dog.error) throw dog.error;
        return putContentObject(contentType.DOG, dog.id, { owner : ticket.owner.id }, true) 
    })
    .then((result) => {
        if (!result.ok) throw result;
        return putContentObject(contentType.TICKET, ticket.id, { Status : "Approved" }, true)
    })
    .catch((error) => {
        return { error : "Other error", message : error };
    });
}

export default ownMethod;