import React, { useState, useEffect, useContext } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import useWindowDimensions from '../../shared/hooks/useWindowDimensions';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import { pageType } from '../services/strapi-access-services';
import { ticketType } from '../services/ticket-utils';
import { submitTicket } from '../services/tickets-api';
import { ProfileContext } from '../contexts/profile-context';
import ModalAccepted from './ModalAccepted';

const ModalRemoveCurator = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [okVisible, setOkVisible] = useState(false);
    const [dog, setDog] = useState(null);

    const { height, width } = useWindowDimensions();
    const [profile, setProfile] = useContext(ProfileContext);
    const pageT = pageType.MODAL_REMOVE_CURATOR;

    useEffect(
		() => {
            setDog(props.dog);
            setVisible(props.visible);
		},
		[props.visible],
	);

    const handleCloseOk = () => {
        setOkVisible(false);
        handleClose();
    }

    const handleClose = () => {
        setVisible(false);
     	props.callbackParent();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (dog.owner?.id == profile.id) {
            let data = {};
            data.chooseDog = dog.id;
            data.curatorId = props.currentCurator.id.toString();
            trackPromise(
                submitTicket(ticketType.UNMANAGE, data, profile)
                .then(() => {
                    setOkVisible(true);
                    setVisible(false);
                })    
            );
        }
    }

    return (
        <>
        { dog != null && props.currentCurator != null ?
        <>
            <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} 
                    onHide={handleClose} data-testid="nkp-modal-rmcur">
            <form onSubmit={handleSubmit}>
            <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="align-items-center">
                <Col lg={5} md={5} className="align-middle p-3">
                    <Image src={pageT.image} className="modal-image" />
                </Col>
                <Col lg={7} md={7} sm={12} className="p-5">
                    <h1>{pageT.title}</h1>
        
                    <span data-testid="nkp-remove-curator">Вы действительно хотите отозвать права куратора 
                        для собаки <b>{dog.Name}</b> у пользователя <b>{props.currentCurator.name}</b>?</span>
                </Col>
                </Row>
	        </Modal.Body>
	        <Modal.Footer>
                <Button variant="success" type="submit" data-testid="nkp-submit">
	                Отозвать
               </Button>
               <Button variant="danger" onClick={handleClose} data-testid="nkp-cancel">
	                Отмена
               </Button>
	        </Modal.Footer>
            </form>
	      </Modal>

        <ModalAccepted visible={okVisible} callbackParent={() => handleCloseOk()} />
        </>

          : ""}
          </>
	  );
}

export default ModalRemoveCurator;