import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import ModalSendMessage from '../shared/modals/ModalSendMessage';
import { pageType } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import KENNELS_QUERY from "../shared/queries/kennels";

const Kennels = (props) => {
	const [theKennel, setTheKennel] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);

    const handleSend = (kennel) => {
        setTheKennel(kennel);
        setModalVisible(true);
    }

		return(
			<>
            <Helmet>
                <title>НКП ВКК - Список питомников и заводчиков - членов НКП</title>
                <meta name="description" content="Список питомников и заводчиков - членов НКП Вельш Корги Кардиган." />
                <meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, члены, питомник, заводчик" />
                <link rel="canonical" href="https://welsh-corgi.club/#/kennels" />
            </Helmet>

    		<PageHeader pageType={pageType.KENNELS} />

            <Query query={KENNELS_QUERY}>
			{({ data: { members } }) => {
				return(
                    <Container className="mt-3 mb-3 p-3 bg-white">
                        <Row className="d-flex justify-content-center">
                        {members.map((kennel, index) => {
                            return (
                                <Col key={index} md={2} 
                                    className={kennel.Type == "Kennel" ? "nkp-kennel-plate-kennel p-2 m-2" : "nkp-kennel-plate-prefix p-2 m-2"}
                                    onClick={() => handleSend(kennel)} data-testid="nkp-kennel"
                                >
                                    <center>
                                        {kennel.Type == "Kennel" ? 
                                            <div>Питомник</div>
                                            :
                                            <div>Зав. приставка</div>
                                        }
                                        <div><strong>{kennel.MemberName.toUpperCase()}</strong></div>
                                        <div><em>{kennel.Name}</em></div>
                                        {kennel.City != null ? <div><em><small>({kennel.City})</small></em></div> : null}
                                    </center>
                                </Col>
                            )
                        })}
                    </Row>
                    </Container>
				);
			}}
    		</Query>

            <ModalSendMessage visible={modalVisible} 
                kennel={theKennel}
                callbackParent={() => setModalVisible(false)}
    		/>
			</>
		);
}

export default Kennels;