import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import PaginationPlus from '../shared/components/PaginationPlus';
import useWindowDimensions from '../shared/hooks/useWindowDimensions';
import Row from 'react-bootstrap/Row';
import { usePromiseTracker } from "react-promise-tracker";
import { contentType, pageType } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import DISQUALIFICATIONS_QUERY from "../shared/queries/disqualifications";

const Disqualifications = (props) => {
	const [page, setPage] = useState(0);

	const history = useHistory();
	const rowsPerPage = 20;
    const { height, width } = useWindowDimensions();

	useEffect(
		() => {
			let page;
			if (!props.match.params.page || props.match.params.page == null) { 
				page = 1;
			} else {
				page = props.match.params.page;
			}
			setPage(page);
		},
		[props.match.params],
	);

	const start = (page) => {
		let firstRecord = (page - 1) * rowsPerPage;
		firstRecord < 0 ? firstRecord = 0 : null;

		return firstRecord;
	}

	const decodeType = (type) => {
		let result;
		switch(type) {
			case "Breeder" :
				result = "Заводчик"
				break;
			case "Judge" :
				result = "Судья"
				break;
			case "C" :
				result = 2
				break;
			default: 
				result = 0
		}
		return result;
	}

	const goToPage = (page) => {
		history.push(contentType.DISQUALIFICATIONS + '/' + page);
	}

	return(
		<>
		<Helmet>
			<title>НКП ВКК - База данных дисквалификаций</title>
			<meta name="description" content="База данных дисквалифицированных судей, экспертов, заводчиков и собак." />
			<meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, дисквалификация" />
			<link rel="canonical" href="https://welsh-corgi.club/#/disqualifications" />
		</Helmet>

		<PageHeader pageType={pageType.DISQUALIFICATIONS} />

		<Query query={DISQUALIFICATIONS_QUERY} start={start(page)} limit={rowsPerPage}>
			{({ data: { disqualifications, disqualificationsConnection } }) => {
				let totalRows2 = disqualificationsConnection.aggregate.count;
				let totalPages2 = Math.ceil(totalRows2 / rowsPerPage);
				return(
					<Container className="py-3">
						<Row>
							{width > 768 ?
							<div className="nkp-box-shadow p-3 mt-3">
								<Table striped bordered hover>
								<thead>
									<tr>
									<th><small>Ф.И.О.</small></th>
									<th><small>Категория</small></th>
									<th><small>Приставка, питомник, город</small></th>
									<th><small>Срок действия</small></th>
									<th><small>Основание</small></th>
									</tr>
								</thead>
								<tbody>
									{disqualifications.map((disqual, index) => {
										return(
											<tr key={index}>
												<td data-testid="nkp-disqual-name"><small>{disqual.Name}</small></td>
												<td><small>{decodeType(disqual.Type)}</small></td>
												<td>
													<small>
														{disqual.Prefix != null && disqual.Prefix != "" ? 
															<div>
																{disqual.Prefix};
															</div> 
															: null}
														{disqual.City != null ? 
															<div>
																{disqual.City}
															</div>
															: null}
													</small>
												</td>
												<td><small>{disqual.Final}</small></td>
												<td><small>{disqual.Reason} от {disqual.Date}</small></td>
											</tr>		
										)
									})}
								</tbody>
								</Table>
								<div className="d-flex justify-content-center">
								<PaginationPlus 
									totalRows={totalRows2}
									page={page}
									totalPages={totalPages2}
									rowsPerPage={rowsPerPage}
									goToPage={goToPage}
								/>
								</div>
							</div>
							:
							<div className="nkp-box-shadow p-3 mt-3">
							<Table striped bordered hover>
							<thead>
								<tr>
								<th><small>Ф.И.О.</small></th>
								<th><small>Категория, приставка, питомник, город</small></th>
								<th><small>Срок действия, основание</small></th>
								</tr>
							</thead>
							<tbody>
								{disqualifications.map((disqual, index) => {
										return(
											<tr key={index}>
												<td data-testid="nkp-disqual-name"><small>{disqual.Name}</small></td>
												<td><small>
														<div>{decodeType(disqual.Type)}</div>
														{disqual.Prefix != null && disqual.Prefix != "" ? 
															<div>
																{disqual.Prefix};
															</div> 
															: null}
														{disqual.City != null ? 
															<div>
																{disqual.City}
															</div>
															: null}
													</small>
												</td>
												<td>
													<small>
														<div>{disqual.Final}</div>
														<div>{disqual.Reason} от {disqual.Date}</div>
													</small>
												</td>
											</tr>		
										)
									})}
							</tbody>
							</Table>
							<div className="d-flex justify-content-center">
							<PaginationPlus 
								totalRows={totalRows2}
								page={page}
								totalPages={totalPages2}
								rowsPerPage={rowsPerPage}
								goToPage={goToPage}
							/>
							</div>
							</div>
							}
						</Row>
					</Container>
				);
			}}
		</Query>					
		</>
	);
}

export default Disqualifications;