import gql from "graphql-tag";

const CURATOR_QUERY = gql`
    query Curator ($slug:ID!){
        user (id: $slug) {
            name
        }
    }
`;

export default CURATOR_QUERY;