import React from 'react';
import Container from 'react-bootstrap/Container';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import { pageType } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import PRIVACY_QUERY from "../shared/queries/privacy";

const Privacy = (props) => {
	return(
		<>
		<Helmet>
			<title>НКП ВКК - Политика в отношении обработки персональных данных</title>
			<meta name="description" content="Политика в отношении обработки персональных данных." />
			<meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, политика, персональные данные" />
			<link rel="canonical" href="https://welsh-corgi.club/#/privacy" />
		</Helmet>

		<PageHeader pageType={pageType.PRIVACY} />

		<Container className="mt-3 mb-3">
			<div className="bg-white border border-primary rounded p-3">
				<Query query={PRIVACY_QUERY}>
				{({ data: { privacy } }) => {
					return(
						<div data-testid="nkp-privacy">
							<ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]} children={privacy?.policy} />
						</div>
					);
				}}
				</Query>
			</div>
		</Container>
		</>
	);
}

export default Privacy;