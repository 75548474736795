// const zeroPad = (num, places) => String(num).padStart(places, '0')

// export const formatDate = (start) => {
//     let startDate = new Date(start);
//     let formattedDate = zeroPad(startDate.getDate(), 2) + "/" + 
//                         zeroPad(startDate.getMonth() + 1, 2) + "/" + 
//                         startDate.getFullYear();
//     return formattedDate;
// }

export const formatTicketTitle = (ticket) => {
    let result;
    let dogName;

    switch(ticket.Type) {
        case "Promote" :
            result = "Регистрация в НКП";
            break;
        case "AddDog" :
            dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0].Value;
            result = "Добавить собаку " + dogName;
            break;
        case "Manage" :
            if (ticket.dog != null) {
                dogName = ticket.dog.Name;
                result = "Назначение куратором собаки " + dogName;
            } else {
                result = "Назначение куратором собаки";
            }
            break;
        case "Unmanage" :
            if (ticket.dog != null) {
                dogName = ticket.dog.Name;
                result = "Отзыв прав куратора собаки " + dogName;
            } else {
                result = "Отзыв прав куратора собаки";
            }
            break;
        case "Genetics" :
            dogName = ticket.dog.Name;
            result = "Подтверждение результатов генетических исследований собаки " + dogName;
            break;
        case "AddDogAndGenetics" :
            dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0].Value;
            result = "Добавление собаки " + dogName + " и подтверждение результатов генетических исследований";
            break;    
        case "Dysplasia" :
            dogName = ticket.dog?.Name;
            result = "Подтверждение результатов исследований HD / ED ";
            if (ticket.dog) result += "собаки " + dogName;
            break;
        case "AddDogAndDysplasia" :
            dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0]?.Value;
            result = "Добавление собаки " + dogName + " и подтверждение результатов исследований HD / ED";
            break;
        case "Prolong" :
            result = "Повторная публикация объявления";
            break;
        case "Own" :
            dogName = ticket.dog.Name;
            result = "Смена текущего куратора собаки " + dogName;
            break;
        case "Puppies" :
            result = "Публикация объявления";
            break;
        case "OKDResults" :
            result = "Подтверждение результатов тестирования ОКД";
            break;
        case "AddDogAndOKDResults" :
            dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0].Value;
            result = "Добавление собаки " + dogName + " и подтверждение результатов тестирования ОКД";
            break;
        case "HerdingResults" :
            result = "Подтверждение результатов тестирования пастушьего инстинкта";
            break;
        case "AddDogAndHerdingResults" :
            dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0].Value;
            result = "Добавление собаки " + dogName + " и подтверждение результатов тестирования пастушьего инстинкта";
            break;
        case "AgilityResults" :
            result = "Подтверждение результатов соревнований по аджилити";
            break;
        default :
            ""
    }

    return result;
}

export const formatTicketStatus = (stringStatus) => {
    let result;

    switch(stringStatus) {
        case "New" :
            result = "На рассмотрении модератора";
            break;
        case "InProgress" :
            result = "В процессе обработки";
            break;
        case "Approved" :
            result = "Подтверждена модератором";
            break;
        case "Canceled" :
            result = "Отклонена модератором";
            break;
        default :
             ""
    }

    return result;
}

export const genetics = [
    { code: 'PRA', short: 'PRA', title: 'Прогрессирующая атрофия сетчатки' },
    { code: 'DM', short: 'DM', title: 'Дегенеративная миелопатия' },
    { code: 'Fluffy', short: 'FL', title: 'Флаффи' },
    { code: 'vWD', short: 'vWD', title: 'Болезнь Виллебранда' },
    { code: 'Pink', short: 'E', title: 'Аллель E' },
    { code: 'Deljut', short: 'D', title: 'Аллель D' },
    { code: 'Chocolate', short: 'B', title: 'Аллель B' }
];

export const buildTestResult = (variant) => {
    let result;
    switch (variant) {
        case "Clean" :
            result = "Чистый (-/-)"
            break;
        case "Carrier" :
            result = "Носитель (-/+)"
            break;
        case "Risk" :
            result = "В зоне риска (+/+)"
            break;
        default: 
            result = "Результат исследования"	
    }
    return result;
}

export const getRussianMonth = (monthNum) => {
    const monthNames = ["января", "февраля", "марта", "апреля", "мая", 
                    "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
    return monthNames[monthNum];
}

export const formatDate = (start) => {
    let startDate = new Date(start);
    let formattedDate = startDate.getDate() + " " + getRussianMonth(startDate.getMonth()) + 
                    " " + startDate.getFullYear() + " г.";
    return formattedDate;
}

export const formatRange = (start, end) => {
    let formattedStart = formatDate(start);
    let formattedEnd = formatDate(end);
    let result;
    result = formattedStart.split(" ")[0];
    if (formattedStart.split(" ")[1] == formattedEnd.split(" ")[1]) {
        result = result + " - " + formattedEnd.split(" ")[0] 
            + " " + formattedEnd.split(" ")[1] 
            + " " + formattedEnd.split(" ")[2]
            + " г.";
    } else if (formattedStart.split(" ")[2] == formattedEnd.split(" ")[2]) {
        result = result + " " + formattedStart.split(" ")[1] + " - " 
            + formattedEnd.split(" ")[0] 
            + " " + formattedEnd.split(" ")[1] 
            + " " + formattedEnd.split(" ")[2]
            + " г.";
    } else {
        result = result + " " + formattedStart.split(" ")[1] 
            + " " + formattedStart.split(" ")[2] + " г. - " 
            + formattedEnd.split(" ")[0] 
            + " " + formattedEnd.split(" ")[1] 
            + " " + formattedEnd.split(" ")[2]
            + " г.";
    }

    return result;
}

export const incrementDate = (start) => {
    let startDate = new Date(start);
    startDate.setDate(startDate.getDate() + 14);
    return startDate;
}
