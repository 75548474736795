import React, { useState, useEffect, useContext } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import { pageType } from '../services/strapi-access-services';
import { useForm } from '../hooks/useForm';
import InputText from '../components/InputText';
import InputDropdown from '../components/InputDropdown';
import InputFile from '../components/InputFile';
import { inputType } from '../services/input-utils';
import { ticketType } from '../services/ticket-utils';
import { submitTicket } from '../services/tickets-api';
import { ProfileContext } from '../contexts/profile-context';
import ModalAccepted from './ModalAccepted';
import Query from "../components/Query";

import DOGS_QUERY from "../queries/dogs";

const ModalAddAgilityResults = (props) => {
    const [visible, setVisible] = useState(false);
    const [okVisible, setOkVisible] = useState(false);
    const [currentDog, setCurrentDog] = useState(null);
	const { promiseInProgress } = usePromiseTracker();

    const { height, width } = useWindowDimensions();
    const pageT = pageType.MODAL_ADD_AGILITY_RESULTS;

    const { handleChange, handleChoose, handleSubmit, reset, errors, data } = useForm({
        validations :{
            dogName: {
                combinationRequired: {
                    isValid: (data, value) => (data.chooseDog == 0 && value) || data.chooseDog > 0,
                    message: 'Необходимо указать кличку',
                },
                custom: {
                    isValid: (value, data) => (data.chooseDog == 0 && RegExp('^[A-Za-zА-Яа-я\- ]*$').test(value)) || data.chooseDog > 0,
                    message: 'Допустимы только буквы и дефисы',
                },
            },
            dogYear: {
                combinationRequired: {
                    isValid: (data, value) => (data.chooseDog == 0 && value) || data.chooseDog > 0,
                    message: 'Необходимо указать год рождения',
                },
                custom: {
                    isValid: (value, data) => (data.chooseDog == 0 && parseInt(value, 10) > 1975 && parseInt(value, 10) < 2100) 
                                || data.chooseDog > 0,
                    message: 'Похоже, ошибка в годе рождения - должен быть между 1975 и 2100',
                },
            },
            chooseDog: {
                required: {
                    value: true,
                    message: 'Необходимо выбрать собаку из списка или указать данные новой собаки',
                },
            },
            files: {
                custom: {
                    isValid: (value, data) => value.length > 0,
                    message: 'Необходимо предоставить подтверждающие документы',
                },
            },
        },
        onSubmit: () => {
            trackPromise(
                submitTicket(ticketType.RESULTS_AGILITY, data, profile)
                .then(() => {
                    setOkVisible(true);
                    setVisible(false);
                })
            );
        },
    });

    const [profile, setProfile] = useContext(ProfileContext);

    useEffect(
		() => {
			setVisible(props.visible);
            if (props.dog != null) {
                handleChoose("chooseDog")(props.dog.id);
                setCurrentDog(props.dog);
            }
		},
		[props.visible],
	);

    const handleCloseOk = () => {
        setOkVisible(false);
        handleClose();
    }

    const handleClose = () => {
        setVisible(false);
     	props.callbackParent();
        reset();
    }

    return (
        <>
        <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} onHide={handleClose}
                data-testid="nkp-add-results">
        <form onSubmit={handleSubmit}>
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Query query={DOGS_QUERY} slug={profile?.id}>
            {({ data: { dogs } }) => {
            return (
            <Row className="align-items-center">
                <Col lg={5} md={5} className="align-middle">
                    <Image src={pageT.image} className="modal-image" />
                </Col>
                <Col lg={7} md={7} sm={12} className="px-4">
                    <h1>{pageT.title}</h1>

                    <div className="d-flex align-items-center" style={{ height: '100%' }}>
                        <div>
                        <InputDropdown current={currentDog} data={dogs} errors={errors} handleChoose={handleChoose} 
                            name="Name" type={inputType.DOG_CHOOSE} newItemName="Добавить собаку" newItem 
                            datatestid="nkp-choose-dog" />
                        {data?.chooseDog == 0 ?
                        <Row className="mb-3">
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} 
                                    type={inputType.DOG_NAME} datatestid="nkp-dog-name" />
                            </Col>
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} 
                                    type={inputType.DOG_YEAR} datatestid="nkp-dog-year" />
                            </Col>
                        </Row>
                        : "" }

                        <Row className="mb-3">
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} 
                                    type={inputType.AGILITY_A1} datatestid="nkp-ag-a1" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} 
                                    type={inputType.AGILITY_A2} datatestid="nkp-ag-a2" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} 
                                    type={inputType.AGILITY_A3} datatestid="nkp-ag-a3" />
                            </Col>
                        </Row>
                        <Form.File id="formcheck-api-regular">
                            <Form.File.Label>
                                <div className="py-3">
                                    <small>
                                        Пожалуйста загрузите скан или фото документа, подтверждающего результаты 
                                        тестирования. Документ не будет опубликован на сайте, но он нужен модератору
                                        для подтверждения предоставленной информации.
                                    </small>
                                </div>                
                                {data.chooseDog == 0 ? 
                                    <div className="py-3">
                                        <small>
                                            Пожалуйста загрузите скан или фото документа, подтверждающего ваши права
                                            на вновь добавляемую собаку. Документ не будет опубликован на сайте, но он нужен модератору
                                            для подтверждения предоставленной информации.
                                        </small>
                                    </div>                
                                : ""}
                            </Form.File.Label>
                            <InputFile data={data} errors={errors} handleChoose={handleChoose} type={inputType.FILE} 
                                inputId="file-input-group" inputTitle="Загрузите файл" datatestid="nkp-file" />
                        </Form.File>
                    </div>
                </div>
            </Col>
            </Row>
            )
            }}
            </Query>               
	        </Modal.Body>
	        <Modal.Footer>
                <Button variant="success" type="submit" 
                    data-testid="nkp-submit" disabled={promiseInProgress}>
	                Добавить
               </Button>
               <Button variant="danger" onClick={handleClose} 
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Отмена
               </Button>
	        </Modal.Footer>
            </form>
	      </Modal>

        <ModalAccepted visible={okVisible} callbackParent={() => handleCloseOk()} />
        </>
	  );
}

export default ModalAddAgilityResults;