import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

export const PageHeader = (props) => {
    const [error, setError] = useState(null);

    return (
        <>
            <Container>
                <Row>
                    <Col className="d-flex align-items-center">
                        <div className="d-flex justify-content-start p-3">
                            <center><h1 style={{ marginTop: 45 }} data-testid="nkp-page-title">
                                {props.altTitle ? props.altTitle : error ? "Нет такой страницы" : props.pageType?.title}
                            </h1></center>
                        </div>
                    </Col>
                    <Col md={6}>
                        <Image src={props.pageType?.image} data-testid="nkp-page-image" fluid />
                    </Col>
                </Row>
            </Container>
        </>
    )
}