import gql from "graphql-tag";

const CONTACTS_QUERY = gql`
query Contacts {
  contact {
    Phone
    Mail
    Facebook
    Address
    Telegram
    Vk
  }
}
`;

export default CONTACTS_QUERY;