import gql from "graphql-tag";

const SPORTS_QUERY = gql`
    query Sports ($slug: String!, $limit: Int, $start: Int ){
        sportsCategories(where: {Section:$slug}) {
            Title
            Description
            sports {
                id
                Title
                Url      
            }
            sports_events (start: $start, limit: $limit){
                id
                Start
                End
                Head
                Type
                Reglament
                City
                Club
                Classes
                Email
                Phone
            }
        }
    sportsEventsConnection(where : {sports_category :{Section: $slug}}) {
        aggregate {
            count
        }   
    }
}
`;

export default SPORTS_QUERY;