import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import ModalRegister from '../shared/modals/ModalRegister';
import { getMediaUrl } from '../shared/services/strapi-access-services';
import Helmet from 'react-helmet';
import NewsWidget from './NewsWidget';
import '../css/nkp.css';
import Query from "../shared/components/Query";

import HOME_QUERY from "../shared/queries/home";

const Home = (props) => {
    const [regVisible, setRegVisible] = useState(false);
	const history = useHistory();

	const goToList = () => {
		history.push('/kennels');
	}

	return(
		<>
		<Helmet>
			<title>НКП ВКК - Список членов НКП</title>
			<meta name="description" content="Список членов НКП Вельш Корги Кардиган." />
			<meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, члены, питомник, клуб, заводчик, владелец" />
			<link rel="canonical" href="https://welsh-corgi.club/#/" />
			<script type="application/ld+json">{`
				{
					"@context": "https://schema.org",
					"@type": "Organization",
					"name": "НКП Вельш Корги Кардиган",
					"alternateName": "НКП ВКК",
					"url": "https://welsh-corgi.club",
					"logo": "https://welsh-corgi.club/image/logo-old.png",	
				}		
			`}</script>
		</Helmet>

		<Query query={HOME_QUERY}>
			{({ data: { home } }) => {
				return(
				<>
				<div className="bg-white px-5 pb-3 mb-3">
					<div className="d-flex justify-content-center mb-3 mt-5">
						<h1 id="home-title" data-testid="home-title"><center>{home?.Title}</center></h1>
					</div>
					<Container>
					<Row>
						<Col md={5}>
							<Image src={getMediaUrl(home?.Image)} fluid />
						</Col>
						<Col id="home-about"  data-testid="home-about">
							<ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]} children={home?.About} />
							<div className="d-flex nkp-action-button" >
								<Button className="mt-3" data-testid="nkp-show-list"  
									variant="primary" size="lg" onClick={goToList}>
									<small>
										Список питомников и заводских приставок - членов НКП
									</small>
								</Button>
							</div>
							<div className="d-flex nkp-action-button" >
								<Button className="mt-3" data-testid="nkp-show-register" 
									variant="primary" size="lg" onClick={() => setRegVisible(true)}>
									<small>
										Вступить в НКП
									</small>
								</Button>
							</div>
						</Col>
					</Row>
					</Container>
				</div>

				<NewsWidget />

				<div className="bg-light p-3">
				<Container className="mt-3 mb-3">
					<div className="bg-white border border-primary rounded p-3">
						<Row className="mt-4">
							<Col>
								<div>
									<Image src={getMediaUrl(home?.Chairman)} fluid />
									<div className="d-flex justify-content-center">
										<center><em>
											<small>{home?.ChairmanPosition}</small>
										</em></center>
									</div>
									<div className="d-flex justify-content-center nkp-board-name" data-testid="chairman">
										{home?.ChairmanName}
									</div>
								</div>
							</Col>
							{
								home?.Board.map((member, index) => {
									return (
										<Col key={index}>
										<div>
											<Image src={getMediaUrl(member?.Pic)} fluid />
											<div className="d-flex justify-content-center">
												<center><em>
												<small>{member?.Position}</small>
												</em></center>
											</div>
											<div className="d-flex justify-content-center nkp-board-name" data-testid="board-member">
												{member?.Name}
											</div>
										</div>
										</Col>
									)
								})
							}
						</Row>
					</div>
				</Container>
				</div>
				</>
				);
			}}
		</Query>

		<ModalRegister visible={regVisible}
			callbackParent={() => setRegVisible(false)}
		/>
		</>
	);
}

export default Home;