import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import PaginationPlus from '../shared/components/PaginationPlus';
import ModalAddAgilityResults from '../shared/modals/ModalAddAgilityResults';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import useWindowDimensions from '../shared/hooks/useWindowDimensions';
import ModalRegister from '../shared/modals/ModalRegister';
import { ProfileContext } from '../shared/contexts/profile-context';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import AGILITY_QUERY from "../shared/queries/agility";

const AgilityTable = (props) => {
	const [filter, setFilter] = useState("");
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [registerVisible, setRegisterVisible] = useState(false);

	const rowsPerPage = 20;
    const { height, width } = useWindowDimensions();
	const [profile, setProfile] = useContext(ProfileContext);

	useEffect(
		() => {
			if (page == 0) { 
				setPage(1);
			}
		},
		[profile],
	);

	const start = (page) => {
		let firstRecord = (page - 1) * rowsPerPage;
		firstRecord < 0 ? firstRecord = 0 : null;

		return firstRecord;
	}

	const goToPage = (page) => {
		setPage(page)
	}

	const handleKeyDown = (e) => {
        if (e.key == 'Enter') { setFilter(search) };
    }

	return(
		<div className="d-flex justify-content-center">
			<Helmet>
                <title>НКП ВКК - База данных кардиганов по аджилити</title>
                <meta name="description" content="База данных кардиганов по аджилити." />
                <meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, аджилити" />
            </Helmet>

			<Container>
				<h4>База данных кардиганов по аджилити</h4>

				<Row>
					<Col>
						<InputGroup className="nkp-search-box">
							<InputGroup.Prepend>
								<InputGroup.Text>
									<FontAwesomeIcon icon={faSearch} />
								</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								type="text"
								placeholder="Кличка собаки"
								onChange={(e) => setSearch(e.target.value)}
								onKeyDown={handleKeyDown}
							/>
						</InputGroup>
					</Col>
					<Col className="d-flex justify-content-end">
						<div className="d-flex align-items-center">
						{profile == null ?
							<>
							<Row className="d-flex justify-content-end" style={{ marginLeft: 0, marginRight: 0 }}>
							<div style={{ textAlign: 'right', marginBottom: '1em' }}>
								<span><small><i>Зарегистрированные пользователи сайта могут добавлять информацию по собакам.</i></small></span>
							</div>
							<div className="d-flex justify-content-end">
								<Button as="input" 
										size="md"
										data-testid="nkp-button-register"
										onClick={() => setRegisterVisible(true)} 
										type="submit" 
										value="Зарегистрироваться на сайте" />
							</div>
							</Row>
							</>
							:
							<Button as="input" 
							size="md"
							data-testid="nkp-button-add"
							onClick={() => setModalVisible(true)} 
							type="submit" 
							disabled={profile == null}
							value="Добавить результаты" />
						}
						</div>
					</Col>
				</Row>
				<Query query={AGILITY_QUERY} slug={filter || ""} start={start(page)} limit={rowsPerPage}>
				{({ data: { dogs, dogsConnection } }) => {
					let totalRows2 = dogsConnection.aggregate.count;
					let totalPages2 = Math.ceil(totalRows2 / rowsPerPage);
					return(
					<>
					{width > 768 ?
					<div className="nkp-box-shadow p-3 mt-3 mb-5">
						<Table striped bordered hover data-testid="nkp-results-table">
						<thead>
							<tr>
							<th><small>Кличка</small></th>
							<th><small>Год рождения</small></th>
							<th><small>A1</small></th>
							<th><small>A2</small></th>
							<th><small>A3</small></th>
							</tr>
						</thead>
						<tbody>
							{dogs.map((dog, index) => {
								if (dog.sports_results_agility != null) {
									let dogName = dog.Name;
									if(profile != null) {
										if (dog.curators?.filter(rec => rec.email == profile.email).length > 0
											|| dog.owner?.email == profile.email
											|| profile.role.name == "Moderator") {
											dogName = <Link to={"/dog/" + dog.id}>{dog.Name}</Link>
										}
									}
									return(
										<tr key={index}>
											<td><small>{dogName}</small></td>
											<td><small>{dog.Year}</small></td>
											<td data-testid="nkp-a1"><small>{dog.sports_results_agility.A1}</small></td>
											<td><small>{dog.sports_results_agility.A2}</small></td>
											<td><small>{dog.sports_results_agility.A3}</small></td>
										</tr>		
									)
								}
							})}
						</tbody>
						</Table>
						<div className="d-flex justify-content-center">
							<PaginationPlus 
								totalRows={totalRows2}
								page={page}
								totalPages={totalPages2}
								rowsPerPage={rowsPerPage}
								goToPage={goToPage}

							/>
						</div>
					</div>
					:
					<div className="nkp-box-shadow p-3 mt-3 mb-4">
						<Table striped bordered hover data-testid="nkp-results-table">
						<thead>
							<tr>
							<th><small>Кличка</small></th>
							<th><small>Год рождения</small></th>
							<th><small>Результат</small></th>
							</tr>
						</thead>
						<tbody>
						{dogs.map((dog, index) => {
								if (dog.sports_results_agility != null) {
									let dogName = dog.Name;
									if(profile != null) {
										if (dog.curators?.filter(rec => rec.email == profile.email).length > 0
											|| dog.owner?.email == profile.email
											|| profile.role.name == "Moderator") {
											dogName = <Link to={"/dog/" + dog.id}>{dog.Name}</Link>
										}
									}
									return(
										<tr key={index}>
											<td><small>{dogName}</small></td>
											<td><small>{dog.Year}</small></td>
											<td data-testid="nkp-a1">
												<small>
													<div>
														A1: {dog.sports_results_agility.A1}
													</div>
													<div>
														A2: {dog.sports_results_agility.A2}
													</div>
													<div>
														A3: {dog.sports_results_agility.A2}
													</div>
												</small>
											</td>
										</tr>		
									)
								}
							})}
						</tbody>
						</Table>
						<div className="d-flex justify-content-center">
							<PaginationPlus 
								totalRows={totalRows2}
								page={page}
								totalPages={totalPages2}
								rowsPerPage={rowsPerPage}
								goToPage={goToPage}
							/>
						</div>
					</div>
				}
				</>)
				}}
				</Query>

	<ModalAddAgilityResults visible={modalVisible} 
		callbackParent={() => setModalVisible(false)}
	/>

	<ModalRegister visible={registerVisible} 
		callbackParent={() => setRegisterVisible(false)}
	/>
	</Container>
	</div>
	);
}

export default AgilityTable;