import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faPaw } from '@fortawesome/free-solid-svg-icons';
import { contentType, getDocumentUrl, pageType, transformImageUri,
	darkBackground } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import DOCUMENTS_QUERY from "../shared/queries/documents";
import ARTICLE_SINGLE_QUERY from "../shared/queries/article-single";
import BREEDING_SINGLE_QUERY from "../shared/queries/breeding-single";
import DOCUMENT_SINGLE_QUERY from "../shared/queries/document-single";
import EXPO_SINGLE_QUERY from "../shared/queries/expo-single";

const Documents = (props) => {
	const [documentId, setDocumentId] = useState(null);
	const [type, setType] = useState(null);
	
	useEffect(
		() => {
			let id;
			if (!props.match.params.id) { 
				id = null;
			} else {
				id = props.match.params.id;
			}
			setDocumentId(id);

			let newtype = {};

			switch (props.type) {
				case "articles" :
					newtype = { type : pageType.ARTICLE, content : contentType.ARTICLE, typeName: "article",
								meta : "Статьи о породе вельш корги кардиган", query : ARTICLE_SINGLE_QUERY };
					break;
				case "documents" : 
					newtype = { type : pageType.DOCUMENT, content : contentType.DOCUMENT, typeName: "document", 
								meta : "Документы РКФ и НКП", query : DOCUMENT_SINGLE_QUERY };
					break;
				case "expos" : 
					newtype = { type : pageType.EXPO, content : contentType.EXPO, typeName: "expo", 
								meta : "Выставочная деятельность НКП", query : EXPO_SINGLE_QUERY };
					break;
				case "breedings" : 
					newtype = { type : pageType.BREEDING, content : contentType.BREEDING, typeName: "breeding", 
								meta : "Племенное разведение вельш корги кардиган", query : BREEDING_SINGLE_QUERY };
					break;
			}
			setType(newtype);
		},
		[props.type, props.match.params],
	);

	return(
		<>
		{documentId == null ?
			<>
			<Helmet>
			<title>НКП ВКК - документы НКП</title>
				<meta name="description" content={type?.meta} />
				<meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, документы, статьи, выставки, разведение" />
				<link rel="canonical" href="https://welsh-corgi.club/#/documents" />
			</Helmet>

			{type &&
			<Query query={DOCUMENTS_QUERY}>
			{({ data: { articles, documents, breedings, expos } }) => {
				let entities = [];
				switch(type.type) {
					case pageType.ARTICLE :
						entities = [].concat(articles);
						break;
					case pageType.DOCUMENT :
						entities = [].concat(documents);
						break;
					case pageType.BREEDING :
						entities = [].concat(breedings);
						break;
					case pageType.EXPO :
						entities = [].concat(expos);
						break;
				}
				return(
					<>
					<PageHeader pageType={type.type} />

					<div className="mx-0 px-0">
						<div style={{ backgroundImage:  darkBackground(),
									backgroundPosition: "center",
									backgroundSize: "cover",
									width: "100wv" }}>
							<div className="nkp-dark-box-1" style={{ width: "100wv", minHeight: "500px" }}>
								<Container className="py-3">
									<Row>
									{entities.map((document, index) => {
										if (document.Url != null) {
											return (
												<Col key={index} lg={6} className="align-items-center">	
												<a href={document.Url} target="_blank">
												<div className="nkp-document-plate">
													<Row>
														<Col xs={2}>
															<FontAwesomeIcon icon={faGlobe} size="3x" />
															</Col>
														<Col data-testid="nkp-document-link">
															{document.Title}
														</Col>
													</Row>
												</div>
												</a>
												</Col>		
											)
										} else if (document.Link != null) {
											return (
												<Col key={index} lg={6} className="align-items-center">	
												<Link to={document.Link}>
												<div className="nkp-document-plate">
													<Row>
														<Col xs={2}>
															<FontAwesomeIcon icon={faPaw} size="3x" />
														</Col>
														<Col data-testid="nkp-document-link">
															{document.Title}
														</Col>
													</Row>
												</div>
												</Link>
												</Col>
											)
										} else {
											return (
												<Col key={index} lg={6} className="align-items-center">	
												<Link to={getDocumentUrl(type?.content) + "/" + document.id} >
												<div className="nkp-document-plate">
													<Row>
														<Col xs={2}>
															<FontAwesomeIcon icon={faPaw} size="3x" />
														</Col>
														<Col data-testid="nkp-document-link">
															{document.Title}
														</Col>
													</Row>
												</div>
												</Link>
												</Col>
											)
										}
									})}
									</Row>
								</Container>
							</div>
						</div>
					</div>
					</>
				);
			}}
			</Query>}
			</>
			:
			<>
			{type &&
			<Query query={type.query} slug={documentId}>
			{({ data: { article, breeding, document, expo } }) => {
				let entity = {};
				switch(type.type) {
					case pageType.ARTICLE :
						entity = {...article};
						break;
					case pageType.DOCUMENT :
						entity = {...document};
						break;
					case pageType.BREEDING :
						entity = {...breeding};
						break;
					case pageType.EXPO :
						entity = {...expo};
						break;
				}
				return(
					<>
					<PageHeader pageType={type?.type} altTitle={entity?.Title} />

					<Container className="mt-3 mb-3">
						<div className="p-3 my-3" data-testid="nkp-page-content">
							<ReactMarkdown 
								rehypePlugins={[rehypeRaw]} transformImageUri={transformImageUri}
								children={entity?.Content} />
						</div>
					</Container>
					</>
				);
			}}
			</Query>}
			</>
		}
		</>
	);
}

export default Documents;