import React, { useState, useEffect, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import useWindowDimensions from '../../shared/hooks/useWindowDimensions';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { usePromiseTracker } from "react-promise-tracker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { pageType } from '../services/strapi-access-services';
import { ticketType } from '../services/ticket-utils';
import { submitTicket } from '../services/tickets-api';
import { ProfileContext } from '../contexts/profile-context';
import ModalAccepted from './ModalAccepted';
import { useQuery } from "@apollo/client";
import USERS_QUERY from '../queries/users';

const ModalAddCurator = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [okVisible, setOkVisible] = useState(false);
    const [dog, setDog] = useState(null);
    const [warn, setWarn] = useState(false);
    const [singleSelections, setSingleSelections] = useState([]);
    const [users, setUsers] = useState([]);
    const [userOps, setUserOps] = useState([]);

	const { promiseInProgress } = usePromiseTracker();
    const { height, width } = useWindowDimensions();
    const [profile, setProfile] = useContext(ProfileContext);
    const pageT = pageType.MODAL_ADD_CURATOR;

    const { data, loading, error } = useQuery(USERS_QUERY, { onCompleted: data => setUsers(data.users) });
    

    useEffect(
		() => {
            setDog(props.dog);
            setVisible(props.visible);
		},
		[props.visible, props.dog],
	);

    useEffect(
        () => {
            let newOps = [];
            users.map((user) => {
                if (dog != null) {
                    if (dog.curators.filter(rec => rec.email == user.email).length == 0 &&
                        dog.owner?.email != user.email) {
                        newOps.push(user.name);
                    }
                }
            })
            setUserOps(newOps);
        }, [users]
    );

    const handleCloseOk = () => {
        setOkVisible(false);
        handleClose();
    }

    const handleClose = () => {
        setVisible(false);
        setWarn(false);
     	props.callbackParent();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let allow = true;
        if (userOps.filter(rec => rec == singleSelections).length < 1) {
            setWarn(true);
            allow = false;
        } else {
            setWarn(false);
        }

        if (allow) {
            let data = {};
            data.chooseDog = dog.id;
            data.curatorId = users.filter(rec => rec.name == singleSelections)[0].id.toString();
            submitTicket(ticketType.MANAGE, data, profile)
            .then(() => {
                setOkVisible(true);
                setVisible(false);
            });
        }
    }

    return (
        <>
        { dog != null ?
            <>
            <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} 
                data-testid="nkp-add-curator" onHide={handleClose}>
            <form onSubmit={handleSubmit}>
            <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error != null ?
                <h4 style={{ color : "red" }}>Что-то пошло не так. Мы обязательно разберемся и исправим.</h4>
                :
                <>
                <Row className="align-items-center">
                <Col lg={5} md={5} className="align-middle p-3">
                    <Image src={pageT.image} className="modal-image" />
                </Col>
                <Col lg={7} md={7} sm={12} className="p-5">
                    <h1>{pageT.title}</h1>
                    <InputGroup className={warn ? "nkp-search-box-invalid" : "nkp-search-box"} data-testid="nkp-group">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faUserAlt} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            onChange={setSingleSelections}
                            options={userOps}
                            placeholder="Начните вводить имя"
                            selected={singleSelections}
                            isInvalid={warn}
                        />
                </InputGroup>
                </Col>
                </Row>
                </>}
	        </Modal.Body>
	        <Modal.Footer>
                <Button variant="success" type="submit" 
                    data-testid="nkp-submit" disabled={promiseInProgress}>
	                Отправить запрос
               </Button>
               <Button variant="danger" onClick={handleClose} 
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Отмена
               </Button>
	        </Modal.Footer>
            </form>
	      </Modal>

          <ModalAccepted visible={okVisible} callbackParent={() => handleCloseOk()} />
          </>
          : ""}
          </>
	  );
}

export default ModalAddCurator;