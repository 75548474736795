import gql from "graphql-tag";

const TITLES_QUERY = gql`
    query Titles ($slug: String!, $limit: Int, $start: Int ){
        dogs(sort:"Name:asc", start: $start, limit: $limit, where : {Titles_null : false, Name_contains :$slug}){
            id
            Name
            Year
            Titles
            Pic {
                url
            }
            owner {
                name
            }
        },
        dogsConnection(where : {Titles_null : false, Name_contains :$slug}) {
            aggregate {
                count
            }
        }
    }
`;

export default TITLES_QUERY;