import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import PaginationPlus from '../shared/components/PaginationPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import PhotoModal from './ExhibitionsPhoto';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import GalleryRow from './ExhibitionsGalleryRow';
import { formatDate } from '../shared/services/utils'; 
import { pageType, getMediaUrl } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import EXHIBITIONS_QUERY from "../shared/queries/exhibitions";
import EXHIBITION_SINGLE_QUERY from "../shared/queries/exhibition-single";

const Exhibitions = (props) => {
    const [expoId, setExpoId] = useState(null);
	const [currentPic, setCurrentPic] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
	const [page, setPage] = useState(1);
    const history = useHistory();

	const rowsPerPage = 20;

	useEffect(
		() => {
            if (props.match.params.id != null) {
                setExpoId(props.match.params.id);
            } else {
                setExpoId(null);
            }
		},
		[props.match.params.id],
	);

    const makeGrid = (photos, numCols) => {
        let numRows = Math.ceil(photos.length / numCols);
        let result = [];
        for (var i = 0; i < numRows; i++) {
            result.push(photos.slice(i * numCols, i * numCols + numCols));
        }
        return result;
    }

    const goToPage = (page) => {
        setPage(page);
    }

    const handleClick = (e) => {
		setCurrentPic(e.target.id);
		setModalVisible(true);
	}

    const onConfirmClose = (newState) => {
		setModalVisible(newState);
	}

    const showReport = (expo) => {
        if (expo.Pictures.length > 0) {
            history.push("/exhibitions/" + expo.id);
        } else {
            if (expo.Report != null) {
                window.open(getMediaUrl(expo.Report), '_blank');
            }
        }
    }

    return(
        <>
            {expoId == null ?
                <>
                    <Helmet>
                        <title>НКП ВКК - Отчеты о проведении монопородных выставок</title>
                        <meta name="description" content="Отчеты о проведении монопородных выставок." />
                        <meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, монопородные выставки" />
                        <link rel="canonical" href="https://welsh-corgi.club/#/exhitibions" />
                    </Helmet>

                    <PageHeader pageType={pageType.EXHIBITIONS} />

                    <Query query={EXHIBITIONS_QUERY}>
                    {({ data: { exhibitions } }) => {
                        return(
                            <Container className="py-3">
                                <Row>
                                <Container className="mt-3 mb-3 p-3 bg-white">
                                    {exhibitions.length > 0 ?
                                        <Row lg={3} noGutters>
                                            {exhibitions.map((expo, index) => {
                                                return(
                                                <Col key={index} onClick={() => showReport(expo)} data-testid="nkp-expo">
                                                    <div className={expo.Type == "Monobreed" ? 
                                                        "nkp-kennel-plate-prefix p-2 m-3" 
                                                        :
                                                        "nkp-kennel-plate-kennel p-2 m-3"}>
                                                    <div className="justify-content-center" style={{ marginTop: "-10px" }}>
                                                        <div className="d-flex align-items-start">
                                                            <span className="nkp-date-capital mr-3">{formatDate(expo.Start).split(" ")[0]}</span> 
                                                            <span className="nkp-date-rest">
                                                                {formatDate(expo.Start).split(" ")[1] + " " + formatDate(expo.Start).split(" ")[2] + " г."}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "70%", marginLeft: "30%", marginTop: "-40px" }}>
                                                        <div>
                                                            <center>
                                                                <small>
                                                                    <b>
                                                                        {expo.Type == "Monobreed" ? 
                                                                        "Монопородная выставка Вельш Корги Кардиган"
                                                                        :
                                                                        <span>Выставка Speciality<br /><br /></span>
                                                                        }
                                                                    </b>
                                                                </small>
                                                            </center>
                                                        </div>
                                                        <div>
                                                            <center>
                                                                <small>ранг {expo.Rank}</small>
                                                            </center>
                                                        </div>
                                                        <div>
                                                            <center>
                                                                <small><i>{expo.City}</i></small>
                                                            </center>
                                                        </div>
                                                        <div>
                                                            <center>
                                                                <small><i>организатор {expo.Organizer}</i></small>
                                                            </center>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </Col>
                                                );
                                            })}
                                        </Row>
                                    : null }
                                </Container>
                                </Row>
                            </Container>
                        );
                    }}
                    </Query>
                </>
            :
                <>
                    <Query query={EXHIBITION_SINGLE_QUERY} slug={expoId}>
                    {({ data: { exhibition } }) => {
                        let totalRows = exhibition.Pictures.length;
                        let totalPages = Math.ceil(totalRows / rowsPerPage);

                        let frame = [];
                        let minId = (page - 1) * 20 + 1;
                        let maxId = page * 20;
                        if (maxId > exhibition.Pictures.length) maxId = exhibition.Pictures.length;
                        for (var i = minId; i <= maxId; i++) {
                            frame.push(exhibition.Pictures[i - 1]);
                        }
                                        
                        return(
                        <>
                            <PageHeader pageType={pageType.EXHIBITIONS} altTitle={
                                exhibition.Type == "Monobreed" ? 
                                <span>Монопородная выставка Вельш Корги Кардиган
                                <br />
                                ранг {exhibition.Rank}, {formatDate(exhibition.Start)},<br />
                                {exhibition.City}, {exhibition.Organizer}</span>
                            :
                                <span>Выставка Speciality
                                <br />
                                ранг {exhibition.Rank}, {formatDate(exhibition.Start)},<br />
                                {exhibition.City}, {exhibition.Organizer}</span>
                            } />

                            <Container className="mt-3 mb-3 p-3 bg-white">
                            <div className="nkp-box-shadow p-3 mt-3">

                                {exhibition.Report != null &&
                                    <h3>
                                        <a href={getMediaUrl(exhibition.Report)} target="_blank">
                                            <FontAwesomeIcon icon={faFilePdf} className="mr-2" /> Отчет о выставке
                                        </a>
                                    </h3>}

                                {frame.length > 0 &&
                                    <div className="p-3 mt-3">
                                        <Container>
                                        {makeGrid(frame, 5).map((row, index) => {
                                            return(
                                                <GalleryRow row={row} key={index} handleShowPhoto={handleClick}/>
                                            );
                                        })} 
                                        </Container>
                                    </div>}

                                <div className="d-flex justify-content-center">
                                <PaginationPlus 
                                    totalRows={totalRows}
                                    page={page}
                                    totalPages={totalPages}
                                    rowsPerPage={rowsPerPage}
                                    goToPage={goToPage}
                                />
                                </div>

                                <PhotoModal visible={modalVisible}
                                            currentPic={currentPic}
                                            expo={exhibition} 
                                            callbackParent={onConfirmClose}
                                />
                            </div>
                            </Container>
                        </>
    		    		);
	        		}}
            		</Query>
                </>
            }
        </>
    );
}

export default Exhibitions;