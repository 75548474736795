import React, { useState, useEffect, useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import ModalRegister from '../modals/ModalRegister';
import ModalLogin from '../modals/ModalLogin';
import ModalResetPassword from '../modals/ModalResetPassword';
import Avatar from 'react-avatar';
import MenuItem from './MenuItem';
import { ProfileContext } from '../contexts/profile-context';
import { contentType, getContentObject } from '../services/strapi-access-services';
import Query from "./Query";

import HEADERS_QUERY from "../queries/headers";

const Header = (props) => {
    const [visible, setVisible] = useState(false);
    const [registerVisible, setRegisterVisible] = useState(false);
    const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
    const [user, setUser] = useState(localStorage.getItem('user'));
    const [userName, setUserName] = useState("");
    const [token, setToken] = useState(localStorage.getItem('token'));

	const [profile, setProfile] = useContext(ProfileContext);

    useEffect(
        () => {
            refreshUser();
        }, []
    );

    const refreshUser = () => {
        let theUser = localStorage.getItem('user');
        setUser(theUser);
        setToken(localStorage.getItem('token'));        
        if (theUser == null)
        {
            props.callbackParent(false);
        } else {
            props.callbackParent(true);
        }
        if (theUser != null && localStorage.getItem('token') != null) {
            getContentObject(contentType.USER_DETAILS)
            .then((obj) => {
                setProfile(obj);
                props.handleChangeProfile(obj);
            });
        } else {
            props.handleChangeProfile(null);
        }
    }

    useEffect(
        () => {
            setUserName(profile?.name);
        }, [profile]
    );

    const handleLogoff = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setUser(null);
        setToken(null);        
        setProfile(null);
        props.callbackParent(false);
        props.handleChangeProfile(null);
    }

    let logo = "image/logo-old.png";

    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="primary" variant="light">
                <Navbar.Brand as={Link} to="/">
                    <img
                        alt=""
                        data-testid="nkp-logo"
                        src={logo}
                        height="31"
                        className="d-inline-block align-top"
                    />
                    <span className="ml-3 text-white">НКП ВЕЛЬШ КОРГИ КАРДИГАН</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                <Query query={HEADERS_QUERY} slug={null}>
        			{({ data: { articles, documents, breedings, expos } }) => {
                        return (
                            <>
                            <MenuItem title="О ПОРОДЕ" items={articles} contentType={contentType.ARTICLE} />
                            <MenuItem title="ДОКУМЕНТЫ" items={documents} contentType={contentType.DOCUMENT} />
                            <MenuItem title="РАЗВЕДЕНИЕ" items={breedings} contentType={contentType.BREEDING} />
                            <MenuItem title="ВЫСТАВКИ" items={expos} contentType={contentType.EXPO} />
                            <Nav.Link as={Link} to="/sports" className="nkp-menu-item-text text-white nkp-menu-item">РАБОТА И СПОРТ</Nav.Link>
                            <Nav.Link as={Link} to="/puppies" className="nkp-menu-item-text text-white nkp-menu-item">ЩЕНКИ</Nav.Link>        
                            </>
                        );
                        }}
                </Query>
                </Nav>
                    {profile === undefined || profile == null || profile == "" ? 
                    <NavDropdown alignRight data-testid="nkp-profile" title={
                        <>
                            <Image src="/image/profile.svg" className="mr-2" width={20} height={20} fluid />
                            <span className="text-white nkp-menu-item nkp-menu-item-text"><small>Гость</small></span>
                        </>
                    } id="collasible-nav-dropdown">
                        <NavDropdown.Item bg="primary" data-testid="nkp-submenu" className="nkp-submenu-item" onClick={() => setVisible(true)}>
                            <span className="nkp-submenu-item-text">Войти</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item bg="primary" data-testid="nkp-submenu" className="nkp-submenu-item" onClick={() => setRegisterVisible(true)} >
                            <span className="nkp-submenu-item-text">Зарегистрироваться</span>
                        </NavDropdown.Item>
                    </NavDropdown>
                    :
                    <NavDropdown data-testid="nkp-profile" className="nkp-user-profile" title={
                        <>
                            {profile.name ? <Avatar name={profile.name || ""} className="mr-2" size={30} round={true} /> : null}
                            <span className="text-white nkp-menu-item nkp-menu-item-text"><small>{user}</small></span>
                        </>
                    } id="collasible-nav-dropdown">
                        <NavDropdown.Item bg="primary" data-testid="nkp-submenu" className="nkp-submenu-item" as={Link} to={contentType.USER_PROFILE}>
                            <span className="nkp-submenu-item-text">Личный кабинет</span>
                        </NavDropdown.Item>
                        {profile?.role?.name == "Moderator" ?
                            <NavDropdown.Item bg="primary" data-testid="nkp-submenu" className="nkp-submenu-item" as={Link} to={contentType.MODERATOR_PANEL}>
                                <span className="nkp-submenu-item-text">Панель модератора</span>
                            </NavDropdown.Item>
                        : ""}
                        <NavDropdown.Item bg="primary" data-testid="nkp-submenu" className="nkp-submenu-item" onClick={() => handleLogoff()}>
                            <span className="nkp-submenu-item-text">Выйти</span>
                        </NavDropdown.Item>
                    </NavDropdown>
                    }
                </Navbar.Collapse>
            </Navbar>

            <ModalRegister visible={registerVisible} 
                callbackParent={() => setRegisterVisible(false)}
                callbackResetPassword={() => {setVisible(false); setResetPasswordVisible(true)}}
    		/>

            <ModalLogin visible={visible} 
                callbackParent={() => setVisible(false)}
                callbackResetPassword={() => {setVisible(false); setResetPasswordVisible(true)}}
                refreshUser={refreshUser}
    		/>

            <ModalResetPassword visible={resetPasswordVisible} 
                callbackParent={() => setResetPasswordVisible(false)}
    		/>

        </>
    );
}

export default Header;