import { useEffect, useState } from 'react';

export const useForm = (options) => {
    const [data, setData] = useState(options?.initialValues || {});
    const [errors, setErrors] = useState({});

    const handleChange = (key) => (e) => {
        setData({
            ...data,
            [key]: e.target.value,
        });
    };

    const handleChoose = (key) => (e) => {
        setData({
            ...data,
            [key]: e,
        });
    };

    const handleCheck = (key) => (e) => {
        setData({
            ...data,
            [key]: e.target.checked,
        });
    };

    const reset = () => {
        setData(options?.initialValues || {});
        setErrors({});    
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validations = options?.validations;

        if (validations) {
            let valid = true;
            const newErrors = {};

            for (const key in validations) {
                const value = data[key];
                const validation = validations[key];

                if (validation?.required?.value && !value) {
                    valid = false;
                    newErrors[key] = validation?.required?.message;
                }

                const pattern = validation?.pattern;
                if (pattern?.value && !RegExp(pattern.value).test(value)) {
                  valid = false;
                  newErrors[key] = pattern.message;
                }

                const custom = validation?.custom;
                if (custom?.isValid && !custom.isValid(value, data)) {
                  valid = false;
                  newErrors[key] = custom.message;
                }

                const combinationRequired = validation?.combinationRequired;
                if (combinationRequired?.isValid && !combinationRequired.isValid(data, value)) {
                    valid = false;
                    newErrors[key] = combinationRequired.message;
                }
            }

            if (!valid) {
                setErrors(newErrors);
                return;
            }
        }

        setErrors({});

        if (options?.onSubmit) {
            options.onSubmit();
        }
    };

    return {
        data,
        handleChange,
        handleChoose,
        handleCheck,
        handleSubmit,
        reset,
        errors,
    };
}