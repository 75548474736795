import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import useWindowDimensions from '../hooks/useWindowDimensions';
import { inputType } from '../services/input-utils';
import InputText from '../components/InputText';
import { useForm } from '../hooks/useForm';
import { contentType, pageType, postContentObject } from '../services/strapi-access-services';

const ModalLogin = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [error, setError] = useState(null);
    
	const { promiseInProgress } = usePromiseTracker();
    const { height, width } = useWindowDimensions();
    const pageT = pageType.LOGIN;

    const emailPattern = "^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$";

    const { handleChange, handleSubmit, reset, errors, data } = useForm({
        validations :{
            username: {
                required: {
                    value: true,
                    message: 'Необходимо указать ваше имя пользователя (совпадает с e-mail)',
                },
                custom: {
                    isValid: value => RegExp(emailPattern).test(value),
                    message: 'Вероятно, вы ошиблись. В качестве имени пользователя необходимо ввести e-mail.',
                },
            },
            password1: {
                required: {
                    value: true,
                    message: 'Необходимо ввести пароль',
                },
            },
        },
        onSubmit: () => {
            login();
        },
    });

    useEffect(
		() => {
			setVisible(props.visible);
		},
		[props],
	);

    const login = () => {
        let account = {
            "identifier": data.username.trim(),
            "password": data.password1
        }
        trackPromise(
            postContentObject(contentType.LOGON, account, false)
            .then((result) => {
                if (!result.ok) throw result;
                return result.json();
            })
            .then((myjson) => {
                localStorage.setItem('user', myjson?.user.email);
                localStorage.setItem('token', myjson?.jwt);
                return myjson;    
            })
            .then(() => {
                handleClose();
            })
            .catch((error) => {
                error.json().then((body) => {
                    if (body.data[0].messages[0].id == "Auth.form.error.confirmed") {
                        setError("unconfirmed");
                    } else if (body.data[0].messages[0].id == "Auth.form.error.invalid") {
                        setError("wrong");
                    } else {
                        setError("other");
                    }
                })
            })    
        );
    }

    const handleClose = () => {
        setVisible(false);
        setError(null);
        reset();
        props.refreshUser();
     	props.callbackParent();
    }

    const handleReset = () => {
        setVisible(false);
        props.callbackResetPassword();
    }

    // const handleKeyDown = (e) => {
    //     if (e.key == 'Enter') { handleLogin(); };
    // }

    return (
            <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} 
                    data-testid="nkp-login-modal" onHide={handleClose}>
            <form onSubmit={handleSubmit}>
            <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="align-items-center">
                <Col lg={5} md={5} className="align-middle p-3">
                    <Image src={pageT.image} className="modal-image" />
                </Col>
                <Col lg={7} md={7} sm={12} className="p-5">
                    <h1>{pageT.title}</h1>

                    <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <div>
                            <Row>
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} 
                                    type={inputType.USER_ACCOUNT} datatestid='nkp-username' />
                            </Col>
                            </Row>

                            <Row>
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} 
                                    type={inputType.USER_PASSWORD_1} datatestid='nkp-password' password />
                            </Col>
                            </Row>
                            </div>
                            </div>

                {error ? 
                <Form.Group controlId="error">
                    {error == "unconfirmed" ?
                        <Form.Text className="text-danger pt-3">
                            Вы не подтвердили адрес электронной почты, аккаунт временно неактивен. 
                            Пожалуйста найдите в почтовом ящике письмо, полученное при регистрации 
                            и подтвердите адрес, пройдя по ссылке.
                        </Form.Text>
                    :
                    error == "wrong" ?
                        <>
                            <Form.Text className="text-danger pt-3">Имя пользователя или пароль неверные.</Form.Text>
                            <Form.Text className="text-danger pt-3">Забыли пароль? <a href="#" onClick={handleReset}>Воспользуйтесь сервисом восстановления</a>.</Form.Text>
                        </>
                    :
                        <Form.Text className="text-danger pt-3">Что-то пошло не так. Мы обязательно разберемся и починим.</Form.Text>
                    }
                </Form.Group>
                : ""}
                </Col>
                </Row>
	        </Modal.Body>
	        <Modal.Footer>
                <Button variant="success" type="submit" data-testid="nkp-submit"
                    disabled={promiseInProgress}>
	                Войти
	            </Button>
	        </Modal.Footer>
            </form>
	      </Modal>
	  );
}

export default ModalLogin;