import gql from "graphql-tag";

const OKD_QUERY = gql`
    query OKD ($slug: String!, $limit: Int, $start: Int ){
            dogs(sort:"Name:asc", start: $start, limit: $limit, where : {sports_results_okd_null : false, Name_contains :$slug}){
                id
                Name
                Year
                sports_results_okd {
                    Rank
                    Temperament
                    Judge
                }
            },
        dogsConnection(where : {sports_results_okd_null : false, Name_contains :$slug}) {
            aggregate {
            count
            }
        }
    }
`;

export default OKD_QUERY;