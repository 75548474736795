import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import useWindowDimensions from '../hooks/useWindowDimensions';
import { inputType } from '../services/input-utils';
import InputText from '../components/InputText';
import { useForm } from '../hooks/useForm';
import { contentType, pageType, postContentObject } from '../services/strapi-access-services';

const ModalResetPassword = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

	const { promiseInProgress } = usePromiseTracker();
    const { height, width } = useWindowDimensions();
    const pageT = pageType.MODAL_RESET_PASSWORD;

    const { handleChange, handleSubmit, reset, errors, data } = useForm({
        validations :{
            username: {
                required: {
                    value: true,
                    message: 'Необходимо указать ваше имя пользователя (совпадает с e-mail)',
                },
            },
        },
        onSubmit: () => {
            resetPassword();
        },
    });

    useEffect(
		() => {
			setVisible(props.visible);
		},
		[props, error, message]
	);

    const handleClose = () => {
        setVisible(false);
        setError(null);
        setMessage(null);
        reset();
     	props.callbackParent();
    }

    const resetPassword = () => {
        let application = {
            "email": data.username
        }
        trackPromise(
            postContentObject(contentType.RESET_PASSWORD, application, false)
            .then((result) => {
                let body = result.body;
                let status = result.status;
                return{body, status};
            })
            .then((response) => {
                if (response.status != 200) {
                    setError("Что-то пошло не так...");
                    throw new Error("Whoops!");
                } else {
                    setMessage("На почтовый ящик " + data.username + " отправлено письмо со ссылкой для сброса пароля.");
                    return response.json();
                }
            }) 
            .catch()    
        );

    }

    return (
        <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="align-items-center">
            <Col lg={5} md={5} className="align-middle p-3">
                <Image src={pageT.image} className="modal-image" />
            </Col>
            <Col lg={7} md={7} sm={12} className="p-5">
                <h1>{pageT.title}</h1>

            {message == null && error == null ?
                <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        type={inputType.USER_ACCOUNT} datatestid="nkp-user" />
                </Col>
                </Row>
            :
                error == null ? 
                    <span data-testid="nkp-message">{message}</span>
                :
                    <span className="text-danger">{error}</span>
            }
            </Col>
            </Row>
	        </Modal.Body>
	        <Modal.Footer>
                <Button variant="success" type="submit" 
                    data-testid="nkp-submit" disabled={promiseInProgress}>
	                Сбросить пароль
               </Button>
               <Button variant="danger" onClick={handleClose} 
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Отмена
               </Button>
	        </Modal.Footer>
            </form>
	      </Modal>
	  );
}

export default ModalResetPassword;