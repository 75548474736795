import { contentType, postContentObject, putContentObject } from '../strapi-access-services';

const ticketMapping = [
    { "tKey" : "PRA", "bKey" : "PRA", "type" : "text" },
    { "tKey" : "DM", "bKey" : 'DM', "type" : "text" },
    { "tKey" : "Fluffy", "bKey" : 'Fluffy', "type" : "text" },
    { "tKey" : "vWD", "bKey" : 'vWD', "type" : "text" },
    { "tKey" : "Pink", "bKey" : 'Pink', "type" : "text" },
    { "tKey" : "Deljut", "bKey" : 'Deljut', "type" : "text" },
    { "tKey" : "Chocolate", "bKey" : 'Chocolate', "type" : "text" }, 
    { "tKey" : "lab", "bKey" : 'Laboratory', "type" : "text" },
    { "tKey" : "consentGenPassport", "bKey" : 'Passport', "type" : "boolean" }
];

const checkNewDog = (ticket) => {
    if (ticket.dog == null) return true;
    return false;
}

const checkNewGenetics = (dog) => {
    if (dog.genetic == null) return true;
    return false;
}

const geneticsMethod = (ticket, addDogF, getDogF, prepF) => {
    return Promise.resolve()
    .then(() => {
        if (checkNewDog(ticket)) {
            return addDogF(ticket);
        } 
        return getDogF(ticket);
    })
    .then((dog) => {
        if (dog.error) throw dog.error;
        if (checkNewGenetics(dog)) {
            return postContentObject (contentType.GENETICS, 
                        prepF(ticket, ticketMapping), 
                        true)
            .then((response) => { 
                if(!response.ok) throw response;
                return response.json() 
            })
            .then((genetic) => {
                return putContentObject(contentType.DOG, 
                    dog.id, 
                    { "genetic": genetic.id },
                    true);
            })
            .catch((error) => {
                return ({message : "Other error", error : error});
            });
        }
        return putContentObject(contentType.GENETICS, 
                                dog.genetic.id, 
                                prepF(ticket, ticketMapping), 
                                true);
    })
    .then((result) => {
        if (!result.ok) throw result;
        return putContentObject(contentType.TICKET, 
                                ticket.id, 
                                { Status : "Approved" },
                                true);
    })
    .catch((error) => {
        return { error : "Other error", message : error };
    });
}

export default geneticsMethod;