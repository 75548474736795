export const ticketType = {
    PROMOTE: "Promote",
    ADD_DOG: "AddDog",
    MANAGE: "Manage",
    UNMANAGE: "Unmanage",
    GENETICS: "Genetics",
    CHAMPION: "Champion",
    TITLES: "Titles",
    GENETICS_PLUS: "AddDogAndGenetics",
    DYSPLASIA: "Dysplasia",
    DYSPLASIA_PLUS: "AddDogAndDysplasia",
    OWN_DOG: "Own",
    PUPPIES: "Puppies",
    PROLONG: "Prolong",
    RESULTS_OKD: "OKDResults",
    RESULTS_OKD_PLUS: "AddDogAndOKDResults",
    RESULTS_HERDING: "HerdingResults",
    RESULTS_HERDING_PLUS: "AddDogAndHerdingResults",
    RESULTS_AGILITY: "AgilityResults",
}

export const formatTicketTitle = (ticket) => {
    let result;
    let dogName;

    switch(ticket.Type) {
        case "Promote" :
            result = "Регистрация в НКП";
            break;
        case "AddDog" :
            if (ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname").length > 0) {
                dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0].Value;
                result = "Добавить собаку " + dogName;    
            } else {
                result = "Добавить собаку";    
            }
            break;
        case "Manage" :
            if (ticket.dog != null) {
                dogName = ticket.dog.Name;
                result = "Назначение куратором собаки " + dogName;
            } else {
                result = "Назначение куратором собаки";
            }
            break;
        case "Unmanage" :
            if (ticket.dog != null) {
                dogName = ticket.dog?.Name;
                result = "Отзыв прав куратора собаки " + dogName;
            } else {
                result = "Отзыв прав куратора собаки";
            }
            break;
        case "Genetics" :
            if (ticket.dog != null) {
                dogName = ticket.dog?.Name;
                result = "Подтверждение результатов генетических исследований собаки " + dogName;    
            } else if (ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname").length > 0) {
                dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0].Value;
                result = "Добавление собаки " + dogName + " и подтверждение результатов генетических исследований";    
            } else {
                result = "Подтверждение результатов генетических исследований собаки";    
            }
            break;
        case "AddDogAndGenetics" :
            dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0].Value;
            result = "Добавление собаки " + dogName + " и подтверждение результатов генетических исследований";
            break;    
        case "Dysplasia" :
            if (ticket.dog != null) {
                dogName = ticket.dog?.Name;
                result = "Подтверждение результатов исследований HD / ED собаки " + dogName;
            } else if (ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname").length > 0) {
                dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0]?.Value;
                result = "Добавление собаки " + dogName + " и подтверждение результатов исследований HD / ED";
            } else {
                result = "Подтверждение результатов исследований HD / ED";
            }
            break;
        case "AddDogAndDysplasia" :
            dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0]?.Value;
            result = "Добавление собаки " + dogName + " и подтверждение результатов исследований HD / ED";
            break;
        case "Prolong" :
            result = "Повторная публикация объявления";
            break;
        case "Own" :
            dogName = ticket.dog?.Name;
            result = "Смена текущего куратора собаки " + dogName;
            break;
        case "Champion" :
            dogName = ticket.dog?.Name || "";
            result = "Публикация информации о статусе чемпиона НКП собаки " + dogName;
            break;
        case "Titles" :
            dogName = ticket.dog?.Name || "";
            result = "Публикация титулов собаки " + dogName;
            break;
        case "Puppies" :
            result = "Публикация объявления";
            break;
        case "OKDResults" :
            result = "Подтверждение результатов тестирования ОКД и тестирования на темперамент";
            break;
        case "AddDogAndOKDResults" :
            dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0].Value;
            result = "Добавление собаки " + dogName + " и подтверждение результатов тестирования ОКД и тестирования на темперамент";
            break;
        case "HerdingResults" :
            result = "Подтверждение результатов тестирования пастушьего инстинкта";
            break;
        case "AddDogAndHerdingResults" :
            dogName = ticket.TicketContent.filter(rec => rec.Key.toLowerCase() == "dogname")[0].Value;
            result = "Добавление собаки " + dogName + " и подтверждение результатов тестирования пастушьего инстинкта";
            break;
        case "AgilityResults" :
            result = "Подтверждение результатов соревнований по аджилити";
            break;
        default :
            ""
    }

    return result;
}

export const formatTicketStatus = (stringStatus) => {
    let result;

    switch(stringStatus) {
        case "New" :
            result = "На рассмотрении модератора";
            break;
        case "InProgress" :
            result = "В процессе обработки";
            break;
        case "Approved" :
            result = "Подтверждена модератором";
            break;
        case "Canceled" :
            result = "Отклонена модератором";
            break;
        default :
             ""
    }

    return result;
}
