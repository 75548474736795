import { contentType, postContentObject, putContentObject } from '../strapi-access-services';

const ticketMapping = [
    { "tKey" : "chooseHerding", "bKey" : 'TPI', "type" : "text" },
    { "tKey" : "test", "bKey" : 'Test', "type" : "text" },
    { "tKey" : "IHT-TS-1", "bKey" : 'Rank1Class', "type" : "text" },
    { "tKey" : "IHT-TS-2", "bKey" : 'Rank2Class', "type" : "text" },
    { "tKey" : "IHT-TS-3", "bKey" : 'Rank3Class', "type" : "text" },
    { "tKey" : "titles", "bKey" : 'Title', "type" : "text" },
];

const checkNewDog = (ticket) => {
    if (ticket.dog == null) return true;
    return false;
}

const checkNewResults = (dog) => {
    if (dog.sports_results_herding == null) return true;
    return false;
}

const herdingResultsMethod = (ticket, addDogF, getDogF, prepF) => {
    return Promise.resolve()
    .then(() => {
        if (checkNewDog(ticket)) {
            return addDogF(ticket);
        } 
        return getDogF(ticket);
    })
    .then((dog) => {
        if (dog.error) throw dog.error;
        if (checkNewResults(dog)) {
            return postContentObject (contentType.RESULTS_HERDING, 
                        prepF(ticket, ticketMapping), 
                        true)
            .then((response) => { 
                if(!response.ok) throw response
                return response.json() 
            })
            .then((results) => {
                return putContentObject(contentType.DOG, 
                    dog.id, 
                    { "sports_results_herding": results.id },
                    true);
            })
            .catch((error) => {
                return { error : "Other error", message : error };
            });
        }
        return putContentObject(contentType.RESULTS_HERDING, 
                                dog.sports_results_herding.id, 
                                prepF(ticket, ticketMapping), 
                                true);
    })
    .then((result) => {
        if (!result.ok) throw result;
        return putContentObject(contentType.TICKET, 
                                ticket.id, 
                                { Status : "Approved" },
                                true);
    })
    .catch((error) => {
        return { error : "Other error", message : error };
    });
}

export default herdingResultsMethod;