import gql from "graphql-tag";

const USER_PROFILE_QUERY = gql`
    query UserProfile ($slug: ID!) {
        dogs(where: { _or : [{ owner : { id : $slug } }, { curators : { id : $slug } }] }) {
            id
            Name
            Year
        }
        puppies(where : { users : { id : $slug } }) {
            Title
            Content
            published_at
        }
        tickets(sort : "id:asc", where : { users_permissions_user : { id : $slug } }) {
            id
            Type
            Status
            published_at
            TicketContent {
                Key
                Value
            }
            dog {
                Name
            }
        }
    }
`;

export default USER_PROFILE_QUERY;