import gql from "graphql-tag";

const CATEGORIES_QUERY = gql`
    query Genetics ($slug: String!, $limit: Int, $start: Int ){
        dogs(sort:"Name:asc", start: $start, limit: $limit, where : {genetic_null : false, Name_contains :$slug}){
            id
            Name
            Year
            genetic {
                PRA
                DM
                Fluffy
                vWD      
                Pink
                Deljut
                Chocolate
                Passport
                Laboratory
            }
        },
        dogsConnection(where : {genetic_null : false, Name_contains :$slug}) {
            aggregate {
                count
            }
        }
    }
`;

export default CATEGORIES_QUERY;