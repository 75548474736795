import gql from "graphql-tag";

const DOGS_SHORT_QUERY = gql`
    query DogsShort {
        dogs (sort: "Name:asc") {
            id
            Name
        }
    }
`;

export default DOGS_SHORT_QUERY;