import gql from "graphql-tag";

const TICKETS_QUERY = gql`
   query Tickets {
      tickets (sort: "id:asc", where: {_or : [{ Status : "New" }, { Status : "Processing..." }]}) {
         id
         Date
         published_at
         users_permissions_user {
            id
            username
            email
            role {
               id
               name
               description
            }
            name
         }
         dog {
            id
            Name
            Year
            genetic {
               id
            }
            dysplasia {
               id
            }
            sports_results_okd {
               id
            }
            sports_results_herding {
               id
            }
            sports_results_agility {
               id
            }
            Champion
            Titles
            DocGeneral{
               id
               name
               url
            }
            Pic{
               id
               name
               url
            }
         }
         Status
         Type
         TicketContent {
            id
            Key
            Value
         }
         Attachment {
            id
            name
            url
         }
         Fotos {
            id
            name
            url
         }
      }
   }
`;

export default TICKETS_QUERY;