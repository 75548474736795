import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from "@apollo/client";
import { HttpLink } from "@apollo/client";
import { base_url } from "../services/strapi-access-services";

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: `${base_url}/graphql`
});
const client = new ApolloClient({
  cache,
  link
});

export default client;