import gql from "graphql-tag";

const CHAMPIONS_QUERY = gql`
    query Champions ($slug: String!, $limit: Int, $start: Int ){
        dogs(sort:"Name:asc", start: $start, limit: $limit, where : {Champion : true, Name_contains :$slug}){
            id
            Name
            Year
            Pic {
                url
            }
            owner {
                name
            }
        },
        dogsConnection(where : {Champion : true, Name_contains :$slug}) {
            aggregate {
                count
            }
        }
    }
`;

export default CHAMPIONS_QUERY;