//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog, faFlask, faCalendarAlt, faUserAlt, faEnvelopeOpen,
         faKey, faSearch, faBriefcaseMedical, faBalanceScale,
         faRuler, faTrophy, faEdit, faLandmark, faBuilding, faPhone,
         faFileAlt, faStamp, faMicrochip } from '@fortawesome/free-solid-svg-icons';

export const inputType = {
    USER_NAME: {
        placeholder: "Ваши фамилия, имя и отчество",
        key: "name",
        icon: faUserAlt,
    },
    USER_ACCOUNT: {
        placeholder: "Имя пользователя (e-mail)",
        key: "username",
        icon: faUserAlt,
    },
    USER_EMAIL: {
        placeholder: "Укажите ваш адрес электронной почты",
        key: "email",
        icon: faEnvelopeOpen,
    },
    USER_PASSWORD_1: {
        placeholder: "Введите пароль",
        key: "password1",
        icon: faKey,
    },
    USER_PASSWORD_2: {
        placeholder: "Повторно укажите пароль",
        key: "password2",
        icon: faKey,
    },
    USER_BIRTHDAY: {
        placeholder: "Дата рождения владельца",
        key: "birthday",
        icon: faCalendarAlt
    },
    CONSENT_PRIVACY: {
        key: "consentPrivacy",
    },
    CONSENT_RULES: {
        key: "consentRules",
    },
    CONSENT_DOCUMENTS: {
        key: "consentDocuments",
    },
    DOG_NAME: {
        placeholder: "Кличка (полная)",
        key: "dogName",
        icon: faDog,
    },
    DOG_YEAR: {
        placeholder: "Год рождения",
        key: "dogYear",
        icon: faCalendarAlt,
    },
    LABORATORY: {
        placeholder: "Лаборатория, выдавшая заключение",
        key: "lab",
        icon: faFlask,
    },
    DOCTOR: {
        placeholder: "Врач, выдавший заключение",
        key: "doctor",
        icon: faBriefcaseMedical,
    },
    JUDGE: {
        placeholder: "Судья, подписавший заключение",
        key: "judge",
        icon: faBalanceScale,
    },
    DOG_CHOOSE: {
        placeholder: "Выберите собаку",
        key: "chooseDog",
        newName: "Новая собака"
    },
    GENETICS_CHOOSE: {
        placeholder: "Результат",
        key: "chooseGenetics",
    },
    CONSENT_GEN_PASSPORT: {
        key: "consentGenPassport",
    },
    DYSPLASIA_CHOOSE: {
        placeholder: "Результат исследования",
        key: "chooseDysplasia",
    },
    OKD_CHOOSE: {
        placeholder: "Результат тестирования",
        key: "chooseOKD",
    },
    TEMPER_CHOOSE: {
        placeholder: "Результат тестирования",
        key: "chooseTemper",
    },
    HERDING_CHOOSE: {
        placeholder: "Результат тестирования",
        key: "chooseHerding",
    },
    HERDING_TEST: {
        placeholder: "Результат испытания",
        key: "test",
        icon: faRuler,
    },
    HERDING_IHT_TS_1: {
        placeholder: "IHT-TS-1",
        key: "IHT-TS-1",
    },
    HERDING_IHT_TS_2: {
        placeholder: "IHT-TS-2",
        key: "IHT-TS-2",
    },
    HERDING_IHT_TS_3: {
        placeholder: "IHT-TS-3",
        key: "IHT-TS-3",
    },
    HERDING_TITLES: {
        placeholder: "Присвоенные титулы",
        key: "titles",
        icon: faTrophy
    },
    AGILITY_A1: {
        placeholder: "A1",
        key: "a1",
        icon: faRuler
    },
    AGILITY_A2: {
        placeholder: "A2",
        key: "a2",
        icon: faRuler
    },
    AGILITY_A3: {
        placeholder: "A3",
        key: "a3",
        icon: faRuler
    },
    MEMBER_TYPE: {
        placeholder: "В каком качестве вы регистрируетесь?",
        key: "memberType"
    },
    MEMBER_NAME: {
        placeholder: "Полное название",
        key: "memberName",
        icon: faLandmark
    },
    LEGAL_ADDRESS: {
        placeholder: "Юридический адрес",
        key: "jurAddress",
        icon: faBuilding
    },
    MANAGER_NAME: {
        placeholder: "Руководитель секции",
        key: "managerName",
        icon: faUserAlt
    },
    ADDRESS: {
        placeholder: "Почтовый адрес с индексом",
        key: "address",
        icon: faBuilding
    },
    MEMBER_EMAIL: {
        placeholder: "Электронная почта",
        key: "memberEmail",
        icon: faEnvelopeOpen
    },
    PHONE: {
        placeholder: "Телефон",
        key: "phone",
        icon: faPhone
    },
    CHIEF_NAME: {
        placeholder: "Руководитель клуба",
        key: "chiefName",
        icon: faUserAlt
    },
    PREFIX: {
        placeholder: "Код клейма",
        key: "prefix",
        icon: faLandmark
    }, 
    BREED: {
        placeholder: "Родословная №",
        key: "breed",
        icon: faFileAlt
    },
    STAMP: {
        placeholder: "Клеймо",
        key: "stamp",
        icon: faStamp
    },
    CHIP: {
        placeholder: "Микрочип",
        key: "chip",
        icon: faMicrochip
    },
    FILE: {
        key: "files",
    },
    FOTO: {
        key: "fotos",
    },
    SEARCH: {
        placeholder: "Кличка собаки",
        key: "searchDog",
        icon: faSearch,
    },
    MESSAGE: {
        placeholder: "Текст сообщения",
        key: "message",
        icon: faEdit,
    },
    PUPPIES: {
        placeholder: "Текст объявления",
        key: "puppies",
        icon: faEdit,
    },
    TITLE: {
        placeholder: "Заголовок",
        key: "title",
        icon: faEdit,
    },
    SUPPORT_OTHER: {
        placeholder: "Укажите свой вариант",
        key: "supportOther",
        icon: faEdit,
    },
    SUPPORT1: {
        key: "nkpSupport1",
    },
    SUPPORT2: {
        key: "nkpSupport2",
    },
    SUPPORT3: {
        key: "nkpSupport3",
    },
    SUPPORT4: {
        key: "nkpSupport4",
    },
    SUPPORT5: {
        key: "nkpSupport5",
    },
}