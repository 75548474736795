import gql from "graphql-tag";

const SPORT_SECTION_QUERY = gql`
    query SportsSections {
        sportsCategories (sort: "Order:asc") {
            id
            Title
            Section
            Image {
                url
            }
        }
    }
`;

export default SPORT_SECTION_QUERY;