import gql from "graphql-tag";

const NEWS_QUERY = gql`
    query News ($start:Int, $limit:Int, $date:DateTime){
        newsItems (start:$start, limit:$limit, where:{Date_lte: $date}, sort:"Date:desc") {
            id
            Date
            Short
            Content
            Image {
                url
            }
            Link
            Url
        }
        newsItemsConnection(where:{Date_lte: $date, published_at_null: false}) {
            aggregate {
               count
            }
        }
    }    
`;

export default NEWS_QUERY;