import { contentType, postContentObject, putContentObject } from '../strapi-access-services';

const checkNewDog = (ticket) => {
    if (ticket.dog == null) return true;
    return false;
}

const championMethod = (ticket, addDogF, getDogF) => {
    return Promise.resolve()
    .then(() => {
        if (checkNewDog(ticket)) {
            return addDogF(ticket);
        } 
        return getDogF(ticket);
    })
    .then((dog) => {
        if (dog.error) throw dog.error;
        return putContentObject(contentType.DOG, 
                                dog.id, 
                                { Champion : true }, 
                                true);
    })
    .then((result) => {
        if (!result.ok) throw result;
        return putContentObject(contentType.TICKET, 
                                ticket.id, 
                                { Status : "Approved" },
                                true);
    })
    .catch((error) => {
        return { error : "Other error", message : error };
    });
}

export default championMethod;