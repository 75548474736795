import React, { useState, useEffect, useContext } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import useWindowDimensions from '../../shared/hooks/useWindowDimensions';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog } from '@fortawesome/free-solid-svg-icons';
import { pageType } from '../services/strapi-access-services';
import { useForm } from '../hooks/useForm';
import InputFile from '../components/InputFile';
import { inputType } from '../services/input-utils';
import { ticketType } from '../services/ticket-utils';
import { submitTicket } from '../services/tickets-api';
import { ProfileContext } from '../contexts/profile-context';
import ModalAccepted from './ModalAccepted';
import { useQuery } from "@apollo/client";
import DOGS_SHORT_QUERY from '../queries/dogs-short';

const ModalReplace = (props) => {
    const [visible, setVisible] = useState(null);
    const [okVisible, setOkVisible] = useState(false);
    const [dogs, setDogs] = useState([]);
    const [curators, setCurators] = useState([]);
    const [dogOps, setDogOps] = useState([]);
    const [singleSelections, setSingleSelections] = useState([]);

	const { promiseInProgress } = usePromiseTracker();    
    const { height, width } = useWindowDimensions();
    const [profile, setProfile] = useContext(ProfileContext);
    const pageT = pageType.MODAL_REPLACE;

    const { dogdata, loading, error } = useQuery(DOGS_SHORT_QUERY, { onCompleted: dogdata => setDogs(dogdata.dogs) });

    const { handleChoose, handleSubmit, reset, errors, data } = useForm({
        validations :{
            dogName: {
                required: {
                    value: true,
                    message: 'Необходимо указать кличку',
                },
                custom: {
                    isValid: value => RegExp('^[A-Za-zА-Яа-я\- ]*$').test(value),
                    message: 'Допустимы только буквы и дефисы',
                },
                custom: {
                    isValid: value => dogOps.filter(rec => rec == value.toString().trim()).length > 0,
                    message: 'Собаки с такой кличкой нет!',
                },
            },
            files: {
                custom: {
                    isValid: (value, data) => value.length > 0,
                    message: 'Необходимо предоставить подтверждающие документы',
                },
            },
        },
        onSubmit: () => {
            let filteredDog = dogs.filter(rec => rec.Name == singleSelections);
            if (filteredDog.length > 0) {
                let dataClear = {};
                dataClear.files = data.files;
                dataClear.chooseDog = filteredDog[0].id;
                dataClear.curatorId = profile.id.toString();
                trackPromise(
                    submitTicket(ticketType.OWN_DOG, dataClear, profile)
                    .then(() => {
                        setOkVisible(true);
                        setVisible(false);
                    })
                );    
            }
        },
    });

    useEffect(
		() => {
            setVisible(props.visible);
		},
		[props.visible, profile],
	);

    useEffect(
        () => {
            let filteredDog = dogs.filter(rec => rec.Name == singleSelections);
            if (filteredDog.length > 0) {
                setCurators(filteredDog[0].curators);
            } else {
                setCurators([]);
            }    
            handleChoose("dogName")(singleSelections);
        }, 
        [singleSelections]
    )

    useEffect(
        () => {
            let newOps = [];
            dogs.map((dog) => {
                if (dog.Name != null) newOps.push(dog.Name);
            })
            setDogOps(newOps);
        },[dogs]
    );

    const handleCloseOk = () => {
        setOkVisible(false);
        handleClose();
    }

    const handleClose = () => {
        setSingleSelections([]);
     	props.callbackParent();
        setVisible(false);
        reset();
    }

    return (
        <>
        <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} onHide={handleClose} 
            data-testid="nkp-user-own">
        <form onSubmit={handleSubmit}>
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {error != null ?
            <h4 style={{ color : "red" }}>Что-то пошло не так. Мы обязательно разберемся и исправим.</h4>
            :
            <Row className="align-items-center">
                <Col lg={5} md={5} className="align-middle p-3">
                    <Image src={pageT.image} className="modal-image" />
                </Col>
                <Col lg={7} md={7} sm={12} className="p-5">
                    <h1>{pageT.title}</h1>
            <InputGroup className={errors["dogName"] ? "nkp-search-box-invalid" : "nkp-search-box"} data-testid="nkp-group">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faDog} />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={setSingleSelections}
                    options={dogOps}
                    placeholder="Кличка собаки"
                    selected={singleSelections}
                    isInvalid={errors["dogName"]!=null}
                />
                </InputGroup>
                {errors["dogName"]!=null ?
                <Form.Text className="text-danger">
                    {errors["dogName"]}
                </Form.Text>
                : null}
            <div className="mt-4">
                {curators?.length > 0 ? 
                    curators?.length > 1 ? "Текущими кураторами являются:" : "Текущим куратором является:"
                :
                    ""}
                <ul>
                {curators?.map((user, index) => {
                    return (
                        <li key={index}>{user.name != null ? user.name : user.email}</li>
                    )
                })} 
                </ul>
            </div>

            <Row>
                <Col>
                <Form.File.Label>
                    <div>
                        <small>
                            Пожалуйста загрузите скан или фото документа, подтверждающего, что вы 
                            являетесь владельцем или заводчиком собаки. Документ не будет опубликован 
                            на сайте, но он нужен модератору для подтверждения предоставленной информации.
                        </small>
                    </div>                
                    </Form.File.Label>
                    <div>
                        <InputFile data={data} errors={errors} handleChoose={handleChoose} type={inputType.FILE} 
                                    inputId="file-input-group" inputTitle="Загрузите файл" datatestid="nkp-file" />
                    </div>

                </Col>
            </Row>
            </Col>
            </Row>}
	        </Modal.Body>
	        <Modal.Footer>
                <Button variant="success" type="submit" 
                    data-testid="nkp-submit" disabled={promiseInProgress}>
	                Отправить
               </Button>
               <Button variant="danger" onClick={handleClose} 
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Отмена
               </Button>
	        </Modal.Footer>
            </form>
	      </Modal>

          <ModalAccepted visible={okVisible} callbackParent={() => handleCloseOk()} />
          </>
	  );
}

export default ModalReplace;