import React, { useState, useEffect, useContext } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import useWindowDimensions from '../hooks/useWindowDimensions';
import ModalPrivacy from './ModalPrivacy';
import { trackPromise } from 'react-promise-tracker';
import { inputType } from '../services/input-utils';
import InputText from '../components/InputText';
import InputCheckbox from '../components/InputCheckbox';
import { usePromiseTracker } from "react-promise-tracker";
import { useForm } from '../hooks/useForm';
import { contentType, pageType, postContentObject } from '../services/strapi-access-services';
import ModalAccepted from './ModalAccepted';

const ModalRegister = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [okVisible, setOkVisible] = useState(false);
    const [privacyVisible, setPrivacyVisible] = useState(false);
    const [error, setError] = useState(null);
    const pageT = pageType.REGISTER;

    const { handleChange, handleCheck, handleSubmit, reset, errors, data } = useForm({
        validations :{
            name: {
                required: {
                    value: true,
                    message: 'Необходимо указать ваше имя',
                },
                custom: {
                    isValid: (value, data) => RegExp('^[A-Za-zА-Яа-я\- ]*$').test(value),
                    message: 'Допустимы только буквы и дефисы',
                },
            },
            email: {
                required: {
                    value: true,
                    message: 'Необходимо указать адрес электронной почты',
                },
            },
            password1: {
                required: {
                    value: true,
                    message: 'Необходимо выбрать пароль',
                },
                combinationRequired: {
                    isValid: (data, value) => data.password1 == data.password2 && value,
                    message: 'Пароли не совпадают',
                },
            },
            password2: {
                required: {
                    value: true,
                    message: 'Необходимо выбрать пароль',
                },
                combinationRequired: {
                    isValid: (data, value) => data.password1 == data.password2 && value,
                    message: 'Пароли не совпадают',
                },
            },
            consentPrivacy: {
                required: {
                    value: true,
                    message: 'Чтобы зарегистрироваться необходимо согласиться с политикой.',
                },
            },
        },
        onSubmit: () => {
            register();
        },
    });

    const { height, width } = useWindowDimensions();
	const { promiseInProgress } = usePromiseTracker();

    useEffect(
		() => {
			setVisible(props.visible);
		},
		[props.visible],
	);

    const handleCloseOk = () => {
        setOkVisible(false);
        handleClose();
    }

    const handleClose = () => {
        setVisible(false);
        reset();
     	props.callbackParent();
    }

    const register = () => {
        let application = {
            username: data.email.trim(),
            email: data.email.trim(),
            password: data.password1,
            name: data.name
        };
        trackPromise(
            postContentObject(contentType.REGISTER, application, false)
            .then((result) => {
                if (!result.ok) throw result;
                return result.json();
            })
            .then(() => {
                setOkVisible(true);
                setVisible(false);
            })
            .catch((error) => {
                error.json().then((body) => {
                    if (body.data[0].messages[0].id == "Auth.form.error.email.taken") {
                        setError("double");
                    } else {
                        setError("other");
                    }
                })
            })    
        );
    }

    const handleReadPrivacy = (e) => {
        e.preventDefault();
        setVisible(false);
        setPrivacyVisible(true);
    }

    const handleClosePrivacy = () => {
        setVisible(true);
        setPrivacyVisible(false);
    }

    return (
        <>
	      <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} 
            data-testid="nkp-register-modal" onHide={handleClose}>
          <form onSubmit={handleSubmit}>
	        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
				<Modal.Title></Modal.Title>
	        </Modal.Header>
	        <Modal.Body>          
                <Row className="align-items-center">
                    <Col lg={5} md={5} className="align-middle p-3">
                        <Image src={pageT.image} className="modal-image" />
                    </Col>
                    <Col lg={7} md={7} sm={12} className="p-5">
                        <h1>{pageT.title}</h1>

                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <div>
                            <Row>
                                <Col>
                                    Заявки на вступление в члены НКП просим направлять в свободной форме 
                                    на электронную почту <a href="mailto:cardiganclub@mail.ru">cardiganclub@mail.ru</a>
                                </Col>
                            </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
	        </Modal.Body>
	        <Modal.Footer>
                <Button variant="danger" onClick={handleClose} 
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Закрыть
                </Button>
	        </Modal.Footer>
            </form>
        </Modal>

        <ModalPrivacy style={{ zIndex: 1500 }} visible={privacyVisible} 
            callbackParent={() => handleClosePrivacy()}
        />

        <ModalAccepted visible={okVisible} parent="Register" callbackParent={() => handleCloseOk()} />
    </>
    );
}

export default ModalRegister;