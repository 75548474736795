import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Link } from 'react-router-dom';
import { formatDate } from '../shared/services/utils';
import { transformImageUri, getMediaUrl } from '../shared/services/strapi-access-services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Query from "../shared/components/Query";

import NEWS_QUERY from "../shared/queries/news";

const NewsWidget = (props) => {
    const [newsCount, setNewsCount] = useState(3);

    const newsCountIncrement = 3;
	const date = new Date();

    const incrementNews = () => {
        setNewsCount(newsCount + newsCountIncrement);
    }

    return (
        <div className="bg-light px-5 pb-3 border-top border-primary">
            <Container>
            <Query query={NEWS_QUERY} date={date.toISOString().split('.')[0] + 'Z'}>
			{({ data: { newsItems, newsItemsConnection } }) => {
                let totalNews = newsItemsConnection.aggregate.count;
				return(
                    <div className="mt-5 p-3 nkp-box-shadow">
                        <h4 style={{ marginTop: "0px" }}>Новости</h4>

                        {newsItems.map((news, index) => {
                            if (index < newsCount) {
                                return(
                                    <div key={index} className={index != 0 ? "py-3 border-top border-primary" : "py-3"}>
                                        <div className="d-flex pb-3 justify-content-end" data-testid="nkp-news-date">
                                            <i>
                                                <small>
                                                    {formatDate(news.Date)}
                                                </small>
                                            </i>
                                        </div>

                                        {news.Short != null && 
                                            <div data-testid="nkp-news-date">
                                                <small>
                                                    <ReactMarkdown 
                                                        rehypePlugins={[rehypeRaw]} transformImageUri={transformImageUri}
                                                        children={news.Short} />
                                                </small>
                                            </div>
                                        }

                                        {news.Image != null && <div><center><Image src={getMediaUrl(news.Image)} /></center></div>}

                                        {news.Url != null && news.Url != "" ?
                                            <div className="d-flex pb-3 justify-content-end">
                                                <small>
                                                    <a href={news.Url}>
                                                        <i>Подробнее</i>
                                                        <FontAwesomeIcon icon={faChevronRight} className="pl-1" style={{ color: "#0081C9" }} size="sm" />
                                                    </a>
                                                </small>
                                            </div>
                                        :
                                        news.Link != null && news.Link != "" ?
                                            <div className="d-flex pb-3 justify-content-end">
                                                <small>
                                                    <Link to={news.Link}>
                                                        <i>Подробнее</i>
                                                        <FontAwesomeIcon icon={faChevronRight} className="pl-1" style={{ color: "#0081C9" }} size="sm" />
                                                    </Link>
                                                </small>
                                            </div>
                                        :
                                            news.Content != null && news.Content != "" ?
                                            <div className="d-flex pb-3 justify-content-end">
                                                <small>
                                                    <Link to="/news">
                                                        <i>Подробнее</i>
                                                        <FontAwesomeIcon icon={faChevronRight} className="pl-1" style={{ color: "#0081C9" }} size="sm" />
                                                    </Link>
                                                </small>
                                            </div>
                                        : null}
                                    </div>
                                )
                            }
                        })}

                        {totalNews > newsCount &&
                        <div onClick={incrementNews} data-testid="nkp-news-extend">
                            <div style={{ color: "#0081C9" }}><center><small><small>Больше новостей</small></small></center></div>
                            <div>
                                <center>
                                    <FontAwesomeIcon icon={faChevronDown} style={{ color: "#0081C9" }} size="lg" />
                                </center>
                            </div>
                        </div>}
                </div>
                );
                }}
            </Query>		            
            </Container>
        </div>
    );
}

export default NewsWidget;