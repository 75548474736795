import React, { useState, useEffect, useContext } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import useWindowDimensions from '../../shared/hooks/useWindowDimensions';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import { pageType } from '../services/strapi-access-services';
import { useForm } from '../hooks/useForm';
import InputText from '../components/InputText';
import InputFile from '../components/InputFile';
import { inputType } from '../services/input-utils';
import { ticketType } from '../services/ticket-utils';
import { submitTicket } from '../services/tickets-api';
import { ProfileContext } from '../contexts/profile-context';
import ModalAccepted from './ModalAccepted';
import { useQuery } from "@apollo/client";
import DOGS_SHORT_QUERY from '../queries/dogs-short';

const ModalAddDog = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [allDogs, setAllDogs] = useState([]);
    const [okVisible, setOkVisible] = useState(false);
    const pageT = pageType.MODAL_ADD_DOG;

    const { dogdata, loading, error } = useQuery(DOGS_SHORT_QUERY, { onCompleted: dogdata => {
            let newOps = [];
            dogdata.dogs.map((dog) => {
                if (dog.Name != null) newOps.push(dog.Name);
            })
            setAllDogs(newOps);
        }
    });

    const { handleChange, handleChoose, handleSubmit, reset, errors, data } = useForm({
        validations :{
            dogName: {
                required: {
                    value: true,
                    message: 'Необходимо указать кличку',
                },
                custom: {
                    isValid: value => RegExp('^[A-Za-zА-Яа-я\- ]*$').test(value),
                    message: 'Допустимы только буквы и дефисы',
                },
                custom: {
                    isValid: value => !checkDog(value),
                    message: 'Собака с такой кличкой уже зарегистрирована!',
                },
            },
            dogYear: {
                required: {
                    value: true,
                    message: 'Необходимо указать год рождения',
                },
                custom: {
                    isValid: value => parseInt(value, 10) > 1975 && parseInt(value, 10) < 2100,
                    message: 'Похоже, ошибка в годе рождения - должен быть между 1975 и 2100',
                },
            },
            files: {
                custom: {
                    isValid: (value, data) => value.length > 0,
                    message: 'Необходимо предоставить подтверждающие документы',
                },
            },
        },
        onSubmit: () => {
            trackPromise(
                submitTicket(ticketType.ADD_DOG, data, profile)
                .then(() => {
                    setOkVisible(true);
                    setVisible(false);
                })
            )
        },
    });

    const [profile, setProfile] = useContext(ProfileContext);
    const { height, width } = useWindowDimensions();
	const { promiseInProgress } = usePromiseTracker();

    useEffect(
		() => {
			setVisible(props.visible);
		},
		[props.visible],
	);

    const checkDog = (name) => {
        if (allDogs.filter(rec => rec == name).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const handleCloseOk = () => {
        setOkVisible(false);
        handleClose();
    }

    const handleClose = () => {
        setVisible(false);
     	props.callbackParent();
        reset();
    }

    return (
        <>
        <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} 
            onHide={handleClose} data-testid="nkp-add-dog">
        <form onSubmit={handleSubmit}>
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {error != null ?
            <h4 style={{ color : "red" }}>Что-то пошло не так. Мы обязательно разберемся и исправим.</h4>
            :
            <>
            <Row className="align-items-center">
                <Col lg={5} md={5} className="align-middle p-3">
                    <Image src={pageT.image} className="modal-image" />
                </Col>
                <Col lg={7} md={7} sm={12} className="p-5">
                    <h1>{pageT.title}</h1>

                    <div className="d-flex align-items-center" style={{ height: '100%' }}>
                        <div>
                            <Row>
                                <Col>
                                    <InputText data={data} errors={errors} handleChange={handleChange} 
                                        type={inputType.DOG_NAME} datatestid="nkp-dog-name" />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <InputText data={data} errors={errors} handleChange={handleChange} 
                                        type={inputType.DOG_YEAR} datatestid="nkp-dog-year" />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <Form.File.Label>
                                        <div>
                                            <small>
                                                Пожалуйста загрузите скан или фото документа, подтверждающего, что вы 
                                                являетесь владельцем или заводчиком собаки. Документ не будет опубликован 
                                                на сайте, но он нужен модератору для подтверждения предоставленной информации.
                                            </small>
                                        </div>                
                                    </Form.File.Label>
                                    <div>
                                        <InputFile data={data} errors={errors} handleChoose={handleChoose} type={inputType.FILE} 
                                                    inputId="file-input-group" inputTitle="Загрузите файл" datatestid="nkp-file" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
            </>}
	        </Modal.Body>
	        <Modal.Footer>
            <Button variant="success" type="submit" 
                    data-testid="nkp-submit" disabled={promiseInProgress}>
	                Отправить
               </Button>
               <Button variant="danger" onClick={handleClose} 
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Отмена
               </Button>
	        </Modal.Footer>
            </form>
	      </Modal>

        <ModalAccepted visible={okVisible} callbackParent={() => handleCloseOk()} />
        </>
	  );
}

export default ModalAddDog;