import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from "react-bootstrap/Form";
import useWindowDimensions from '../../shared/hooks/useWindowDimensions';
import emailjs from 'emailjs-com';
import apiKeys from '../../apikeys';
import { usePromiseTracker } from "react-promise-tracker";
import ModalPrivacy from '../modals/ModalPrivacy';
import { pageType } from '../services/strapi-access-services';
import { useForm } from '../hooks/useForm';
import InputText from '../components/InputText';
import InputCheckbox from '../components/InputCheckbox';
import InputTextArea from '../components/InputTextArea';
import { inputType } from '../services/input-utils';
import ModalAccepted from './ModalAccepted';

const ModalSendMessage = (props) => {
    const [visible, setVisible] = useState(false);
    const [okVisible, setOkVisible] = useState(false);
    const [kennel, setKennel] = useState(null);
    const [privacyVisible, setPrivacyVisible] = useState(false);

	const { promiseInProgress } = usePromiseTracker();
    const { height, width } = useWindowDimensions();
    const pageT = pageType.MODAL_SEND_MESSAGE;

    const { handleChange, handleCheck, handleSubmit, reset, errors, data } = useForm({
        validations :{
            message: {
                required: {
                    value: true,
                    message: 'Вы не написали текст сообщения',
                },
            },
            name: {
                required: {
                    value: true,
                    message: 'Необходимо указать ваше имя',
                },
                custom: {
                    isValid: (value, data) => RegExp('^[A-Za-zА-Яа-я\- ]*$').test(value),
                    message: 'Допустимы только буквы и дефисы',
                },
            },
            email: {
                required: {
                    value: true,
                    message: 'Необходимо указать ваш адрес электронной почты, по которому с вами смогут связаться.',
                },
            },
            consentPrivacy: {
                required: {
                    value: true,
                    message: 'Чтобы отправить сообщение необходимо согласиться с политикой.',
                },
            },
        },
        onSubmit: () => {
            handleSend();
        },
    });

    useEffect(
		() => {
			setVisible(props.visible);
            setKennel(props.kennel);
		},
		[props.visible, props.kennel],
	);

    const handleCloseOk = () => {
        setOkVisible(false);
        handleClose();
    }

    const handleClose = () => {
        setKennel(null);
        setVisible(false);
     	props.callbackParent();
        reset();
    }

    const handleSend = () => {
        var templateParams = {
            from_name: data.name + "<" + data.email + ">",
            to_name: kennel.Name,
            to_address: kennel.Email,
            message: data.message
        };

        emailjs.send(apiKeys.SERVICE_ID, apiKeys.TEMPLATE_ID, templateParams, apiKeys.USER_ID)
        .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
            console.log('FAILED...', error);
        });
        handleClose();    
    }

    const handleReadPrivacy = (e) => {
        e.preventDefault();
        setVisible(false);
        setPrivacyVisible(true);
    }

    const handleClosePrivacy = () => {
        setVisible(true);
        setPrivacyVisible(false);
    }

    return (
        <>
        <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="align-items-center">
                <Col lg={5} md={5} className="align-middle">
                    <Image src={pageT.image} className="modal-image" />
                </Col>
                <Col lg={7} md={7} sm={12} className="px-4">
                    <h1>{pageT.title}</h1>

            {kennel &&
            <>
                <Form.Label data-testid="nkp-text">
                    <small>Вы можете написать сообщение <strong>{kennel.Name}</strong>, мы обязательно его доставим.</small>
                </Form.Label>
                    <InputTextArea data={data} errors={errors} handleChange={handleChange} rows={5} 
                        type={inputType.MESSAGE} datatestid="nkp-message" />
                <Row>
                    <Col>
                        <InputText data={data} errors={errors} handleChange={handleChange} 
                            type={inputType.USER_NAME} datatestid="nkp-user" />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <InputText data={data} errors={errors} handleChange={handleChange} 
                            type={inputType.USER_EMAIL} datatestid="nkp-email" />
                    </Col>
                </Row>
                <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} datatestid="nkp-privacy-ok"
                    type={inputType.CONSENT_PRIVACY} label={(<>Я ознакомился и согласен 
                    с <a href="" onClick={handleReadPrivacy}>политикой в отношении обработки
                    персональных данных</a></>)} />

            </>}
            </Col>
            </Row>
	        </Modal.Body>
            <Modal.Footer>
                <Button variant="success" type="submit" 
                    data-testid="nkp-submit" disabled={promiseInProgress}>
	                Добавить
               </Button>
               <Button variant="danger" onClick={handleClose} 
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Отмена
               </Button>
	        </Modal.Footer>
            </form>
        </Modal>

            <ModalPrivacy visible={privacyVisible} 
                callbackParent={() => handleClosePrivacy()}
            />

            <ModalAccepted visible={okVisible} callbackParent={() => handleCloseOk()} />
        </>
	  );
}

export default ModalSendMessage;