import gql from "graphql-tag";

const EXHIBITION_SINGLE_QUERY = gql`
query Exhibitions ($slug:ID!){
  exhibition(id:$slug){
    id
    Type
    Start
    Organizer
    Rank
    City
    Pictures {
      id
      url
      formats
    }
    Report {
      url
    }
  }
}
`;

export default EXHIBITION_SINGLE_QUERY;