import React, { useState, useEffect } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import { getContentObject } from '../services/strapi-access-services';
import Query from "./Query";

const MenuItem = (props) => {
    const [entities, setEntities] = useState([]); 

	const refresh = () => {
        getContentObject(props.contentType, [], 'Order')
        .then((objs) => {
            if (objs == "Permission denied") {
                history.push('/');
            } else if (objs == "Unauthorized") {
                handleLogoff();
            } else {
                if (Array.isArray(objs)) {
                    setEntities(objs) 
                }
            }
        });
    }

    // useEffect(
	// 	() => {
	// 		refresh();
	// 	},
	// 	[props.itemSet, props.contentType],
	// );

    return (
        <NavDropdown title={<span className="nkp-menu-item-text text-white">{props.title}</span>} 
        className="nkp-menu-item" id="collasible-nav-dropdown" data-testid="nkp-menu">
            {props.items.map((entity) => {
                let docEndPoint = props.contentType.toLowerCase() + "/" + entity.id;
                if (entity.Url != null) {
                    return(
                        <NavDropdown.Item bg="primary" className="nkp-submenu-item" data-testid="nkp-submenu"
                            key={entity.id} href={entity.Url} target="_blank">
                            <span className="nkp-submenu-item-text">{entity.Title}</span>
                        </NavDropdown.Item>
                    )    
                } else if (entity.Link != null) {
                    return(
                        <NavDropdown.Item bg="primary" className="nkp-submenu-item" data-testid="nkp-submenu"
                            key={entity.id} as={Link} to={entity.Link}>
                            <span className="nkp-submenu-item-text">{entity.Title}</span>
                        </NavDropdown.Item>
                    )    
                } else {
                    return(
                        <NavDropdown.Item bg="primary" className="nkp-submenu-item" data-testid="nkp-submenu"
                            key={entity.id} as={Link} to={docEndPoint}>
                            <span className="nkp-submenu-item-text">{entity.Title}</span>
                        </NavDropdown.Item>
                    )    
                }
            })}
        </NavDropdown>
    );
}

export default MenuItem;