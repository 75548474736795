import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useWindowDimensions from '../hooks/useWindowDimensions';
import Query from "../components/Query";

import PRIVACY_QUERY from "../queries/privacy";

const ModalPrivacy = (props) => {
    const [visible, setVisible] = useState(props.visible);

    const { height, width } = useWindowDimensions();

    useEffect(
		() => {
			setVisible(props.visible);
		},
		[props.visible],
	);

    const handleClose = () => {
        setVisible(false);
     	props.callbackParent();
    }

    return (
        <Modal size={width <= 768 ? "md" : "lg"} show={visible} animation={false} 
            onHide={handleClose} data-testid="nkp-modal-privacy">
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
                <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Query query={PRIVACY_QUERY}>
				{({ data: { privacy } }) => {
					return(
                        <>
                        <div className="pt-3">
                            <h1>{privacy?.Title}</h1>
                        </div>
                        <div className="pt-3">
                            <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]} children={privacy?.policy} />
                        </div>
                        </>
                    );
                }}
            </Query>                        
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={handleClose} data-testid="nkp-cancel">
                   Закрыть
            </Button>
        </Modal.Footer>
        </Modal>        
    )
}

export default ModalPrivacy;