import gql from "graphql-tag";

const DISQUALIFICATIONS_QUERY = gql`
    query Disqualifications ($limit: Int, $start: Int) {
      disqualifications(limit : $limit, start : $start){
        Name
        Type
        Prefix
        City
        Final
        Reason
        Date
      }
      disqualificationsConnection {
        aggregate {
          count
        }
      }
    }
`;

export default DISQUALIFICATIONS_QUERY;