import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import PaginationPlus from '../shared/components/PaginationPlus';
import ModalAddTitles from '../shared/modals/ModalAddTitles';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
import ModalRegister from '../shared/modals/ModalRegister';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ProfileContext } from '../shared/contexts/profile-context';
import { pageType } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import TITLES_QUERY from "../shared/queries/titles";

const Genetics = (props) => {
	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState("");
	const [page, setPage] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [registerVisible, setRegisterVisible] = useState(false);
	const [profile, setProfile] = useContext(ProfileContext);

	const history = useHistory();
	const rowsPerPage = 20;

	useEffect(
		() => {
			let page;
			if (!props.match.params.page) { 
				page = 1;
			} else {
				page = props.match.params.page;
			}
			setPage(page);
		},
		[props.match.params],
	);

	const start = (page) => {
		let firstRecord = (page - 1) * rowsPerPage;
		firstRecord < 0 ? firstRecord = 0 : null;

		return firstRecord;
	}

	const goToPage = (page) => {
		history.push('/genetics/' + page);
	}

	const handleKeyDown = (e) => {
        if (e.key == 'Enter') { setFilter(search) };
    }


	return(
		<>
		<Helmet>
			<title>НКП ВКК - Список титулов собак - членов НКП</title>
			<meta name="description" content="Список титулов собак - членов НКП Вельш Корги Кардиган." />
			<meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, члены, титулы" />
			<link rel="canonical" href="https://welsh-corgi.club/#/titles" />
		</Helmet>

		<PageHeader pageType={pageType.TITLES} />

		<Container className="mt-3 mb-3">
		<div className="bg-white p-3"/*border border-primary rounded"*/>

		<Row>
			<Col className="d-flex align-items-end">
				<InputGroup className="mb-3 nkp-search-box" style={{ minWidth: '200px' }}>
					<InputGroup.Prepend>
						<InputGroup.Text>
							<FontAwesomeIcon icon={faSearch} />
						</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl
						type="text"
						placeholder="Кличка собаки"
						onChange={(e) => setSearch(e.target.value)}
						onKeyDown={handleKeyDown}
					/>
				</InputGroup>
			</Col>
			<Col className="d-flex justify-content-end align-items-end">
			<div className="d-flex mb-3">
				{profile == null ?
					<>
					<Row className="d-flex justify-content-end mb-3" style={{ marginLeft: 0, marginRight: 0 }}>
					<div style={{ textAlign: 'right', marginBottom: '1em' }}>
						<span><small><i>Зарегистрированные пользователи сайта могут добавлять информацию по собакам.</i></small></span>
					</div>
					<div className="d-flex justify-content-end">
						<Button as="input" 
								size="md"
								data-testid="nkp-button-register"
								onClick={() => setRegisterVisible(true)} 
								type="submit" 
								value="Зарегистрироваться на сайте" />
					</div>
					</Row>
					</>
					:
					<Button as="input" 
							size="md"
							data-testid="nkp-button-add"
							onClick={() => setModalVisible(true)} 
							type="submit" 
							disabled={profile == null}
							value="Добавить результаты" />
				}
				</div>
			</Col>
		</Row>

		<Query query={TITLES_QUERY} slug={filter || ""} start={start(page)} limit={rowsPerPage}>
			{({ data: { dogs, dogsConnection } }) => {
				let totalRows2 = dogsConnection.aggregate.count;
				let totalPages2 = Math.ceil(totalRows2 / rowsPerPage);
				return(
				<div className="nkp-box-shadow p-3">
					<Table striped bordered hover>
					<thead>
						<tr>
						<th className="nkp-titles-head"><small>Кличка</small></th>
						<th><small>Год рождения</small></th>
						<th><small>Титулы</small></th>
						</tr>
					</thead>
					<tbody>
						{dogs.map((dog, index) => {
							if (dog.Titles != null) {
								let dogName = dog.Name;
								if(profile != null) {
									if (dog.curators?.filter(rec => rec.email == profile.email).length > 0
										|| dog.owner?.email == profile.email
										|| profile.role.name == "Moderator") {
										dogName = <Link to={"/dog/" + dog.id}>{dog.Name}</Link>
									}
								}
								return(
									<tr key={index}>
										<td><small>{dogName}</small></td>
										<td><small>{dog.Year}</small></td>
										<td data-testid="nkp-titles"><small>{dog.Titles}</small></td>
									</tr>		
								)	
							}
						})}
					</tbody>
					</Table>

					<div className="d-flex justify-content-center">
							<PaginationPlus 
								totalRows={totalRows2}
								page={page}
								totalPages={totalPages2}
								rowsPerPage={rowsPerPage}
								goToPage={goToPage}
							/>
					</div>
				</div>
				);
			}}
		</Query>
				
		</div>
		</Container>

        <ModalAddTitles visible={modalVisible} 
			profile={profile}
            callbackParent={() => setModalVisible(false)}
		/>

		<ModalRegister visible={registerVisible} 
			callbackParent={() => setRegisterVisible(false)}
		/>

		</>
	);
}

export default Genetics;
