import React, { useState, useEffect, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import { pageType } from '../services/strapi-access-services';
import { useForm } from '../hooks/useForm';
import InputText from '../components/InputText';
import InputTextArea from '../components/InputTextArea';
import InputCheckbox from '../components/InputCheckbox';
import InputFile from '../components/InputFile';
import { inputType } from '../services/input-utils';
import { ticketType } from '../services/ticket-utils';
import { submitTicket } from '../services/tickets-api';
import { ProfileContext } from '../contexts/profile-context';
import ModalPrivacy from '../modals/ModalPrivacy';
import ModalRules from '../modals/ModalRules';
import ModalAccepted from './ModalAccepted';

const ModalAddPuppies = (props) => {
    const [visible, setVisible] = useState(false);
    const [okVisible, setOkVisible] = useState(false);
    const [rulesVisible, setRulesVisible] = useState(false);
    const [privacyVisible, setPrivacyVisible] = useState(false);

    const { promiseInProgress } = usePromiseTracker();
    const pageT = pageType.MODAL_ADD_PUPPIES;

    const { handleChange, handleChoose, handleCheck, handleSubmit, reset, errors, data } = useForm({
        validations :{
            email: {
                required: {
                    value: true,
                    message: 'Необходимо указать контактный e-mail',
                },
            },
            phone: {
                required: {
                    value: true,
                    message: 'Необходимо указать контактный телефон',
                },
            },
            puppies: {
                required: {
                    value: true,
                    message: 'Вы не написали текст объявления',
                },
            },
            consentPrivacy: {
                required: {
                    value: true,
                    message: 'Чтобы опубликовать объявление необходимо согласиться с политикой.',
                },
            },
            consentRules: {
                required: {
                    value: true,
                    message: 'Чтобы опубликовать объявление необходимо согласиться с правилами публикации объявлений.',
                },
            },
        },
        onSubmit: () => {
            trackPromise(
                submitTicket(ticketType.PUPPIES, data, profile)
                .then(() => {
                    setOkVisible(true);
                    setVisible(false);
                })
            )
        },
    });

    const [profile, setProfile] = useContext(ProfileContext);

    useEffect(
		() => {
			setVisible(props.visible);
		},
        [props.visible],
	);

    const handleCloseOk = () => {
        setOkVisible(false);
        handleClose();
    }

    const handleClose = () => {
        setVisible(false);
     	props.callbackParent();
        reset();
    }

    const handleCloseRules = () => {
        setVisible(true);
        setRulesVisible(false);
    }

    const handleReadRules = (e) => {
        e.preventDefault();
        setVisible(false);
        setRulesVisible(true);
    }

    const handleReadPrivacy = (e) => {
        e.preventDefault();
        setVisible(false);
        setPrivacyVisible(true);
    }

    const handleClosePrivacy = () => {
        setVisible(true);
        setPrivacyVisible(false);
    }
       
    return (
        <>
	      <Modal show={visible} animation={true} onHide={handleClose} data-testid="nkp-puppies-add">
          <form onSubmit={handleSubmit}>
	        <Modal.Header data-testid="nkp-close-button" closeButton>
	        </Modal.Header>
	        <Modal.Body>
                <Row className="align-items-center">
                    <Col lg={5} md={5} className="align-middle">
                        <Image src={pageT.image} className="modal-image" />
                    </Col>
                    <Col lg={7} md={7} sm={12}>
                        <h1>{pageT.title}</h1>

                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <div>
                        <Row>
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} type={inputType.TITLE} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputTextArea data={data} errors={errors} handleChange={handleChange} rows={3} 
                                    type={inputType.PUPPIES} datatestid="nkp-puppies-text" />
                            </Col>                    
                        </Row>

                        <Row>
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} 
                                    type={inputType.USER_EMAIL} datatestid="nkp-email" />
                            </Col>
                            <Col>
                                <InputText data={data} errors={errors} handleChange={handleChange} 
                                    type={inputType.PHONE} datatestid="nkp-phone" />
                            </Col>
                        </Row>

                        <Row className="px-3">
                            <Form.File id="formcheck-api-regular">
                            <Form.File.Label>
                                <div>
                                    <small>
                                        Вы можете добавить фото к тексту объявления.
                                    </small>
                                </div>
                            </Form.File.Label>
                                <div>
                                    <InputFile data={data} errors={errors} handleChoose={handleChoose} type={inputType.FILE} 
                                                inputId="file-input-group" inputTitle="Загрузите файл" />
                                </div>
                            </Form.File>
                        </Row>
                        <Row>
                            <Col>
                                <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} 
                                    type={inputType.CONSENT_RULES} label={(<>Я согласен 
                                    с <a href="" onClick={handleReadRules} data-testid="nkp-rules-text"> 
                                        правилами публикации объявлений
                                    </a></>)} datatestid="nkp-rules-ok" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} 
                                    type={inputType.CONSENT_PRIVACY} label={(<>Я ознакомился и согласен 
                                    с <a href="" onClick={handleReadPrivacy} data-testid="nkp-privacy-text">
                                        политикой в отношении обработки персональных данных
                                    </a></>)} datatestid="nkp-privacy-ok" />
                            </Col>
                        </Row>
                        </div>
                        </div>
                    </Col>
                </Row>
	        </Modal.Body>
	        <Modal.Footer>
            <Button variant="success" type="submit" 
                    data-testid="nkp-submit" disabled={promiseInProgress}>
	                Добавить
               </Button>
               <Button variant="danger" onClick={handleClose} 
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Отмена
               </Button>
	        </Modal.Footer>
            </form>
	      </Modal>

            <ModalRules style={{ zIndex: 1500 }} visible={rulesVisible} 
                callbackParent={() => handleCloseRules()}
            />

            <ModalPrivacy style={{ zIndex: 1500 }} visible={privacyVisible} 
                callbackParent={() => handleClosePrivacy()}
            />

            <ModalAccepted visible={okVisible} callbackParent={() => handleCloseOk()} />
        </>
	  );
}

export default ModalAddPuppies;