import React, { useState, useEffect, useContext } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from "react-bootstrap/Form";
import Image from 'react-bootstrap/Image';
import useWindowDimensions from '../../shared/hooks/useWindowDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { pageType } from '../services/strapi-access-services';
import { getFormUrl } from '../services/form-access-services';
import { useForm } from '../hooks/useForm';
import InputText from '../components/InputText';
import InputCheckbox from '../components/InputCheckbox';
import InputDropdown from '../components/InputDropdown';
import InputFile from '../components/InputFile';
import { inputType } from '../services/input-utils';
import { ticketType } from '../services/ticket-utils';
import { submitTicket } from '../services/tickets-api';
import { ProfileContext } from '../contexts/profile-context';
import ModalPrivacy from '../modals/ModalPrivacy';
import ModalAccepted from './ModalAccepted';
import Query from "../components/Query";

import LAST_TICKET_QUERY from "../queries/ticket-last";

const ModalPromote = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [formVisible, setFormVisible] = useState(false);
    const { height, width } = useWindowDimensions();
    const [privacyVisible, setPrivacyVisible] = useState(false);
    const [okVisible, setOkVisible] = useState(false);

	const { promiseInProgress } = usePromiseTracker();
    const [profile, setProfile] = useContext(ProfileContext);
    const pageT = pageType.MODAL_PROMOTE;

    const { handleChange, handleChoose, handleCheck, handleSubmit, reset, errors, data } = useForm({
        validations :{
            memberType: {
                required: {
                    value: true,
                    message: 'Пожалуйста выберите подходящий вариант',
                },
            },
            memberName: {
                combinationRequired: {
                    isValid: (data, value) => (data.memberType != "Владелец" && value) || data.memberType == "Владелец",
                    message: 'Необходимо указать название',
                },
            },
            jurAddress: {
                combinationRequired: {
                    isValid: (data, value) => (data.memberType == "Клуб" && value) || data.memberType != "Клуб" ,
                    message: 'Необходимо указать юридический адрес',
                },
            },
            dogName: {
                combinationRequired: {
                    isValid: (data, value) => ((data.chooseDog == 0 && value) || data.chooseDog > 0) && data.memberType != "Клуб"
                            || data.memberType == "Клуб",
                    message: 'Необходимо указать кличку',
                },
            },
            dogYear: {
                combinationRequired: {
                    isValid: (data, value) => ((data.chooseDog == 0 && value) || data.chooseDog > 0) && data.memberType != "Клуб"
                            || data.memberType == "Клуб",
                    message: 'Необходимо указать год рождения',
                },
                combinationRequired: {
                    isValid: (data, value) => ((data.chooseDog == 0 && value) || data.chooseDog > 0) && data.memberType != "Клуб"
                            && parseInt(value, 10) > 1975 && parseInt(value, 10) < 2100
                            || data.memberType == "Клуб",
                    message: 'Похоже, ошибка в годе рождения - должен быть между 1975 и 2100',
                },
            },
            managerName: {
                combinationRequired: {
                    isValid: (data, value) => (data.memberType == "Клуб" && value) || data.memberType != "Клуб" ,
                    message: 'Необходимо указать Ф.И.О.',
                },
            },
            address: {
                required: {
                    value: true,
                    message: 'Необходимо указать почтовый адрес',
                },
            },
            chooseDog: {
                combinationRequired: {
                    isValid: (data, value) => value && data.memberType != "Клуб"
                        || !value && data.dogName != "" || data.memberType == "Клуб",
                    message: 'Необходимо выбрать собаку из списка или указать данные новой собаки',
                },
            },
            memberEmail: {
                required: {
                    value: true,
                    message: 'Необходимо указать адрес электронной почты',
                },
            },
            phone: {
                required: {
                    value: true,
                    message: 'Необходимо указать контактный телефон',
                },
            },
            breed: {
                combinationRequired: {
                    isValid: (data, value) => data.memberType != "Клуб" && value || data.memberType == "Клуб",
                    message: 'Необходимо указать номер',
                },
            },
            prefix: {
                combinationRequired: {
                    isValid: (data, value) => data.memberType == "Питомник" && value || data.memberType != "Питомник",
                    message: 'Необходимо указать код клейма',
                },
            },
            chiefName: {
                combinationRequired: {
                    isValid: (data, value) => (data.memberType == "Клуб" && value) || data.memberType != "Клуб",
                    message: 'Необходимо указать Ф.И.О. руководителя',
                },
            },
            name: {
                combinationRequired: {
                    isValid: (data, value) => data.memberType == "Владелец" && value || data.memberType != "Владелец",
                    message: 'Необходимо указать Ф.И.О.',
                },
            },
            birthday: {
                combinationRequired: {
                    isValid: (data, value) => data.memberType == "Владелец" && value || data.memberType != "Владелец",
                    message: 'Необходимо указать дату рождения',
                },
            },
            files: {
                custom: {
                    isValid: (value, data) => (data.consentDocuments == true && data.memberType != "Клуб" && value.length >= 0)
                        || value.length > 0,
                    message: 'Необходимо предоставить подтверждающие документы',
                },
            },
            consentPrivacy: {
                required: {
                    value: true,
                    message: 'Чтобы зарегистрироваться необходимо согласиться с политикой.',
                },
            },
            nkpSupport1: {
                custom: {
                    isValid: (value, data) => (data.memberType == "Клуб" ||
                        (data.nkpSupport1 == true || 
                        data.nkpSupport2 == true || 
                        data.nkpSupport3 == true || 
                        data.nkpSupport4 == true || 
                        (data.nkpSupport5 == true && (data.supportOther != "" && data.supportOther != null && data.supportOther !== undefined)))),
                    message: 'Пожалуйста выберите хотя бы один вариант.',
                },
            },
            nkpSupport2: {
                custom: {
                    isValid: (value, data) => (data.memberType == "Клуб" ||
                        (data.nkpSupport1 == true || 
                        data.nkpSupport2 == true || 
                        data.nkpSupport3 == true || 
                        data.nkpSupport4 == true || 
                        (data.nkpSupport5 == true && (data.supportOther != "" && data.supportOther != null && data.supportOther !== undefined)))),
                    message: 'Пожалуйста выберите хотя бы один вариант.',
                },
            },
            nkpSupport3: {
                custom: {
                    isValid: (value, data) => (data.memberType == "Клуб" ||
                        (data.nkpSupport1 == true || 
                        data.nkpSupport2 == true || 
                        data.nkpSupport3 == true || 
                        data.nkpSupport4 == true || 
                        (data.nkpSupport5 == true && (data.supportOther != "" && data.supportOther != null && data.supportOther !== undefined)))),
                    message: 'Пожалуйста выберите хотя бы один вариант.',
                },
            },
            nkpSupport4: {
                custom: {
                    isValid: (value, data) => (data.memberType == "Клуб" ||
                        (data.nkpSupport1 == true || 
                        data.nkpSupport2 == true || 
                        data.nkpSupport3 == true || 
                        data.nkpSupport4 == true || 
                        (data.nkpSupport5 == true && (data.supportOther != "" && data.supportOther != null && data.supportOther !== undefined)))),
                    message: 'Пожалуйста выберите хотя бы один вариант.',
                },
            },
            nkpSupport5: {
                custom: {
                    isValid: (value, data) => (data.memberType == "Клуб" ||
                        (data.nkpSupport1 == true || 
                        data.nkpSupport2 == true || 
                        data.nkpSupport3 == true || 
                        data.nkpSupport4 == true || 
                        (data.nkpSupport5 == true && (data.supportOther != "" && data.supportOther != null && data.supportOther !== undefined)))),
                    message: 'Пожалуйста выберите хотя бы один вариант.',
                },
            },
            supportOther: {
                custom: {
                    isValid: (value, data) => (data.memberType == "Клуб" ||
                        (data.nkpSupport1 == true || 
                        data.nkpSupport2 == true || 
                        data.nkpSupport3 == true || 
                        data.nkpSupport4 == true || 
                        (data.nkpSupport5 == true && (data.supportOther != "" && data.supportOther != null && data.supportOther !== undefined)))),
                    message: 'Пожалуйста выберите хотя бы один вариант.',
                },
            },
        },
        onSubmit: () => {
            trackPromise(
                submitTicket(ticketType.PROMOTE, data, profile)
                .then(() => {
                    if (data.id != null) {
                        setOkVisible(true);
                        setVisible(false);    
                    } else {
                        refresh();
                    }
                })
            )
        },

    });

    const memberTypes = [
        {id: "Клуб", name: "Клуб"},
        {id: "Владелец", name: "Владелец"},
        {id: "Питомник", name: "Питомник"},
        {id: "Приставка", name: "Приставка"}
    ]

    useEffect(
		() => {
			setVisible(props.visible);
		},
		[props.visible],
	);

    const handleReadPrivacy = (e) => {
        e.preventDefault();
        setVisible(false);
        setPrivacyVisible(true);
    }

    const handleClosePrivacy = () => {
        setVisible(true);
        setPrivacyVisible(false);
    }

    const handleCloseOk = () => {
        setOkVisible(false);
        handleClose();
    }

    const handleClose = () => {
        setVisible(false);
        setFormVisible(false);
        props.callbackParent();
        reset();
    }

    return (
        <>
        <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} 
            data-testid="nkp-modal-promote" onHide={handleClose}>
        <form onSubmit={handleSubmit}>
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Query query={LAST_TICKET_QUERY} slug={profile?.id}>
            {({ data: { tickets, dogs } }) => {
            let ticket;
            let newDogFlag;

            if (dogs.length == 0) {
                promoteData.chooseDog = 0;
            }
 
            let dogOps = [];
            dogs.map((dog) => {
                dogOps.push(dog.Name + " (" + dog.Year + ")");
            })

            if (tickets.length > 0) {
                ticket = tickets[0];
                data.id = ticket.id;
                data.oldfiles = [];
                var content = ticket.TicketContent;
                for (const index in content) {
                    let value = content[index].Value;
                    let key = content[index].Key;
                    if (key == "consentPrivacy" || key == "consentDocuments" || key == "nkpSupport1" ||
                    key == "nkpSupport2" || key == "nkpSupport3" || key == "nkpSupport4" || key == "nkpSupport5") {
                        value = (ticket.TicketContent[index].Value.toString().toLowerCase() === 'true');
                    }
                    data[key] = value;
                }
                if(ticket.Attachment.length > 0) {
                    ticket.Attachment.map((attach) => {
                        data.oldfiles.push(attach.id);
                    })
                }
                if (ticket.dog != null) {
                    data.chooseDog = ticket.dog.id.toString();
                } else {
                    data.chooseDog = "0";
                }
                if (data.chooseDog == "0" && data.memberType != "Клуб") {
                    dogOps.push(content["DogName"] + " (" + content["DogYear"] + ")");
                    newDogFlag = true;
                }
            }

            return (
            <Row className="align-items-center">
                <Col lg={4} md={4} className="align-middle p-3">
                    <Image src={pageT.image} className="modal-image" />
                </Col>
                <Col lg={8} md={8} sm={12} className="px-4">
                    <h1>{pageT.title}</h1>

            {(tickets.length == 0 || formVisible == true) ?
            <>
            <div className="mb-3">
                <div><small>Для регистрации в НКП вам необходимо:</small></div>

                <div><small>
                    <ul>
                        <li>Заполнить данную форму</li>
                        <li>Распечатать заявление (оно будет создано автоматически) и подписать его</li>
                        <li>Загрузить скан или фото заявления на сайт или выслать его в бумажном виде в НКП</li>
                    </ul>
                </small></div>

                <div><small>Модератор подтверждает заявку на вступление в НКП только после получения подписанного заявления.</small></div>
            </div>

            <Row>
                <InputDropdown data={memberTypes} errors={errors} handleChoose={handleChoose} datatestid="nkp-mem-type"
                    name="name" current={data.memberType ? {name: data.memberType} : null} type={inputType.MEMBER_TYPE}/>
            </Row>

            {data.memberType == "Клуб" ?
            <>
            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        datatestid="nkp-mem-name" type={inputType.MEMBER_NAME} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        datatestid="nkp-mem-jur" type={inputType.LEGAL_ADDRESS} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        datatestid="nkp-manager" type={inputType.MANAGER_NAME} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        datatestid="nkp-address" type={inputType.ADDRESS} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        datatestid="nkp-email" type={inputType.MEMBER_EMAIL} />
                </Col>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        datatestid="nkp-phone" type={inputType.PHONE} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        datatestid="nkp-chief" type={inputType.CHIEF_NAME} />
                </Col>
            </Row>

            <Row className="pt-3">
                <Col>
                <Form.File.Label>
                    <div>
                        <div><small>Загрузите сканы или фото документов:</small></div>

                        <div><small>
                            <ul>
                                <li>Свидетельство о внесении записи в Единый государственный реестр юридических лиц (ЕГРЮЛ).</li>
                                <li>Свидетельство о регистрации в Налоговой инспекции с кодами ИНН и КПП.</li>
                                <li>Копии родословных собак породы вельш корги кардиган членов клуба.</li>
                            </ul>
                        </small></div>
                    </div>  
                </Form.File.Label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputFile data={data} errors={errors} handleChoose={handleChoose} type={inputType.FILE} 
                        inputId="file-input-group" inputTitle="Загрузите файл" datatestid="nkp-file" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} 
                        type={inputType.CONSENT_PRIVACY} datatestid="nkp-privacy-ok" label={(<>Я ознакомился 
                        и согласен с <a href="" onClick={handleReadPrivacy} data-testid="nkp-privacy-text">политикой 
                        в отношении обработки персональных данных</a></>)} />
                </Col>
            </Row>

            </>
            :
            <>
            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        type={inputType.USER_NAME} datatestid="nkp-name" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        type={inputType.USER_BIRTHDAY} datatestid="nkp-birthday" />
                </Col>
            </Row>

            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        type={inputType.ADDRESS} datatestid="nkp-address" />
                </Col>
            </Row>

            <Row>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        type={inputType.MEMBER_EMAIL} datatestid="nkp-email" />
                </Col>
                <Col>
                    <InputText data={data} errors={errors} handleChange={handleChange} 
                        type={inputType.PHONE} datatestid="nkp-phone" />
                </Col>
            </Row>
            {data.memberType == "Питомник" ?
                <>
                <Row>
                    <Col>
                        <InputText data={data} errors={errors} handleChange={handleChange} 
                            type={inputType.MEMBER_NAME} datatestid="nkp-mem-name" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputText data={data} errors={errors} handleChange={handleChange} 
                            type={inputType.PREFIX} datatestid="nkp-prefix" />
                    </Col>
                </Row>
                </>
                :
                data.memberType == "Приставка" ?
                <Row>
                    <Col>
                        <InputText data={data} errors={errors} handleChange={handleChange} 
                            type={inputType.MEMBER_NAME} datatestid="nkp-mem-name" />
                    </Col>
                </Row>
                :  null
            }

            <Row className="pt-3">
                <Col>        
                    <Form.Label>
                        <small>Для регистрации необходимо указать подробные данные хотя бы одной вашей собаки.</small>
                    </Form.Label>
                    {dogs.length > 0 ? 
                    <>
                    <Form.Label className="pb-3">
                        <small>В настоящее время вы уже разместили на сайте информацию, вы можете уточнить 
                        информацию или загрузить информацию о новой собаке.  </small>
                    </Form.Label>
                    <InputDropdown data={dogs} errors={errors} handleChoose={handleChoose} datatestid="nkp-choose-dog"
                        current={data.chooseDog == "0" || tickets.length == 0 || ticket?.dog == null ? null : {Name: ticket?.dog?.Name}} newFlag={newDogFlag} 
                        name="Name" type={inputType.DOG_CHOOSE} newItemName="Добавить собаку" newItem />
                    </>
                    : null }
                </Col>
            </Row>
            {(dogs.length > 0 && data.chooseDog == 0) || dogs.length == 0 ?
                    <>
                    <Row>
                        <Col>
                            <InputText data={data} errors={errors} handleChange={handleChange} 
                                type={inputType.DOG_NAME} datatestid="nkp-dog-name" />
                        </Col>
                        <Col>
                            <InputText data={data} errors={errors} handleChange={handleChange} 
                                type={inputType.DOG_YEAR} datatestid="nkp-dog-year" />
                        </Col>
                    </Row>
                    </>
                    : null}

                    <Row>
                        <Col>
                            <InputText data={data} errors={errors} handleChange={handleChange} 
                                type={inputType.BREED} datatestid="nkp-breed" />
                        </Col>
                        <Col>
                            <InputText data={data} errors={errors} handleChange={handleChange} type={inputType.STAMP} />
                        </Col>
                        <Col>
                            <InputText data={data} errors={errors} handleChange={handleChange} type={inputType.CHIP} />
                        </Col>
                    </Row>

            <Row className="pt-3">
            <Col>
                {data.memberType == "Приставка" ?
                <>
                <div>
                    <small>Загрузите фото или скан свидетельства о регистрации приставки.</small>
                </div>                
                <div>
                    <small>Если вы ранее этого не делали, загрузите скан или фото родословной.</small>
                </div>                
                </>
                : data.memberType == "Питомник" ?
                <>
                <div>
                    <small>Загрузите фото или скан свидетельства о регистрации питомника.</small>
                </div>                
                <div>
                    <small>Если вы ранее этого не делали, загрузите скан или фото родословной.</small>
                </div>                
                </>
                : data.memberType == "Владелец" ?
                <div>
                    <small>Если вы ранее этого не делали, загрузите скан или фото родословной.</small>
                </div>                
                : null}
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputFile data={data} errors={errors} handleChoose={handleChoose} type={inputType.FILE} 
                        inputId="file-input-group" inputTitle="Загрузите файл" datatestid="nkp-file" />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} datatestid="nkp-consent-docs"
                        type={inputType.CONSENT_DOCUMENTS} label={(<>Я уже загрузил скан / фото родословной.</>)} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className={errors.nkpSupport1 ? "nkp-textarea-invalid p-3" : "nkp-textarea p-3"}>
                    <div>
                        <span  className={errors.nkpSupport1 && "text-danger"}>
                            Какой вклад в деятельность НКП вы готовы внести:
                        </span>
                    </div>
                    <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} datatestid="nkp-support1"
                        type={inputType.SUPPORT1} label={(<><small>Финансовая поддержка.</small></>)} 
                        id="nkp-support" warnings={false} />
                    <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} datatestid="nkp-support2"
                        type={inputType.SUPPORT2} label={(<><small>Предоставление призов на монопородные
                        выставки и соревнования.</small></>)} id="nkp-support" warnings={false} />
                    <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} datatestid="nkp-support3"
                        type={inputType.SUPPORT3} label={(<><small>Помощь в организации Чемпионата породы
                        (найти спонсоров, привезти / отвезти инвентарь, встретить / отвезти экспертов и т.д.).</small></>)} 
                        id="nkp-support" warnings={false} />
                    <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} datatestid="nkp-support4"
                        type={inputType.SUPPORT4} label={(<><small>Производство и размещение рекламы НКП
                        и монопородных выставок на дрессировочных площадках и площвдках для выгула собак в вашем 
                        регионе.</small></>)} id="nkp-support" warnings={false} />
                    <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} datatestid="nkp-support5"
                        type={inputType.SUPPORT5} label={(<><small>Другое (укажите свой вариант).</small></>)} 
                        id="nkp-support" warnings={false} />
                    <InputText data={data} errors={errors} handleChange={handleChange} type={inputType.SUPPORT_OTHER} small />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputCheckbox data={data} errors={errors} handleCheck={handleCheck} 
                        type={inputType.CONSENT_PRIVACY} datatestid="nkp-privacy-ok" label={(<>Я ознакомился 
                        и согласен с <a href="" onClick={handleReadPrivacy} data-testid="nkp-privacy-text">политикой 
                        в отношении обработки персональных данных</a></>)} />
                </Col>
            </Row>
            </>
            }
            </>
            : 
            <>
                <div>
                    <small>Вы отправили заявку. Для завершения регистрации пожалуйста скачайте заявление, 
                    распечатайте, подпишите его на бумаге и загрузите на сайт.</small>
                </div>
                <div className="mt-3">
                <a href={getFormUrl(ticket.id)}>
                    <FontAwesomeIcon icon={faFilePdf} /> Скачать заявление в формате Adobe Acrobat (PDF)
                </a>
                </div>
                <div>
                    <InputFile data={data} errors={errors} handleChoose={handleChoose} type={inputType.FILE} 
                        inputId="file-input-group" inputTitle="Загрузите файл" datatestid="nkp-file"/>
                </div>

                <div className="mt-3">
                    <small>Если вы заметили ошибку в отправленной заявке, вы можете ее отредактировать.</small>
                </div>
                <Button variant="primary"  className="mt-3" 
                    onClick={() => setFormVisible(true)} data-testid="nkp-edit">
                    Отредактировать
                </Button>
            </>
            } 
            </Col>
            </Row>
            )
            }}
            </Query>                   
	        </Modal.Body>
	        <Modal.Footer>
                <Button variant="success" type="submit" 
                    data-testid="nkp-submit" disabled={promiseInProgress}>
                    Отправить
               </Button>
               <Button variant="danger" onClick={handleClose} 
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Отмена
               </Button>
	        </Modal.Footer>
           </form>
        </Modal>

        <ModalPrivacy style={{ zIndex: 1500 }} visible={privacyVisible} 
            callbackParent={() => handleClosePrivacy()}
        />

        <ModalAccepted visible={okVisible} callbackParent={() => handleCloseOk()} />
    </>
    );
}

export default ModalPromote;