import gql from "graphql-tag";

const EXPO_SINGLE_QUERY = gql`
query Expos($slug:ID!) {
    expo(id: $slug) {
            Date
            Content
            Title
        }
    }  
`;

export default EXPO_SINGLE_QUERY;