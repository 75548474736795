import React from 'react';
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputText = (props) => {
    return (
        <>
            <InputGroup className={props.errors[props.type.key] ? "nkp-textarea-invalid" : "nkp-textarea"}
                        style={{ borderRadius: 15 }}>
            <InputGroup.Prepend className="nkp-textarea-prepend">
                <InputGroup.Text>
                    {props.type.icon != null ? <FontAwesomeIcon icon={props.type.icon} /> : null}
                </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                as="textarea"
                rows={props.rows}
                data-testid={props.datatestid}
                placeholder={props.type.placeholder}
                value={props.data[props.type.key] || ''}
                onChange={props.handleChange(props.type.key)}
                isInvalid={props.errors[props.type.key]}
            />
            </InputGroup>
            {props.errors[props.type.key] ?
                <Form.Text className="text-danger">
                    {props.errors[props.type.key]}
                </Form.Text>
            : null}
        </>
    )
}

export default InputText;