import gql from "graphql-tag";

const EXPERTS_QUERY = gql`
    query Experts {
        experts (sort: "Country:asc, Name:asc"){
            Name
            Kennel
            Limitation
            Country
            Breeder
        }
    }
`;

export default EXPERTS_QUERY;