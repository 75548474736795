import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useWindowDimensions from '../hooks/useWindowDimensions';
import { trackPromise } from 'react-promise-tracker';
import { contentType, getContentObject, getMediaUrl } from '../services/strapi-access-services';

const ModalRules = (props) => {
    const [rules, setRules] = useState(null);
    const [visible, setVisible] = useState(props.visible);
    const [error, setError] = useState(null);

    const { height, width } = useWindowDimensions();

    useEffect(
		() => {
			setVisible(props.visible);
		},
		[props.visible],
	);

    useEffect(
        () => {
            refresh();
        },
        [visible],
    );

    const refresh = () => {
        trackPromise(
            getContentObject(contentType.RULES)
            .then((rules) => {
                if (!rules.error) {
                    setRules(rules);
                } else {
                    setError(rules.error);
                }
            })
        );    
    }

    const handleClose = () => {
        setVisible(false);
     	props.callbackParent();
    }

    return (
        <Modal size={width <= 768 ? "md" : "lg"} show={visible} animation={false} 
            data-testid="nkp-modal-rules" onHide={handleClose}>
        <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
                <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {error != null ?
                <h4 style={{ color : "red" }}>Что-то пошло не так. Мы обязательно разберемся и исправим.</h4>
            :
                <>
                <div className="pt-3">
                    <h1>{rules?.Title}</h1>
                </div>
                <div className="pt-3">
                    <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]} children={rules?.RulesText} />
                </div>
                </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={handleClose} data-testid="nkp-cancel">
                   Закрыть
            </Button>
        </Modal.Footer>
        </Modal>        
    )
}

export default ModalRules;