import gql from "graphql-tag";

const DOGS_QUERY = gql`
    query Dogs ($slug: Int!) {
        dogs (sort:"Name:asc", where: { _or : [{owner : {id : $slug}} , {curators : {id : $slug}}] }) {
            id
            Name
            Year
        }
    }
`;

export default DOGS_QUERY;