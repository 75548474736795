import gql from "graphql-tag";

const HEADERS_QUERY = gql`
query Headers {
    articles (sort: "Order:asc") {
      id
      Title
      Url
      Link    
    }
    documents (sort: "Order:asc"){
      id
      Title
      Url
      Link
    }
    breedings (sort: "Order:asc"){
      id
      Title
      Url
      Link
    }
    expos (sort: "Order:asc") {
      id
      Title
      Url
      Link
    }
  }
`;

export default HEADERS_QUERY;