import gql from "graphql-tag";

const AGILITY_QUERY = gql`
    query Genetics ($slug: String!, $limit: Int, $start: Int ){
            dogs(sort:"Name:asc", start: $start, limit: $limit, where : {sports_results_agility_null : false, Name_contains :$slug}){
                id
                Name
                Year
                sports_results_agility {
                A1
                A2
                A3
                }
            },
        dogsConnection(where : {sports_results_agility_null : false, Name_contains :$slug}) {
            aggregate {
            count
            }
        }
    }
`;

export default AGILITY_QUERY;