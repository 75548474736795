import gql from "graphql-tag";

const DOG_PROFILE_QUERY = gql`
    query Dog ($slug:ID!) {
        dog (id : $slug) {
            id
            Name
            Year
            Champion
            Titles
            Chip
            Stamp
            Breed
            owner {
                id
                name
                email
            }
            curators {
                id
                name
                email
            }
            genetic {
                PRA
                DM
                vWD
                Pink
                Deljut
                Chocolate
                Fluffy
                Passport
                Laboratory
            }
            dysplasia {
                ED
                HD
                Doctor
            }
            sports_results_okd {
                Rank
                Temperament
                Judge
            }
            sports_results_herding {
                Rank1Class
                Rank2Class
                Rank3Class
                TPI
                Test
                Title
            }
            sports_results_agility {
                A1
                A2
                A3
            }
            DocGeneral {
                url
                name
            }
        }
    }
`;

export default DOG_PROFILE_QUERY;