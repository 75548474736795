import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { usePromiseTracker } from "react-promise-tracker";
import useWindowDimensions from '../../shared/hooks/useWindowDimensions';
import { TicketCard } from '../../modules/TicketCard';
import { pageType } from '../services/strapi-access-services';

const ModalTicket = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [ticketCard, setTicketCard] = useState(null);

	const { promiseInProgress } = usePromiseTracker();
    const { height, width } = useWindowDimensions();
    const pageT = pageType.MODAL_TICKET;

    useEffect(
		() => {
			setVisible(props.visible);
            setTicketCard(props.ticket);
		},
		[props.ticket, props.visible],
	);

    const handleClose = () => {
        setVisible(false);
     	props.callbackParent();
    }

    const handleConfirm = () => {
        props.handleConfirm(ticketCard, props.refetch);
        handleClose();
    }

    const handleReject = () => {
        props.handleReject(ticketCard, props.refetch);
        handleClose();
    }

    return (
            <Modal size={width <= 768 ? "sm" : "lg"} show={visible} animation={false} 
                    onHide={handleClose} data-testid='nkp-modal-ticket'>
            <Modal.Header className="bg-white" data-testid="nkp-close-button" closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                {ticketCard != null &&
                <Row className="align-items-center">
                <Col lg={5} md={5} className="align-middle p-3">
                    <Image src={pageT.image} className="modal-image" />
                </Col>
                <Col lg={7} md={7} sm={12} className="p-2">
                    <h1>Заявка № {ticketCard.id}</h1>

                    <div className="d-flex align-items-center">
                        <div>
                        <Row>
                            <Col>
                            <TicketCard ticket={ticketCard} 
                                        handleConfirm={handleConfirm} 
                                        handleReject={handleReject} />
                            </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                </Row>}
                </>
	        </Modal.Body>
	        <Modal.Footer>
            <Button size="sm" className="btn-success mx-2" onClick={(e) => {handleConfirm(ticketCard)}}
                    data-testid="nkp-submit" disabled={promiseInProgress}>
	                Подтвердить
               </Button>
               <Button size="sm" className="btn-danger mx-2" onClick={(e) => {handleReject(ticketCard)}}
                    data-testid="nkp-cancel" disabled={promiseInProgress}>
	                Отклонить
               </Button>
	        </Modal.Footer>
	      </Modal>
	  );
}

export default ModalTicket;