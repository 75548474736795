import { contentType, putContentObject } from '../strapi-access-services';

const prolongMethod = (ticket) => {
    return Promise.resolve()
    .then(() => {
        return putContentObject (contentType.PUPPIES, ticket.puppiesId, { published_at : Date.now() }, true)
    })
    .then((result) => {
        if (!result.ok) throw result;
        return putContentObject(contentType.TICKET, ticket.id, { Status : "Approved" }, true);
    })
    .catch((error) => {
        return { error : "Other error", message : error };
    });
}

export default prolongMethod;