import gql from "graphql-tag";

const HERDING_QUERY = gql`
    query Herding ($slug: String!, $limit: Int, $start: Int ){
        dogs(sort:"Name:asc", start: $start, limit: $limit, where : {sports_results_herding_null : false, Name_contains :$slug}){
            id
            Name
            Year
            sports_results_herding {
                TPI
                Test
                Title
                Rank1Class
                Rank2Class
                Rank3Class
            }
        },
        dogsConnection(where : {sports_results_herding_null : false, Name_contains :$slug}) {
            aggregate {
            count
            }
        }
    }
`;

export default HERDING_QUERY;