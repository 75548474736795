import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getMediaUrl } from '../shared/services/strapi-access-services';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';

const GalleryRow = (props) => {
    const [show, setShow] = useState(null);

    const row = props.row;

    const mouseEnter = (e) => {
		setShow(e.target.id);
	}
	
	const mouseLeave = (e) => {
		setShow(null);
	}

    const checkVisible = (id) => {
		if (id == show) {
			return false;
		} else {
			return true;
		}
	}

    return (
        <Row className="my-2" >
            {row.map((pic) => {
                return (
                    <Col key={pic.id} ms={3} className="mx-2" className="d-flex align-items-center justify-content-center" >
                        <div data-testid="nkp-photo-div">
                        <Image src={getMediaUrl(pic?.formats?.thumbnail)} 
                            className="border border-primary rounded" fluid 
                            id={pic.id} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={props.handleShowPhoto} />
                            <div style={{ opacity: 0.5, position: "absolute", 
                                          top: "50%", left: "50%", marginTop: "-25px", marginLeft: "-25px" }} 
                                          hidden={checkVisible(pic.id)} >
                                <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: '50px', color: "white" }} 
                                    id={pic.id} onClick={props.handleShowPhoto} />
                            </div>
                        </div>
                    </Col>
                )
            })}
        </Row>
    )
}    

export default GalleryRow;