import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';

const PaginationPlus = (props) => {
	const history = useHistory();

    const totalRows= props.totalRows;
	const page = props.page;
	const totalPages = props.totalPages;
    const rowsPerPage = props.rowsPerPage;

    let pages = [];

    const goToPage = (page) => {
		props.goToPage(page);
	}

    let prevPage = parseInt(page) - 1;
    let nextPage = parseInt(page) + 1;

    if (totalPages <= 9) {
        for (var i = 1; i <= totalPages; i++) {
            pages.push(i)
        }

        return (
            <Pagination >
            <Pagination.First data-testid="nkp-pagination-first" disabled={page == 1} onClick={() => goToPage(1)}/>
            <Pagination.Prev data-testid="nkp-pagination-prev" disabled={page == 1} onClick={() => goToPage(prevPage)} />
            {pages.map((val, index) => {
                return (
                    <Pagination.Item 
                        key={index} 
                        onClick={() => goToPage(val)}
                        data-testid="nkp-pagination"
                        disabled={val == page}
                    >
                        {val}
                    </Pagination.Item>
                )
            })}
            <Pagination.Next data-testid="nkp-pagination-next" disabled={page == totalPages} onClick={() => goToPage(nextPage)} />
            <Pagination.Last data-testid="nkp-pagination-last" disabled={page == totalPages} onClick={() => goToPage(totalPages)} />
            </Pagination>
        )    
    } else {
        if ( page <= 6 ) {
            for (var i = 1; i <= 7; i++) {
                pages.push(i)
            }
    
            return (
                <Pagination >
                <Pagination.First disabled={page == 1} onClick={() => goToPage(1)}/>
                <Pagination.Prev disabled={page == 1} onClick={() => goToPage(prevPage)} />

                {pages.map((val, index) => {
                    return (
                        <Pagination.Item 
                            key={index} 
                            onClick={() => goToPage(val)}
                            disabled={val == page}
                        >
                            {val}
                        </Pagination.Item>
                    )
                })}
 
                <Pagination.Ellipsis />
                <Pagination.Item onClick={() => goToPage(totalPages)}>{totalPages}</Pagination.Item>
                <Pagination.Next disabled={page == totalPages} onClick={() => goToPage(nextPage)} />
                <Pagination.Last disabled={page == totalPages} onClick={() => goToPage(totalPages)} />
                </Pagination>
            )        
        } else if (page >= totalPages - 5) {
            for (var i = totalPages - 6; i <= totalPages; i++) {
                pages.push(i)
            }

            return (
                <Pagination >
                <Pagination.First disabled={page == 1} onClick={() => goToPage(1)}/>
                <Pagination.Prev disabled={page == 1} onClick={() => goToPage(prevPage)} />
                <Pagination.Item onClick={() => goToPage(1)}>{1}</Pagination.Item>
                <Pagination.Ellipsis />

                {pages.map((val, index) => {
                    return (
                        <Pagination.Item 
                            key={index} 
                            onClick={() => goToPage(val)}
                            disabled={val == page}
                        >
                            {val}
                        </Pagination.Item>
                    )
                })}
                <Pagination.Next disabled={page == totalPages} onClick={() => goToPage(nextPage)} />
                <Pagination.Last disabled={page == totalPages} onClick={() => goToPage(totalPages)} />
                </Pagination>
            )        
        } else {
            for (var i = parseInt(page) - 2; i <= parseInt(page) + 2; i++) {
                pages.push(i)
            }

            return (
                <Pagination >
                <Pagination.First disabled={page == 1} onClick={() => goToPage(1)}/>
                <Pagination.Prev disabled={page == 1} onClick={() => goToPage(prevPage)} />
                <Pagination.Item onClick={() => goToPage(1)}>{1}</Pagination.Item>
                <Pagination.Ellipsis />

                {pages.map((val, index) => {
                    return (
                        <Pagination.Item 
                            key={index} 
                            onClick={() => goToPage(val)}
                            disabled={val == page}
                        >
                            {val}
                        </Pagination.Item>
                    )
                })}
                <Pagination.Ellipsis />
                <Pagination.Item onClick={() => goToPage(totalPages)}>{totalPages}</Pagination.Item>
                <Pagination.Next disabled={page == totalPages} onClick={() => goToPage(nextPage)} />
                <Pagination.Last disabled={page == totalPages} onClick={() => goToPage(totalPages)} />
                </Pagination>
            )        
        }
    }
}

export default PaginationPlus;