import gql from "graphql-tag";

const DOCUMENT_SINGLE_QUERY = gql`
query Documents($slug:ID!) {
    document(id: $slug) {
            Date
            Content
            Title
        }
    }  
`;

export default DOCUMENT_SINGLE_QUERY;