import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { Row, Col } from 'react-bootstrap';
import useWindowDimensions from '../shared/hooks/useWindowDimensions';
import PaginationPlus from '../shared/components/PaginationPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { formatDate, formatRange } from '../shared/services/utils'; 
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import OKDTable from './SportsOKDTable';
import HerdingTable from './SportsHerdingTable';
import AgilityTable from './SportsAgilityTable';
import { pageType, darkBackground, getMediaUrl } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Query from "../shared/components/Query";

import SPORT_SECTION_QUERY from "../shared/queries/sport-section";
import SPORTS_QUERY from '../shared/queries/sports';

const Sports = (props) => {
    const [section, setSection] = useState("General");
	const [page, setPage] = useState(0);
	const [leftMost, setLeftMost] = useState(0);
    const [activeEvent, setActiveEvent] = useState(null);

    const history = useHistory();
	const rowsPerPage = 15;
    const { height, width } = useWindowDimensions();

    useEffect(
        () => {
            let page;
			if (!props.match.params.page) { 
				page = 1;
			} else {
				page = props.match.params.page;
			}
			setPage(page);
        }, [props.match.params.page]
    );

    useEffect(
		() => {
            if (props.match.params.section != null) {
                setSection(props.match.params.section);
            } else {
                setSection("General");
            }        
		},
		[props.match.params.section],
	);

    const start = (page) => {
		let firstRecord = (page - 1) * rowsPerPage;
		firstRecord < 0 ? firstRecord = 0 : null;

		return firstRecord;
	}

    const goToSection = (section) => {
		history.push('/sports/' + section);
	}

    const goToPage = (page) => {
		history.push('/sports/' + section + "/" + page);
	}

    const handleMouseEnter = (id) => {
        setActiveEvent(id);
    }

    const handleMouseLeave = () => {
        setActiveEvent(null);
    }

    const shiftRight = (total, maxPlates) => {
        if (leftMost + maxPlates < (total - 1)) {
            setLeftMost(leftMost + 1);
        }
    }

    const shiftLeft = () => {
        if (leftMost > 0) {
            setLeftMost(leftMost - 1);
        }
    }

    return(
        <>
		<PageHeader pageType={pageType.SPORTS} />

        <div className="d-flex justify-content-center bg-light p-3">
        <Container>
        <Query query={SPORT_SECTION_QUERY}>
			{({ data: { sportsCategories } }) => {
                var maxPlates;
                if (width > 1000) {
                    maxPlates = 4;
                } else if (width > 768) {
                    maxPlates = 3;
                } else if (width > 576) {
                    maxPlates = 2;
                } else {
                    maxPlates = 1;
                }
                var numCat = sportsCategories.length - 1;
                if ((maxPlates > numCat) && numCat > 0) {
                    maxPlates = numCat;
                }
                return(
                    <Row>
                        {leftMost != 0 &&
                        <div className="d-flex align-items-center" onClick={shiftLeft}>
                            <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#0081C9" }} size="lg" />
                        </div>}

                        {sportsCategories?.slice(leftMost, leftMost + maxPlates).map((sec, index) => {
                            if (sec.Section != "General") {
                                return (
                                    <Col key={index} data-testid='nkp-sports-section'>
                                    <div 
                                        className={sec.Section == section ?
                                            "nkp-sports-plate-active px-2 pb-2"
                                        :
                                            "nkp-sports-plate px-2 pb-2"} 
                                        onClick={() => goToSection(sec.Section)}>
                                        <span className="nkp-sports">{sec.Title}</span>
                                        <Image src={getMediaUrl(sec.Image)} fluid />
                                    </div>
                                    </Col>        
                                )    
                            }
                        })}

                        {leftMost != sportsCategories?.length - 1 - maxPlates &&
                        <div className="d-flex align-items-center" onClick={() => shiftRight(sportsCategories.length, maxPlates)}>
                            <FontAwesomeIcon icon={faChevronRight} style={{ color: "#0081C9" }} size="lg" />
                        </div>}
                    </Row>
                );
            }}
        </Query>                
        </Container>
        </div>

        <Query query={SPORTS_QUERY} slug={section} start={start(page)} limit={rowsPerPage}>
        {({ data: { sportsCategories, sportsEventsConnection } }) => {
            let category = sportsCategories[0];
            let totalRows2 = sportsEventsConnection.aggregate.count;
            let totalPages2 = Math.ceil(totalRows2 / rowsPerPage);                        
            return(
                <>
                <div className="mx-0 px-0">
                    <div style={{ backgroundImage: darkBackground(),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        width: "100wv" }}
                    >
        				<div className="nkp-dark-box-1" style={{ width: "100wv", minHeight: "200px" }}>
                        <Container>
                            <>
                            <div className="py-3" style={{ color: "#FFFFFF" }} data-testid="nkp-section-plate">
                                <div>
                                    <b>{category.Title == "General" ? null : category.Title}</b>
                                </div>

                                <div className="my-2" >
                                    <small>
                                        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={category.Description} />
                                    </small>
                                </div>
                            </div>

                            <Row className="pb-3">
                                {category.sports?.map((document, index) => {
                                    return (
                                    <Col key={index} lg={6} className="align-items-center">	
                                        <a href={document.Url}>
                                        <div className="nkp-document-plate" data-testid='nkp-sports-doc'>
                                            <Row>
                                                <Col xs={2}>
                                                    <FontAwesomeIcon icon={faGlobe} size="3x" />
                                                    </Col>
                                                <Col>
                                                    {document.Title}
                                                </Col>
                                            </Row>
                                        </div>
                                        </a>
                                    </Col>		
                                    )
                                })}
                            </Row>
                            </>
                        </Container>
                        </div>
                    </div>
                </div>

                {category.sports_events?.length > 0 &&
                <Container className="mt-3 mb-3 p-3 bg-white">

                <h4>Календарь событий</h4>

                <Row lg={3} noGutters data-testid="nkp-sports-calendar">
                {category.sports_events?.map((event, index) => {
                    return(
                        <Col key={index} 
                            data-testid="nkp-sports-event"
                            onMouseEnter={() => handleMouseEnter(event.id)}
                            onMouseLeave={handleMouseLeave} >
                            <div className="nkp-kennel-plate-kennel p-2 m-3">
                                <div className="justify-content-center" style={{ marginTop: "-10px" }}>
                                    <div className="d-flex align-items-start">
                                        <span className="nkp-date-capital mr-3">{formatDate(event.Start).split(" ")[0]}</span> 
                                        <span className="nkp-date-rest">
                                            {formatDate(event.Start).split(" ")[1] + " " + formatDate(event.Start).split(" ")[2] + " г."}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ width: "70%", marginLeft: "30%", marginTop: "-40px" }}>
                                    <div>
                                        <center>
                                            <small><b>{event.Classes}</b></small>
                                        </center>
                                    </div>
                                    <div>
                                        <center>
                                            <small><i>{event.City}</i></small>
                                        </center>
                                    </div>
                                </div>
                            </div>

                            <div className="nkp-sports-overlay p-3" 
                                    hidden={event.id != activeEvent}
                                    data-testid="nkp-event-overlay" >
                                <div>
                                    <span className="nkp-sports-overlay-date">
                                        {formatRange(event.Start, event.End)}
                                    </span>
                                </div>
                                <div className="py-2" data-testid="nkp-sports-event-club">
                                    <small>
                                        <b>
                                            {event.Club}
                                        </b>
                                    </small>
                                </div>
                                <div className="py-2">
                                    <small>
                                        <i>
                                            {event.City}
                                        </i>
                                    </small>
                                </div>
                                <div className="py-2">
                                    <div>
                                        <small>
                                            {event.Head}
                                        </small>
                                    </div>
                                    {event.Phone != null ? 
                                    <div>
                                        <small>
                                            <img src="image/phone.svg" width={15} height={15} />: {event.Phone}
                                        </small>
                                    </div>
                                    : ""}
                                    {event.Email != null ?
                                    <div>
                                        <small>
                                            <img src="image/mail.svg" width={15} height={15} />: <a href={"mailto:" + event.Email}>{event.Email}</a>
                                        </small>
                                    </div>
                                    : ""}
                                </div>
                            </div>

                        </Col>
                   );
                })}
                </Row>

                <div className="d-flex justify-content-center pt-3">
                    <PaginationPlus 
                        totalRows={totalRows2}
                        page={page}
                        totalPages={totalPages2}
                        rowsPerPage={rowsPerPage}
                        goToPage={goToPage}
                    />
                </div>
            </Container>}
            </>
            );
        }}
        </Query>                        

        {section != null &&
            section == "OKD" ?
                <OKDTable />
            : section == "Herding" ?
                <HerdingTable />
            : section == "Agility" ?
                <AgilityTable />
            : null}
        </>
    );
}

export default Sports;
