import gql from "graphql-tag";

const ARTICLE_SINGLE_QUERY = gql`
query Articles($slug:ID!) {
    article(id: $slug) {
            Date
            Content
            Title
        }
    }  
`;

export default ARTICLE_SINGLE_QUERY;