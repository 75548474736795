import gql from "graphql-tag";

const PROTOCOLS_QUERY = gql`
    query Protocols {
        protocols (sort: "Date:asc") {
            id
            Number
            Date
            protocol {
                url
            }
        }
    }
`;

export default PROTOCOLS_QUERY;