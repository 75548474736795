import React, { useEffect, useState } from 'react';
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

const InputFile = (props) => {
    const [files, setFiles] = useState([]);

    const handleChange = (e) => {
        let newFiles = [].concat(files);
        newFiles.push(e.target.files[0]);
        setFiles(newFiles);
    }

    useEffect(
        () => {
            props.handleChoose(props.type.key)(files);
        }, [files]
    );

    return (
        <>
            <Form.File.Label>
                {files.map((file, index) => {
                    return (
                        <div key={index}>
                            <FontAwesomeIcon icon={faFile} /> <small>{file.name}</small>
                        </div>
                    )
                })}              
            </Form.File.Label>
            <div className={props.errors[props.type.key] ? "custom-file nkp-search-box-invalid" : "custom-file nkp-search-box"} >
                <input id={props.inputId} type="file" className="custom-file-input" 
                    onChange={handleChange} data-testid={props.datatestid} />
                <label className={props.errors[props.type.key] ? "nkp-file-label-invalid mr-0" : "nkp-file-label mr-0"}
                htmlFor={props.inputId}>{props.inputTitle}</label>
            </div>

            {props.errors[props.type.key] ?
                <Form.Text className="text-danger">
                    {props.errors[props.type.key]}
                </Form.Text>
            : null}
        </>
    )
}

export default InputFile;