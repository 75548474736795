import gql from "graphql-tag";

const BREEDING_SINGLE_QUERY = gql`
query Breedings($slug:ID!) {
    breeding(id: $slug) {
            Date
            Content
            Title
        }
    }  
`;

export default BREEDING_SINGLE_QUERY;