import React from 'react';
const ReactDOM = require('react-dom');
import {HashRouter } from "react-router-dom";
import './scss/custom.scss';
import './css/nkp.css';
import './css/modal.css';
import App from './App';
import {ApolloProvider} from "@apollo/client";
import client from "./shared/services/apolloClient";

ReactDOM.render(
	<HashRouter basename="/">
		<ApolloProvider client={client}>
			<App />
		</ApolloProvider>
	</HashRouter>,
	document.getElementById('root')
);
