import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import PaginationPlus from '../shared/components/PaginationPlus';
import ModalAddGenetics from '../shared/modals/ModalAddGenetics';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
import useWindowDimensions from '../shared/hooks/useWindowDimensions';
import { genetics } from '../shared/services/utils';
import ModalRegister from '../shared/modals/ModalRegister';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ProfileContext } from '../shared/contexts/profile-context';
import { pageType } from '../shared/services/strapi-access-services';
import { PageHeader } from '../shared/components/PageHeader';
import Helmet from 'react-helmet';
import Query from "../shared/components/Query";

import GENETICS_QUERY from "../shared/queries/genetics";

const Genetics = (props) => {
	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState("");
	const [page, setPage] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [registerVisible, setRegisterVisible] = useState(false);
	const [profile, setProfile] = useContext(ProfileContext);

	const history = useHistory();
	const rowsPerPage = 20;
    const { height, width } = useWindowDimensions();

	useEffect(
		() => {
			let page;
			if (!props.match.params.page) { 
				page = 1;
			} else {
				page = props.match.params.page;
			}
			setPage(page);
		},
		[props.match.params],
	);

	const start = (page) => {
		let firstRecord = (page - 1) * rowsPerPage;
		firstRecord < 0 ? firstRecord = 0 : null;

		return firstRecord;
	}

	const decodeStatus = (status) => {
		let result;
		switch(status) {
			case "Clean" :
				result = "(-/-)"
				break;
			case "Carrier" :
				result = "(-/+)"
				break;
			case "Risk" :
				result = "(+/+)"
				break;
			default: 
				result = " "	
		}
		return result;
	}

	const goToPage = (page) => {
		history.push('/genetics/' + page);
	}

	const handleKeyDown = (e) => {
        if (e.key == 'Enter') { setFilter(search) };
    }


	return(
		<>
		<Helmet>
			<title>НКП ВКК - База данных генетических тестов</title>
			<meta name="description" content="База данных генетических тестов членов НКП Вельш Корги Кардиган." />
			<meta name="keywords" content="вельш Корги Кардиган, корги, кардиган, нкп, генетика, тесты, база данных" />
			<link rel="canonical" href="https://welsh-corgi.club/#/genetics" />
		</Helmet>

		<PageHeader pageType={pageType.GENETIC} />

		<Container className="mt-3 mb-3">
		<div className="bg-white p-3"/*border border-primary rounded"*/>

		<Row>
			<Col className="d-flex align-items-end">
				<InputGroup className="mb-3 nkp-search-box" style={{ minWidth: '200px' }}>
					<InputGroup.Prepend>
						<InputGroup.Text>
							<FontAwesomeIcon icon={faSearch} />
						</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl
						type="text"
						placeholder="Кличка собаки"
						onChange={(e) => setSearch(e.target.value)}
						onKeyDown={handleKeyDown}
					/>
				</InputGroup>
			</Col>
			<Col className="d-flex justify-content-end align-items-end">
			<div className="d-flex mb-3">
				{profile == null ?
					<>
					<Row className="d-flex justify-content-end mb-3" style={{ marginLeft: 0, marginRight: 0 }}>
					<div style={{ textAlign: 'right', marginBottom: '1em' }}>
						<span><small><i>Зарегистрированные пользователи сайта могут добавлять информацию по собакам.</i></small></span>
					</div>
					<div className="d-flex justify-content-end">
						<Button as="input" 
								size="md"
								data-testid="nkp-button-register"
								onClick={() => setRegisterVisible(true)} 
								type="submit" 
								value="Зарегистрироваться на сайте" />
					</div>
					</Row>
					</>
					:
					<Button as="input" 
							size="md"
							data-testid="nkp-button-add"
							onClick={() => setModalVisible(true)} 
							type="submit" 
							disabled={profile == null}
							value="Добавить результаты" />
				}
				</div>
			</Col>
		</Row>
		<Row>
			<Col lg={8} 
				className="mx-3 mb-3 nkp-box-shadow">
					<Row>
						<Col>
							{genetics.map((gen, index) => {
								if (index < 4) {
									return(
										<div key={index}><small><strong>{gen.short}</strong> - {gen.title}</small></div>
									);						
								}
							})}
						</Col>
						<Col>
							{genetics.map((gen, index) => {
								if (index >= 4) {
									return(
										<div key={index}><small><strong>{gen.short}</strong> - {gen.title}</small></div>
									);						
								}
							})}
							<div><small><strong>Pasp</strong> - Генетический паспорт</small></div>
						</Col>
					</Row>
			</Col>
			<Col
				className="mx-3 mb-3 nkp-box-shadow">
				<div><small><strong>(-/-)</strong> - Чистый</small></div>
				<div><small><strong>(-/+)</strong> - Носитель</small></div>
				<div><small><strong>(+/+)</strong> - В зоне риска</small></div>
			</Col>
		</Row>

		<Query query={GENETICS_QUERY} slug={filter || ""} start={start(page)} limit={rowsPerPage}>
			{({ data: { dogs, dogsConnection } }) => {
				let totalRows2 = dogsConnection.aggregate.count;
				let totalPages2 = Math.ceil(totalRows2 / rowsPerPage);
				return(
					<>
					{width > 1000 ?

					<div className="nkp-box-shadow p-3">
						<Table striped bordered hover>
						<thead>
							<tr>
							<th className="nkp-genetics-head"><small>Кличка</small></th>
							<th><small>Год рождения</small></th>
							{genetics.map((gen, index) => {
								return(
									<th key={index}><small>{gen.short}</small></th>
								);					
							})}
							<th><small>Pasp</small></th>
							<th><small>Лаборатория</small></th>
							</tr>
						</thead>

						<tbody>
						{dogs.map((dog, index) => {
							let dogName = dog.Name;
							if(profile != null) {
								if (dog.curators?.filter(rec => rec.email == profile.email).length > 0
								|| dog.owner?.email == profile.email
								|| profile.role.name == "Moderator") {
									dogName = <Link to={"/dog/" + dog.id}>{dog.Name}</Link>
								}
							}							
							return (
							<tr key={index}>
								<td><small>{dogName}</small></td>
								<td><small>{dog.Year}</small></td>
								<td><small>{decodeStatus(dog.genetic.PRA)}</small></td>
								<td><small>{decodeStatus(dog.genetic.DM)}</small></td>
								<td><small>{decodeStatus(dog.genetic.Fluffy)}</small></td>
								<td><small>{decodeStatus(dog.genetic.vWD)}</small></td>
								<td><small>{decodeStatus(dog.genetic.Pink)}</small></td>
								<td><small>{decodeStatus(dog.genetic.Deljut)}</small></td>
								<td><small>{decodeStatus(dog.genetic.Chocolate)}</small></td>
								<td data-testid="nkp-passport">
									<center>
										{dog.genetic.Passport  && <FontAwesomeIcon icon={faCheck} />}
									</center>
								</td>
								<td data-testid="nkp-lab"><small>{dog.genetic.Laboratory}</small></td>
							</tr>	
							);	
						})}
						</tbody>
						</Table>

						<div className="d-flex justify-content-center">
							<PaginationPlus 
								totalRows={totalRows2}
								page={page}
								totalPages={totalPages2}
								rowsPerPage={rowsPerPage}
								goToPage={goToPage}
							/>
						</div>
					</div>
					:
					<div className="nkp-box-shadow p-3">
					<Table striped bordered hover>
					<thead>
						<tr>
						<th><small>Кличка</small></th>
						<th><small>Год рождения</small></th>
						<th><small>Результат</small></th>
						</tr>
					</thead>

					<tbody>
					{dogs.map((dog, index) => {
						let dogName = dog.Name;
						if(profile != null) {
							if (dog.curators?.filter(rec => rec.email == profile.email).length > 0
							|| dog.owner?.email == profile.email
							|| profile.role.name == "Moderator") {
								dogName = <Link to={"/dog/" + dog.id}>{dog.Name}</Link>
							}
						}
						return (
						<tr key={index}>
							<td><small>{dogName}</small></td>
							<td><small>{dog.Year}</small></td>
							<td data-testid="nkp-lab">
								<div><em><small>
									{dog.genetic.Laboratory}
								</small></em></div>
								<div><small>PRA - {decodeStatus(dog.genetic.PRA)}</small></div>
								<div><small>DM - {decodeStatus(dog.genetic.DM)}</small></div>
								<div><small>FL - {decodeStatus(dog.genetic.Fluffy)}</small></div>
								<div><small>vWD - {decodeStatus(dog.genetic.vWD)}</small></div>
								<div><small>E - {decodeStatus(dog.genetic.Pink)}</small></div>
								<div><small>D - {decodeStatus(dog.genetic.Deljut)}</small></div>
								<div><small>B - {decodeStatus(dog.genetic.Chocolate)}</small></div>
								{dog.genetic.Passport != null &&
								<div>
									<small>
										Генетический паспорт - {dog.genetic.Passport && <FontAwesomeIcon icon={faCheck} />}
									</small>
								</div>}
							</td>
						</tr>	
						);	
					})}
					</tbody>
					</Table>

					<div className="d-flex justify-content-center">
						<PaginationPlus 
							totalRows={totalRows2}
							page={page}
							totalPages={totalPages2}
							rowsPerPage={rowsPerPage}
							goToPage={goToPage}
						/>
					</div>
				</div>}
				</>
				);
			}}
		</Query>
		</div>
		</Container>

        <ModalAddGenetics visible={modalVisible} 
			profile={profile}
            callbackParent={() => setModalVisible(false)}
		/>

		<ModalRegister visible={registerVisible} 
			callbackParent={() => setRegisterVisible(false)}
		/>

		</>
	);
}

export default Genetics;
