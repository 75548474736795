import gql from "graphql-tag";

const MEMBERS_QUERY = gql`
    query Members {
        members (sort: "Type:asc, Name:asc, MemberName:asc", limit: -1) {
            Name
            MemberName
            City
            Type
        }
    }
`;

export default MEMBERS_QUERY;
