import gql from "graphql-tag";

const PRIVACY_QUERY = gql`
    query Privacy {
        privacy {
            Title
            policy
        }
    }
`;

export default PRIVACY_QUERY;