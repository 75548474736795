import gql from "graphql-tag";

const HOME_QUERY = gql`
    query Home {
        home {
            Title
            About
            Chairman {
                url
            }
            ChairmanName
            ChairmanPosition
            Image {
                url
            }
            Board {
                Name
                Position
                Pic {
                    url
                }
            }
        }
    }
`;

export default HOME_QUERY;