import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faVk } from '@fortawesome/free-brands-svg-icons';

const Footer = (props) => {
    const [contact, setContact] = useState(null);

    const logo = "image/logo-old.png";

    useEffect(
        () => {
            setContact(props.contact);
        }, [props]
    );

    return (
        <div className="bg-light px-5 pb-3 border-top border-primary">
        <Container className="mb-3 mt-3">
        <Row>
            <Col lg={3} className="d-flex nkp-footer-logo">
                <div className="my-3 mx-5">
                    <Link to="/">
                    <Image src={logo} style = {{ maxWidth: 200 }} data-testid="nkp-logo" fluid />
                    </Link>
                </div>
            </Col>
            <Col className="nkp-footer-section mt-3">
                <div>
                <div className="d-flex nkp-footer-logo">
                    <small>
                        <Link to="/articles">
                            <strong>О породе</strong>
                        </Link>
                    </small>
                </div>
                <div className="d-flex nkp-footer-logo">
                    <small>
                        <Link to="/documents">
                            <strong>Документы НКП</strong>
                        </Link>
                    </small>
                </div>
                <div className="d-flex nkp-footer-logo">
                    <small>
                        <Link to="/breedings">
                            <strong>Племенная работа</strong>
                        </Link>
                    </small>
                </div>
                <div className="d-flex nkp-footer-logo">
                    <small>
                        <Link to="/expos">
                            <strong>Выставочная деятельность</strong>
                        </Link>
                    </small>
                </div>
                <div className="d-flex nkp-footer-logo">
                    <small>
                        <Link to="/sports">
                            <strong>Работа и спорт</strong>
                        </Link>
                    </small>
                </div>
                <div className="d-flex nkp-footer-logo">
                    <small>
                        <Link to="/puppies">
                            <strong>Щенки</strong>
                        </Link>
                    </small>
                </div>
                <div className="d-flex nkp-footer-logo">
                    <small>
                        <Link to="/privacy">
                            <strong>Политика в отношении обработки<br />
                            персональных данных</strong>
                        </Link>
                    </small>
                </div>
            </div>
            </Col>
            <Col lg={4} className="nkp-footer-section mt-3">
                <div className="d-flex nkp-footer-logo">
                    <strong>
                        Контакты
                    </strong>
                </div>
                {contact?.Phone ?
                <div className="d-flex nkp-footer-logo" data-testid="nkp-phone">
                    <small>
                        <FontAwesomeIcon icon={faPhone} width={15} height={15} /> : {contact?.Phone}
                    </small>
                </div>
                : null}
                {contact?.Mail ?
                <div className="d-flex nkp-footer-logo" data-testid="nkp-mail">
                    <small>
                        <FontAwesomeIcon icon={faEnvelopeOpen} width={15} height={15} /> :
                        <a href={"mailto:" + contact?.Mail}>{contact?.Mail}</a>
                    </small>
                </div>
                : null}
                {contact?.Facebook ?
                <div className="d-flex nkp-footer-logo" data-testid="nkp-facebook">
                    <small>
                        <FontAwesomeIcon icon={faFacebook} width={15} height={15} color="#4267B2" /> :
                        <a href={contact?.Facebook}>Группа HКП</a>
                    </small>
                </div>
                : null}
                {contact?.Telegram ?
                <div className="d-flex nkp-footer-logo" data-testid="nkp-telegram">
                    <small>
                        <FontAwesomeIcon icon={faTelegram} width={15} height={15} color="#0088CC" /> :
                        <a href={contact?.Telegram}>Канал HКП</a>
                    </small>
                </div>
                : null}
                {contact?.Vk ?
                <div className="d-flex nkp-footer-logo" data-testid="nkp-vk">
                    <small>
                        <FontAwesomeIcon icon={faVk} width={15} height={15} color="#4C75A3" /> :
                        <a href={contact?.Vk}>Группа HКП</a>
                    </small>
                </div>
                : null}
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="text-right">
            <div><small>&copy; НКП Вельш Корги Кардиган, 2021</small></div>
            </Col>
        </Row>
        </Container>
        </div>
    );
}

export default Footer;