import React from 'react';
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputCheckbox = (props) => {
    return (
        <div id={props.id}>
            <Form.Check 
                type='checkbox'
                className={props.errors[props.type.key] ? "nkp-checkbox-invalid" : ""}
                data-testid={props.datatestid}
                label={props.label}
                isInvalid={props.errors[props.type.key]}
                checked={props.data[props.type.key] || false}
                onChange={props.handleCheck(props.type.key)}
                style={props.style}
            />
            {props.errors[props.type.key] && props.warnings != false ?
                <Form.Text className="text-danger">
                    {props.errors[props.type.key]}
                </Form.Text>
            : null}
        </div>
    )
}

export default InputCheckbox;